import React, { useEffect, useRef, useState } from "react";
import styles from "../../utility/styles/Signup.module.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import GeniusFooterLogo from "../../utility/images/ico.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch } from "react-redux";
import { Country, State, City } from "country-state-city";
import { useFocus } from "../../utility/hooks/hooks";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import CallAPI from "../../utility/functions/getData";
import {
  setAlertWithTitle,
  setEmailVerificationStatus,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import { toProperCase } from "../../utility/component/format";
import axios from "axios";
import { isTablet } from "react-device-detect";
import { NeumorphicButton } from "../../utility/component/neumorphismComp";

const MobileSignUp = () => {
  const [selectedTab, setSelectedTab] = useState("company");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [Website, setWebsite] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [username, setUsername] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pinCode, setPincode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [companyRef, setCompanyFocus] = useFocus();
  const [companyNameRef, setCompanyNameFocus] = useFocus();
  const [mobileRef, setMobileFocus] = useFocus();
  const [UsernameRef, setUsernameFocus] = useFocus();
  const [userEmailRef, setUserEmailFocus] = useFocus();
  const [passwordRef, setPasswordFocus] = useFocus();
  const [confirmPasswordRef, setConfirmPasswordFocus] = useFocus();

  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const [isFocused2, setIsFocused2] = useState(false);
  const [isFocused3, setIsFocused3] = useState(false);
  const [isFocused4, setIsFocused4] = useState(false);
  const [isFocused5, setIsFocused5] = useState(false);
  const [isFocused6, setIsFocused6] = useState(false);
  const [isFocused7, setIsFocused7] = useState(false);
  const [isFocused8, setIsFocused8] = useState(false);
  const [isFocused9, setIsFocused9] = useState(false);
  const [isFocused10, setIsFocused10] = useState(false);
  const [isFocused11, setIsFocused11] = useState(false);
  const [isFocused12, setIsFocused12] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createFinacialYear = (code, company, email, hmac) => {
    const data = {
      company_code: code,
      company_name: company,
      company_email: email,
    };
    axios
      .post(
        `/api/users/createFinancialYear`,

        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hmac}`,
          },
        }
      )
      .then(async function (response) {
        navigate("/auth/login", {
          state: {
            signupEmail: email,
          },
        });
        dispatch(setEmailVerificationStatus(true));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const focusto = (el) => {
    setTimeout(() => {
      // Function to execute after 30 seconds
      const inputFields = formRef.current.querySelectorAll("input, select");
      inputFields.forEach((element) => {
        if (element.id === el) {
          element.focus();
        }
      });
    }, 200);
  };

  const phoneRegex = /^[6-9]\d{9}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSignUp = () => {
    if (
      email &&
      emailRegex.test(email) &&
      companyName &&
      Website &&
      mobile &&
      phoneRegex.test(mobile) &&
      username &&
      userEmail &&
      emailRegex.test(userEmail) &&
      password &&
      password === confirmPassword &&
      confirmPassword &&
      password.length > 5 &&
      address &&
      selectedCountry &&
      selectedState &&
      city &&
      pinCode &&
      Website
    ) {
      const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
      const text = companyName;
      const words = text.split(" ");

      const result =
        words.reduce((acc, word) => {
          if (word) {
            acc += word[0];
          }
          return acc;
        }, "") + "_";
      // const hmac = CryptoJS.HmacSHA256(message, secret);
      const hmac = CryptoJS.HmacSHA256(username, secretPass);
      // Convert the HMAC to a string and encode it using Base64
      const hmacString = hmac.toString(CryptoJS.enc.Base64);
      const tablePrefix = result.toLowerCase();
      const data = {
        user_email: userEmail,
        user_password: password,
        user_name: username,
        user_type: "MANAGER",
        user_status: "ACTIVE",
        company_email: email,
        company_name: companyName,
        company_mobile: mobile,
        company_website: Website,
        group_name: "",
        address: address,
        city: city,
        state: selectedState,
        pin_code: pinCode,
        shop_id: "",
        shop_name: "",
        currency_name: "rupee",
        currency_symbol: "₹",
        regd_no1: "",
        regd_no2: "",
        regd_no3: "",
        disputes: `All Disputes Subject to ${city}`,
        company_logo: "",
        table_prefix: tablePrefix,
      };
      dispatch(setSpinnerLoading("Creating"));
      CallAPI(
        "/api/users/registerCompany",
        data,
        hmacString,
        (e) => {
          if (!e.data.error) {
            const code = e.data.results.insertId;
            createFinacialYear(code, companyName, email, hmacString);

            clearState();
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "Something Went Wrong.",
              })
            );
          }
        },
        dispatch,
        () => {
          dispatch(setSpinnerLoading(false));
        }
      );
    } else {
      if (!email || !emailRegex.test(email)) {
        if (!email) {
          setSelectedTab("company");
          focusto("companyEmail");

          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Please Enter Company Email.",
            })
          );
        } else {
          setSelectedTab("company");
          focusto("companyEmail");

          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Please Enter valid Email.",
            })
          );
        }
      } else {
        if (!companyName) {
          setSelectedTab("company");
          focusto("companyName");
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Please Enter Company Name.",
            })
          );
        } else {
          if (!Website) {
            setSelectedTab("company");

            focusto("website");
            // setWebsiteRef();
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "Please Enter website.",
              })
            );
          } else {
            if (!address) {
              setSelectedTab("company");

              focusto("address");
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: "Enter User Address.",
                })
              );
            } else {
              if (!selectedCountry) {
                setSelectedTab("company");

                focusto("country");
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: "Please Select Country.",
                  })
                );
              } else {
                if (!selectedState) {
                  setSelectedTab("company");

                  focusto("state");
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Please Select State",
                    })
                  );
                } else {
                  if (!selectedState) {
                    setSelectedTab("company");

                    focusto("state");
                    dispatch(
                      setAlertWithTitle({
                        title: "Alert",
                        msg: "Please Select State",
                      })
                    );
                  } else {
                    if (!city) {
                      setSelectedTab("company");

                      focusto("city");
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "Please select City",
                        })
                      );
                    } else {
                      if (!pinCode) {
                        setSelectedTab("company");

                        focusto("pincode");
                        dispatch(
                          setAlertWithTitle({
                            title: "Alert",
                            msg: "Please Enter Pincode.",
                          })
                        );
                      } else {
                        if (!mobile || !phoneRegex.test(mobile)) {
                          if (!mobile) {
                            setSelectedTab("company");

                            focusto("mobile");
                            dispatch(
                              setAlertWithTitle({
                                title: "Alert",
                                msg: "Please Enter Mobile.",
                              })
                            );
                          } else {
                            setSelectedTab("company");

                            focusto("mobile");
                            dispatch(
                              setAlertWithTitle({
                                title: "Alert",
                                msg: "Please Enter Valid Mobile.",
                              })
                            );
                          }
                        } else {
                          if (!username) {
                            setUsernameFocus();
                            dispatch(
                              setAlertWithTitle({
                                title: "Alert",
                                msg: "Please enter User Name",
                              })
                            );
                          } else {
                            if (!userEmail || !emailRegex.test(userEmail)) {
                              if (!userEmail) {
                                setUserEmailFocus();
                                dispatch(
                                  setAlertWithTitle({
                                    title: "Alert",
                                    msg: "Please enter User Email",
                                  })
                                );
                              } else {
                                setUserEmailFocus();
                                dispatch(
                                  setAlertWithTitle({
                                    title: "Alert",
                                    msg: "Please enter Valid Email",
                                  })
                                );
                              }
                            } else {
                              if (!password || password.length < 5) {
                                if (!password) {
                                  setPasswordFocus();
                                  dispatch(
                                    setAlertWithTitle({
                                      title: "Alert",
                                      msg: "Please Enter Password.",
                                    })
                                  );
                                } else {
                                  setPasswordFocus();
                                  dispatch(
                                    setAlertWithTitle({
                                      title: "Alert",
                                      msg: "Password should be more than 5 characters.",
                                    })
                                  );
                                }
                              } else {
                                if (
                                  !confirmPassword ||
                                  confirmPassword !== password
                                ) {
                                  if (!confirmPassword) {
                                    setConfirmPasswordFocus();
                                    dispatch(
                                      setAlertWithTitle({
                                        title: "Alert",
                                        msg: "Please Enter Confirm Password.",
                                      })
                                    );
                                  } else {
                                    setConfirmPasswordFocus();
                                    dispatch(
                                      setAlertWithTitle({
                                        title: "Alert",
                                        msg: "Confirm Password does not match.",
                                      })
                                    );
                                  }
                                } else {
                                  if (!mobile) {
                                    setSelectedTab("company");
                                    setMobileFocus();
                                    dispatch(
                                      setAlertWithTitle({
                                        title: "Alert",
                                        msg: "Enter Company Mobile.",
                                      })
                                    );
                                  } else {
                                    if (!companyName) {
                                      setCompanyNameFocus();
                                      dispatch(
                                        setAlertWithTitle({
                                          title: "Alert",
                                          msg: "Enter Company Name.",
                                        })
                                      );
                                    } else {
                                      if (!email) {
                                        setCompanyFocus();
                                        dispatch(
                                          setAlertWithTitle({
                                            title: "Alert",
                                            msg: "Enter Company Email.",
                                          })
                                        );
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const clearState = () => {
    setEmail("");
    setCompanyName("");
    setWebsite("");
    setMobile("");
    setAddress("");
    setSelectedCountry("");
    setSelectedState("");
    setCity("");
    setPincode("");
    setUsername("");
    setUserEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [selectedState, setSelectedState] = useState("");
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const countries = Country.getAllCountries();
  const [city, setCity] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  useEffect(() => {
    if (selectedCountry) {
      const countryy = countries.filter(
        (country) => country.name === selectedCountry
      );
      setStatelist(State.getStatesOfCountry(countryy[0].isoCode));
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState && stateList[0]) {
      const statee = stateList.filter((state) => state.name === selectedState);
      // Check if statee has at least one element
      if (statee.length > 0) {
        const firstState = statee[0];
        setCitylist(
          City.getCitiesOfState(firstState.countryCode, firstState.isoCode)
        );
      } else {
        // alert("hgdcfujhgsd");
        // setCitylist([]);
      }
    }
  }, [selectedState, stateList]);

  const handleArrowButtonClick = () => {
    setSelectedTab("user");
  };

  const formRef = useRef(null);

  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll("input, select");

    // Add a keydown event listener to each input and select field
    inputFields.forEach((field, index) => {
      field.addEventListener("keydown", (event) => {
        if (event.key === "Enter") {
          event.preventDefault(); // Prevent form submission
          const nextIndex = (index + 1) % inputFields.length;
          if (index === 8) {
            setSelectedTab("user");
            inputFields[0].focus();
          } else if (selectedTab === "user" && index === 3) {
            setSelectedTab("company");
            inputFields[0].focus();
          } else {
            inputFields[nextIndex].focus();
          }
        }
      });
    });

    return () => {
      // Clean up event listeners when the component unmounts
      inputFields.forEach((field) => {
        field.removeEventListener("keydown", () => {});
      });
    };
  }, [selectedTab]);
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

  // useEffect(()=>{
  //   CallAPI('')
  // })

  const checkIsDuplicateUser = (email, mobile) => {
    if (email || mobile) {
      const isEmail = email && emailRegex.test(email);
      const isMobile = mobile && phoneRegex.test(mobile);
      if (isEmail || isMobile) {
        const u = email ? email : mobile;
        const hmac = CryptoJS.HmacSHA256(u, secretPass);
        // Convert the HMAC to a string and encode it using Base64
        const hmacString = hmac.toString(CryptoJS.enc.Base64);
        const d = {
          company_email: email ? email : "",
          company_mobile: mobile ? mobile : "",
        };
        CallAPI(
          "/api/users/companyInfo",
          d,
          hmacString,
          (e) => {
            if (e.data.response[0]) {
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: `This ${
                    email ? "email address" : "mobile"
                  } is already registered with our system`,
                })
              );
              if (email) {
                setEmail("");
              } else {
                setMobile("");
              }
            }
          },
          dispatch
        );
      }
    }
  };

  const VerifyUser = async (e) => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
    const hmac = CryptoJS.HmacSHA256(e, secretPass);
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    if (e && emailRegex.test(e)) {
      await axios
        .post(
          "/api/users/userInfo",
          {
            user_email: e,
            user_mobile: "",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${hmacString}`,
            },
          }
        )
        .then((res) => {
          if (res.data.response[0]) {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "email is already registered",
              })
            );
            setUserEmail("");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className=" h-screen px-2 bg-[#e7e7e9] w-screen">
      <div
        style={{
          height: isTablet ? `calc(100% - 100px)` : "100%",

          alignItems: isTablet ? "start" : "center",
          paddingTop: isTablet ? "20px" : "0px",
        }}
        className="flex w-full items-center justify-center  py-6 "
      >
        <div
          className={styles.signup}
          style={{
            padding:
              window.innerWidth <= 1280
                ? "10px 0px 0px 0px"
                : "20px 0px 0px 0px",
            backgroundColor: "#ebeff3",
            borderRadius: "20px",
            boxShadow: "2px 2px 2px #d4d4d4,-2px -2px 2px #d4d4d4",
            overflowY: "hidden",
          }}
          ref={formRef}
        >
          <h2>Retailx Sign Up</h2>

          <div
            style={{}}
            className=" w-full  rounded-sm px-2 pt-2 pb-2  bg-[#ebeff3] flex "
          >
            <div
              onClick={() => {
                setSelectedTab("company");
              }}
              style={{
                color: selectedTab === "company" ? "white" : "gray",
              }}
              className={`flex-1 rounded-sm p-2 ${
                selectedTab === "company"
                  ? "bg-brown "
                  : "  bg-[#ebeff3]  hover:cursor-pointer "
              }  flex justify-center items-center  `}
            >
              <div
                style={{
                  color: selectedTab === "company" ? "white" : "#383535",
                  fontSize: "18px",
                }}
                className=" h-full flex items-center"
              >
                Company Details
              </div>
            </div>

            <div
              onClick={() => {
                setSelectedTab("user");
              }}
              style={{
                color: selectedTab === "user" ? "wjite" : "gray",
              }}
              className={`flex-1 rounded-sm p-2 ${
                selectedTab === "user"
                  ? "bg-brown "
                  : "  bg-[#ebeff3]  hover:cursor-pointer "
              }  flex justify-center items-center  `}
            >
              <div
                style={{
                  color: selectedTab === "user" ? "white" : "#383535",
                  fontSize: "18px",
                }}
                className=" h-full flex items-center"
              >
                User Details
              </div>
            </div>
          </div>

          {selectedTab === "company" && (
            <div>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "11px 20px",
                  height: "431px",
                }}
              >
                <div className={styles.group} style={{ position: "relative" }}>
                  <label
                    className={`${styles.labelStyles} ${
                      isFocused ? styles.labelFocused : ""
                    }`}
                  >
                    Company Email
                  </label>
                  <input
                    className={styles.input}
                    ref={companyRef}
                    style={{
                      width: "100%",
                      outline: "none",
                      paddingTop: "12px",
                    }}
                    type="email"
                    id="companyEmail"
                    value={email}
                    onChange={(e) => {
                      checkIsDuplicateUser(e.target.value);
                      setEmail(e.target.value);
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />
                </div>

                <div className={styles.group} style={{ position: "relative" }}>
                  <label
                    className={`${styles.labelStyles} ${
                      isFocused1 ? styles.labelFocused : ""
                    }`}
                  >
                    Company Name
                  </label>
                  <input
                    style={{
                      width: "100%",
                      outline: "none",
                      paddingTop: "12px",
                    }}
                    ref={companyNameRef}
                    className={styles.input}
                    type="text"
                    id={"companyName"}
                    value={companyName}
                    onChange={(e) =>
                      setCompanyName(toProperCase(e.target.value))
                    }
                    onFocus={() => setIsFocused1(true)}
                    onBlur={() => setIsFocused1(false)}
                  />
                </div>
                <div className={styles.group} style={{ position: "relative" }}>
                  <label
                    className={`${styles.labelStyles} ${
                      isFocused2 ? styles.labelFocused : ""
                    }`}
                  >
                    Website
                  </label>
                  <input
                    style={{
                      width: "100%",
                      outline: "none",
                      paddingTop: "12px",
                    }}
                    className={styles.input}
                    type="text"
                    id={"website"}
                    value={Website}
                    onChange={(e) => {
                      setWebsite(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.group} style={{ position: "relative" }}>
                  <label
                    className={`${styles.labelStyles} ${
                      isFocused3 ? styles.labelFocused : ""
                    }`}
                  >
                    Address
                  </label>
                  <input
                    style={{
                      width: "100%",
                      outline: "none",
                      paddingTop: "12px",
                    }}
                    className={styles.input}
                    type="text"
                    id={"address"}
                    value={address}
                    onChange={(e) => {
                      setAddress(toProperCase(e.target.value));
                    }}
                    onFocus={() => setIsFocused3(true)}
                    onBlur={() => setIsFocused3(false)}
                  />
                </div>

                <div className="flex w-full">
                  <div
                    className={`${styles.group} w-[185px]`}
                    style={{ position: "relative" }}
                  >
                    <label
                      className={`${styles.labelStyles} ${
                        isFocused4 ? styles.labelFocused : ""
                      }`}
                    >
                      Select Country
                    </label>
                    <select
                      style={{
                        width: "100%",
                        outline: "none",
                        paddingTop: "12px",
                      }}
                      className={`${styles.select} text-black`}
                      id="country"
                      name="country"
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      onFocus={() => setIsFocused4(true)}
                      onBlur={() => setIsFocused4(false)}
                    >
                      <option value={countries}></option>
                      {countries.map((e, i) => (
                        <option key={i} value={e.value}>
                          {e.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div
                    className={`${styles.group} ml-2 w-[185px]`}
                    style={{ position: "relative" }}
                  >
                    <label
                      className={`${styles.labelStyles} ${
                        isFocused5 ? styles.labelFocused : ""
                      }`}
                    >
                      Select State
                    </label>
                    <select
                      style={{
                        width: "100%",
                        outline: "none",
                        paddingTop: "12px",
                      }}
                      id="state"
                      name="state"
                      value={selectedState}
                      onChange={handleStateChange}
                      className={`${styles.select} text-black`}
                      onFocus={() => setIsFocused5(true)}
                      onBlur={() => setIsFocused5(false)}
                    >
                      <option value={stateList}></option>
                      {stateList.map((e, i) => (
                        <option key={i} value={e.value}>
                          {e.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="flex">
                  <div
                    className={`${styles.group} w-[185px]`}
                    style={{ position: "relative" }}
                  >
                    <label
                      className={`${styles.labelStyles} ${
                        isFocused6 ? styles.labelFocused : ""
                      }`}
                    >
                      Select City
                    </label>
                    <select
                      style={{
                        width: "100%",
                        outline: "none",
                        paddingTop: "14px",
                      }}
                      className={`${styles.select} text-black`}
                      id="city"
                      name="city"
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                      onFocus={() => setIsFocused6(true)}
                      onBlur={() => setIsFocused6(false)}
                    >
                      <option value={cityList}></option>
                      {cityList.map((e, i) => (
                        <option key={i} value={e.value}>
                          {e.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div
                    className={`${styles.group} ml-2 w-[185px]`}
                    style={{ position: "relative" }}
                  >
                    <label
                      className={`${styles.labelStyles} ${
                        isFocused7 ? styles.labelFocused : ""
                      }`}
                    >
                      Pin Code
                    </label>

                    <input
                      style={{
                        width: "100%",
                        outline: "none",
                        paddingTop: "12px",
                      }}
                      className={styles.input}
                      type="text"
                      id={"pincode"}
                      value={pinCode}
                      onChange={(e) => {
                        setPincode(e.target.value);
                      }}
                      onFocus={() => setIsFocused7(true)}
                      onBlur={() => setIsFocused7(false)}
                    />
                  </div>
                </div>

                <div className={styles.group} style={{ position: "relative" }}>
                  <div className="flex">
                    <label
                      className={`${styles.labelStyles} ${
                        isFocused8 ? styles.labelFocused : ""
                      }`}
                    >
                      Mobile
                    </label>
                    <input
                      style={{
                        width: "100%",
                        outline: "none",
                        paddingTop: "12px",
                      }}
                      ref={mobileRef}
                      className={styles.input}
                      type="text"
                      id={"mobile"}
                      value={mobile}
                      onChange={(e) => {
                        const newMobile = e.target.value;
                        checkIsDuplicateUser("", newMobile);
                        setMobile(newMobile);
                      }}
                      onFocus={() => setIsFocused9(true)}
                      onBlur={() => setIsFocused9(false)}
                    />
                    <button
                      className={styles.btn}
                      type="button"
                      onClick={handleArrowButtonClick}
                    >
                      <ArrowForwardIcon
                        style={{
                          fontSize: "22px",
                        }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedTab === "user" && (
            <div
              style={{
                backgroundColor: "white",
                padding: "11px 20px",
                height: "431px",
              }}
            >
              {/* <div className="flex justify-center text-[20px] font-bold text-black mb-2">
              User Details
            </div> */}
              <div className={styles.group} style={{ position: "relative" }}>
                <label
                  className={`${styles.labelStyles} ${
                    isFocused10 ? styles.labelFocused : ""
                  }`}
                >
                  User Name
                </label>
                <input
                  autoComplete="off"
                  style={{
                    width: "100%",
                    outline: "none",
                  }}
                  ref={UsernameRef}
                  className={styles.input}
                  type="text"
                  id={"userName"}
                  value={username}
                  onChange={(e) => setUsername(toProperCase(e.target.value))}
                />
              </div>

              <div className={styles.group} style={{ position: "relative" }}>
                <label
                  className={`${styles.labelStyles} ${
                    isFocused11 ? styles.labelFocused : ""
                  }`}
                  style={{ background: "white" }}
                >
                  User Email
                </label>
                <input
                  className={styles.input}
                  ref={userEmailRef}
                  autoComplete="off"
                  style={{
                    width: "100%",
                    outline: "none",
                    background: userEmail ? "white" : "transparent",
                  }}
                  type="email"
                  id="userEmail"
                  value={userEmail}
                  onChange={(e) => {
                    VerifyUser(e.target.value);
                    setUserEmail(e.target.value);
                  }}
                  onFocus={() => setIsFocused11(true)}
                  onBlur={() => setIsFocused11(false)}
                />
              </div>

              <div
                className={styles.formGroup}
                style={{ position: "relative" }}
              >
                {/* <div className="mt-3"> */}
                <div
                  className="flex mt-3"
                  style={{ position: "relative", width: "100%" }}
                >
                  <label
                    className={`${styles.labelStyles} ${
                      isFocused12 ? styles.labelFocused : ""
                    }`}
                    style={{ background: "white" }}
                  >
                    Password
                  </label>
                  <input
                    className={styles.input}
                    ref={passwordRef}
                    style={{
                      width: "100%",
                      outline: "none",
                      background: password ? "white" : "transparent",
                      paddingRight: "40px",
                    }}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={() => setIsFocused12(true)}
                    onBlur={() => setIsFocused12(false)}
                    autoComplete="off"
                  />
                  <button
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      border: "none",
                      background: "none",
                    }}
                    type="button"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <VisibilityOff
                        style={{
                          fontSize: "20px",
                          color: "#383535",
                        }}
                      />
                    ) : (
                      <Visibility
                        style={{
                          fontSize: "20px",
                          color: "#383535",
                        }}
                      />
                    )}
                  </button>
                </div>
                {/* </div> */}
              </div>
              <div
                className={styles.formGroup}
                style={{ position: "relative" }}
              >
                {/* <div className="mt-3"> */}
                <div
                  className="flex mt-3"
                  style={{ position: "relative", width: "100%" }}
                >
                  <label
                    className={`${styles.labelStyles} ${
                      isFocused8 ? styles.labelFocused : ""
                    }`}
                    style={{ background: "white" }} // Setting background to white
                  >
                    Confirm Password
                  </label>

                  <input
                    className={styles.input}
                    ref={confirmPasswordRef}
                    style={{
                      width: "100%",
                      outline: "none",
                      background: "transparent",
                      paddingRight: "40px", // Adjust this value to accommodate the button width
                    }}
                    type={showConfirmPassword ? "text" : "password"}
                    id="cpassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onFocus={() => setIsFocused8(true)}
                    onBlur={() => setIsFocused8(false)}
                  />

                  <button
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      border: "none",
                      background: "none",
                    }}
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? (
                      <VisibilityOff
                        style={{
                          fontSize: "20px",
                          color: "#383535",
                        }}
                      />
                    ) : (
                      <Visibility
                        style={{
                          fontSize: "20px",
                          color: "#383535",
                        }}
                      />
                    )}
                  </button>
                </div>
                {/* </div> */}
              </div>

              <div className="flex justify-end items-center mt-[50px] ll:mt-[120px]">
                <button
                  onClick={handleSignUp}
                  className={styles.button}
                  type="submit"
                >
                  Sign Up
                </button>
              </div>
            </div>
          )}

          <div className="bg-[#ebeff3]">
            <div className="flex justify-center pt-4">
              <span className="flex  text-black text-[14px]">
                {`Already have an account?`}
                <a style={{ textDecoration: "none" }} href={"/auth/login"}>
                  <div className="text-orange ml-1">Log in</div>
                </a>
              </span>
            </div>

            <div
              className="bg-[#ebeff3] p-4 "
              style={{
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
            >
              <div className="text-[11.5px] sm:text-[15px] items-center w-full justify-center flex  font-ubuntu">
                A product of
                <div className="flex justify-center items-center px-1 mt-1">
                  <img src={GeniusFooterLogo} alt="genius office" width={18} />
                </div>
                <label className="font-semibold pr-1">genius office</label>
                2023. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSignUp;
