import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PurchaseColumns } from "./columns";
import { selectPrefix, selectWidth } from "../utility/Redux/profile";
import {
  ModifyButton,
  PrintButton,
  SaveButton,
  UndoButton,
} from "../utility/component/buttons";
import moment from "moment";
import GeniustextInput from "../utility/component/mastertext";
import EditableDataGrid from "../utility/component/datagrid/editableDatagrid";
import {
  setAlertWithTitle,
  setIssueStockItem,
  setnumericKeyboardStatus,
  setnumericKeyboardType,
  setSpinnerLoading,
} from "../utility/Redux/modal";
import uniqeId from "uniqid";
import ModNumericKeyboard from "../utility/component/modalNumeric";
import CustomHeader from "./printReportHeader";
import { PrintColumns } from "./printColumns";
import Footer from "./printReportFooter";
import PurchasePrint from "./purchasePrint";
import { toProperCase } from "../utility/component/format";
import { ItemMasterHelp } from "../help";
import TextBoxMui from "../utility/component/textBoxMui";
import FirstIcodeItem from "./icodeEnterValue";
import { IsEnterPressed } from "../utility/functions/common";
import {
  FormatNumber2,
  IscontainsAlphabetic,
} from "../utility/functions/formatNumber";
import checkLastPurchasePrice from "./common";
import { selectToken } from "../utility/Redux/security";
import BarcodeScanner from "../utility/component/barcodeScanner";
import IsCameraAvailval from "../utility/functions/isCameraAvailvl";
import { getItemByAlios, getItemByCode } from "../utility/constant";
import { enterRowWithItemDetails } from "./fun";
const Datagrid = ({
  savePurchaseBill,
  setItemMasterStatus,
  setBarCodeListStatus,
  setBarCodeList,
  delayedFunction,
  setPartyDetails,
  partyDetails,
  rows,
  setRows,
  alertTwoButtonModal,
  totalSum,
  vatTotal,
  cessTotal,
  cgstTotal,
  sgstTotal,
  igstTotal,
  disqTotal,
  qntyTotal,
  surchargeTotal,
  tcsTotal,
  roundOff,
  grandTotal,
  clearState,
  setAccountMasterHelpStatus,
  printRef,
  muiTextBox,
  currentINVNO,
  inputRef,
  frieghtRef,
  setAlertTwoButtonModal,
}) => {
  const width = useSelector(selectWidth);
  const columns = PurchaseColumns(width);
  const [lastPrice, setLastPrice] = useState("");
  const printColumns = PrintColumns(width);
  const [searchText, setSearchText] = useState("");
  const [itemMasterHelpStatus, setItemMasterHelpStatus] = useState(false);
  const [scannerItem, setScannerItem] = useState("");
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const [rowId, setRowId] = useState("");
  const dispatch = useDispatch();
  const [isDeviceHaveCamera, setIsDeviceHaveCamera] = useState(false);
  const [scannerStatus, setScannerStatus] = useState(false);
  const delayedFunctionForFocus = (id) => {
    setTimeout(() => {
      // Function to execute after 30 seconds
      const nearestInput = document.getElementById(id);
      if (nearestInput) {
        nearestInput.focus();
        nearestInput.select();
      }
    }, 500);
  };
  const keyDown = (e, currentFieldName, id) => {
    const value = e.target.value;

    if (currentFieldName === "ICODE" && e.code === "F4") {
      if (partyDetails.NAME) {
        setRowId(id);
        setItemMasterHelpStatus(true);
      } else {
        setAlertTwoButtonModal({
          ...alertTwoButtonModal,
          status: true,
          title: "Alert",
          msg: "Please Select party First.",
          button1: "ok",

          button1Click: () => {
            setAlertTwoButtonModal({
              ...alertTwoButtonModal,
              status: false,
            });
            setAccountMasterHelpStatus(true);
          },
        });
      }
    } else {
      if (
        (currentFieldName === "ICODE" &&
          e.code === "Enter" &&
          value !== "" &&
          value !== "0") ||
        (e.code === "NumpadEnter" &&
          value !== "" &&
          value !== "0" &&
          currentFieldName === "ICODE")
      ) {
        if (partyDetails.NAME) {
          if (!isNaN(value)) {
            const v = value.toString().replace(" ", "");
            if (v) {
              const isFilled = rows.find((r) => r.id === id);
              console.log(isFilled);
              if (!isFilled?.ITEMNAME) {
                if (
                  !rows.find((o) => {
                    console.log(o);
                    if (o.ITEMNAME) {
                      if (Number(o.ICODE) === Number(value) && o.id !== id) {
                        return o; // Return the object if condition matches
                      }
                      if (
                        o?.ALIAS_CODE?.toString() === value.toString() &&
                        o.id !== id
                      ) {
                        return o; // Return the object if condition matches
                      }
                    }
                    return false;
                  })
                ) {
                  if (
                    e.code === "Enter" ||
                    e.code === "NumpadEnter" ||
                    e.code === "ArrowRight"
                  ) {
                    const currentFieldIndex = columns.findIndex(
                      (column) => column.field === currentFieldName
                    );
                    const nextEditableField = columns.find(
                      (column, index) =>
                        index > currentFieldIndex && column.editable
                    );
                    if (nextEditableField) {
                      const nearestInput = document.getElementById(
                        id + "-" + nextEditableField.field
                      );
                      console.log(nearestInput);
                      setTimeout(() => {
                        if (nearestInput) {
                          nearestInput.focus();
                          nearestInput.select();
                        }
                      }, 2000);
                    }
                  }
                  getItemByCode(prefix, value, token, dispatch, true).then(
                    (codeItem) => {
                      if (!codeItem[0]) {
                        getItemByAlios(
                          prefix,
                          value,
                          token,
                          dispatch,
                          true
                        ).then((aliosItem) => {
                          enterRowWithItemDetails(
                            aliosItem,
                            rows,
                            id,
                            prefix,
                            partyDetails,
                            token,
                            dispatch,
                            setLastPrice,
                            setRows,
                            value,
                            setAlertTwoButtonModal,
                            setItemMasterStatus
                          );
                        });
                      } else {
                        enterRowWithItemDetails(
                          codeItem,
                          rows,
                          id,
                          prefix,
                          partyDetails,
                          token,
                          dispatch,
                          setLastPrice,
                          setRows,
                          value,
                          setAlertTwoButtonModal,
                          setItemMasterStatus
                        );
                      }
                    }
                  );
                } else {
                  setAlertTwoButtonModal((o) => {
                    return {
                      ...o,
                      status: true,
                      title: "Alert",
                      msg: "Item already exist",
                      button1: "ok",
                      button1Click: () => {
                        setAlertTwoButtonModal((o) => {
                          return {
                            ...o,
                            status: false,
                          };
                        });
                      },
                    };
                  });
                }
              } else {
                const currentFieldIndex = columns.findIndex(
                  (column) => column.field === currentFieldName
                );
                const nextEditableField = columns.find(
                  (column, index) =>
                    index > currentFieldIndex && column.editable
                );
                if (nextEditableField) {
                  const nearestInput = document.getElementById(
                    id + "-" + nextEditableField.field
                  );
                  console.log(nearestInput);
                  if (nearestInput) {
                    nearestInput.focus();
                    nearestInput.select();
                  }
                }
              }
            } else {
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: "Please enter Icode to continue",
                })
              );
            }
          } else {
            setRows((r) =>
              r.map((o) => {
                if (o.id === id) {
                  return {
                    ...o,
                    ICODE: "",
                  };
                } else {
                  return o;
                }
              })
            );
            setSearchText(value);
            setRowId(id);
            setItemMasterHelpStatus(true);
          }
        } else {
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: true,
            title: "Alert",
            msg: "Please Select party First.",
            button1: "ok",

            button1Click: () => {
              setAlertTwoButtonModal({
                ...alertTwoButtonModal,
                status: false,
              });
              setAccountMasterHelpStatus(true);
            },
          });
        }
      } else {
        if (currentFieldName === "ICODE") {
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: true,
            title: "Alert",
            msg: "Please Enter Item Code First.",
            button1: "ok",

            button1Click: () => {
              setAlertTwoButtonModal({
                ...alertTwoButtonModal,
                status: false,
              });
            },
          });
        } else {
          if (
            e.code === "Enter" ||
            e.code === "NumpadEnter" ||
            e.code === "ArrowRight"
          ) {
            const currentFieldIndex = columns.findIndex(
              (column) => column.field === currentFieldName
            );
            const nextEditableField = columns.find(
              (column, index) => index > currentFieldIndex && column.editable
            );
            if (nextEditableField) {
              const nearestInput = document.getElementById(
                id + "-" + nextEditableField.field
              );
              if (nearestInput) {
                nearestInput.focus();
                nearestInput.select();
              }
            }
          }
        }
      }
    }
  };

  const nextRow = (index) => {
    delayedFunction(rows[index].id);
  };
  const deletRow = (id) => {
    setRows((o) => {
      const list = o.filter((r) => r.id !== id);
      if (list.find((o) => o.ITEMNAME === "")) {
        return list;
      } else {
        return [
          ...list,
          {
            id: uniqeId(),
            INVNO: "",
            DATE: moment().format("YYYY-MM-DD"),
            PUR_BILL_NO: "",
            PUR_BILL_DATE: moment().format("YYYY-MM-DD"),
            PUR_BILL_AMOUNT: "0",
            ENT_TIME: moment().format("YYYY-MM-DD"),
            BILL_MODE: rows[0].BILL_MODE,
            BILL_TYPE: rows[0].BILL_TYPE,
            ACCOUNT_NO: "",
            NAME: "",
            ADDRESS: "",
            MOBILE: "",
            TAX_NUMBER: "",
            ICODE: "",
            ITEMNAME: "",
            PACKING: "",
            UNIT: "",
            SECTION_NAME: "",
            SUB_SEC_NAME: "",
            BRAND_NAME: "",
            PUR_PRICE: "0",
            MRP_RATE: "0",
            SALE_PRICE: "0",
            QNTY: "0",
            AMOUNT: "0",
            DISQ_RT: "0",
            DISQ_AMT: "0",
            TAX1_RATE: "0",
            TAX1_AMT: "0",
            TAX2_RATE: "0",
            TAX2_AMT: "0",
            TAX3_RATE: "0",
            TAX3_AMT: "0.00",
            NET_AMT: "0.00",
            BASIC_RATE: "0.00",
            BASIC_AMT: "0.00",
            TOTAL: "0",
            FREIGHT: "0.00",
            REMARKS: "",
            ROUND_OFF: "0",
            GTOTAL: "0",
            HSN_CODE: "",
            GR_NO: "0",
            PO_NO: "0",
            INCL_EXCL: "Excluded",
            CESS_RATE: "0",
            CESS_AMOUNT: "0",
            TCS_RATE: "0",
            TCS_AMOUNT: "0",
            ADDITIONAL_CESS: "0",
            VAT_AMOUNT: "0",
            SURCHARGE_RATE: "0",
            SURCHARGE_AMT: "0.00",
            VAT_RATE: "0",
            SCHEME_RATE: "0",
            SCHEME_AMOUNT: "0.00",
            TOTAL_SCHEME_AMOUNT: "0.00",
            ADD_CHARGES: "0.00",
            LESS_CHARGES: "0.00",
            INCL_TAX: "No",
            DEP_NAME: "",
            PURCHASE_PIC: "",
            EXP_DATE: "",
            BATCH: "",
            MARK_RATE: 0,
            FREE_QNTY: 0,
          },
        ];
      }
    });
  };

  const CellEdit = ({ id, field, value }) => {
    if (field === "ICODE") {
      const isSameIdExist = rows.filter((row) => row.id === id);
      if (isSameIdExist[0]) {
        setRows((old) =>
          old.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                ICODE: value,
                INVNO: "",
                DATE: moment().format("YYYY-MM-DD"),
                PUR_BILL_NO: "",
                PUR_BILL_DATE: moment().format("YYYY-MM-DD"),
                PUR_BILL_AMOUNT: "0",
                ENT_TIME: moment().format("YYYY-MM-DD"),

                ACCOUNT_NO: "",
                NAME: "",
                ADDRESS: "",
                MOBILE: "",
                TAX_NUMBER: "",
                ITEMNAME: "",
                PACKING: "",
                UNIT: "",
                SECTION_NAME: "",
                SUB_SEC_NAME: "",
                BRAND_NAME: "",
                PUR_PRICE: "0",
                MRP_RATE: "0",
                SALE_PRICE: "0",
                QNTY: "0",
                AMOUNT: "0",
                DISQ_RT: "0",
                DISQ_AMT: "0",
                TAX1_RATE: "0",
                TAX1_AMT: "0",
                TAX2_RATE: "0",
                TAX2_AMT: "0",
                TAX3_RATE: "0",
                TAX3_AMT: "0.00",
                NET_AMT: "0.00",
                BASIC_RATE: "0.00",
                BASIC_AMT: "0.00",
                TOTAL: "0",
                FREIGHT: "0.00",
                REMARKS: "",
                ROUND_OFF: "0",
                GTOTAL: "0",
                HSN_CODE: "",
                GR_NO: "0",
                PO_NO: "0",
                INCL_EXCL: "Excluded",
                CESS_RATE: "0",
                CESS_AMOUNT: "0",
                TCS_RATE: "0",
                TCS_AMOUNT: "0",
                ADDITIONAL_CESS: "0",
                VAT_AMOUNT: "0",
                SURCHARGE_RATE: "0",
                SURCHARGE_AMT: "0.00",
                VAT_RATE: "0",
                SCHEME_RATE: "0",
                SCHEME_AMOUNT: "0.00",
                TOTAL_SCHEME_AMOUNT: "0.00",
                ADD_CHARGES: "0.00",
                LESS_CHARGES: "0.00",
                INCL_TAX: "No",
                DEP_NAME: "",
                PURCHASE_PIC: "",
                EXP_DATE: "",
                BATCH: "",
              };
            } else {
              return item;
            }
          })
        );
      } else {
        setRows((old) => {
          return [
            ...old,
            {
              ...old[0],
              ICODE: value,
              INVNO: "",
              DATE: moment().format("YYYY-MM-DD"),
              PUR_BILL_NO: "",
              PUR_BILL_DATE: moment().format("YYYY-MM-DD"),
              PUR_BILL_AMOUNT: "0",
              ENT_TIME: moment().format("YYYY-MM-DD"),
              ACCOUNT_NO: "",
              NAME: "",
              ADDRESS: "",
              MOBILE: "",
              TAX_NUMBER: "",
              ITEMNAME: "",
              PACKING: "",
              UNIT: "",
              SECTION_NAME: "",
              SUB_SEC_NAME: "",
              BRAND_NAME: "",
              PUR_PRICE: "0",
              MRP_RATE: "0",
              SALE_PRICE: "0",
              QNTY: "0",
              AMOUNT: "0",
              DISQ_RT: "0",
              DISQ_AMT: "0",
              TAX1_RATE: "0",
              TAX1_AMT: "0",
              TAX2_RATE: "0",
              TAX2_AMT: "0",
              TAX3_RATE: "0",
              TAX3_AMT: "0.00",
              NET_AMT: "0.00",
              BASIC_RATE: "0.00",
              BASIC_AMT: "0.00",
              TOTAL: "0",
              FREIGHT: "0.00",
              REMARKS: "",
              ROUND_OFF: "0",
              GTOTAL: "0",
              HSN_CODE: "",
              GR_NO: "0",
              PO_NO: "0",
              INCL_EXCL: "Excluded",
              CESS_RATE: "0",
              CESS_AMOUNT: "0",
              TCS_RATE: "0",
              TCS_AMOUNT: "0",
              ADDITIONAL_CESS: "0",
              VAT_AMOUNT: "0",
              SURCHARGE_RATE: "0",
              SURCHARGE_AMT: "0.00",
              VAT_RATE: "0",
              SCHEME_RATE: "0",
              SCHEME_AMOUNT: "0.00",
              TOTAL_SCHEME_AMOUNT: "0.00",
              ADD_CHARGES: "0.00",
              LESS_CHARGES: "0.00",
              INCL_TAX: "No",
              DEP_NAME: "",
              PURCHASE_PIC: "",
              EXP_DATE: "",
              BATCH: "",
            },
          ];
        });
      }
    }

    if (
      field === "QNTY" ||
      field === "PUR_PRICE" ||
      field === "DISQ_RT" ||
      field === "DISQ_AMT" ||
      // field === "VAT_AMOUNTT" ||
      field === "VAT_RATE" ||
      field === "SURCHARGE_AMT" ||
      field === "SURCHARGE_RATE" ||
      field === "BATCH" ||
      field === "EXP_DATE" ||
      field === "FREE_QNTY"
    ) {
      if (
        !isNaN(value) ||
        field === "EXP_DATE" ||
        field === "BATCH" ||
        (value.includes("-") &&
          !value.includes("--") &&
          field === "QNTY" &&
          !IscontainsAlphabetic(value))
      ) {
        const updatedRows = rows.map((row) => {
          if (row.id === id) {
            const qnty =
              field === "QNTY"
                ? value.includes("-")
                  ? -value.replace("-", "")
                  : value
                : row.QNTY;
            console.log(qnty);

            const pur_price =
              field === "PUR_PRICE" ? Number(value) : row.PUR_PRICE;
            const diq_rate =
              field === "DISQ_RT"
                ? value
                  ? parseFloat(value)
                  : "0"
                : field === "DISQ_AMT"
                ? (
                    (parseFloat(value) /
                      (parseFloat(qnty) * parseFloat(pur_price))) *
                    100
                  ).toFixed(2)
                : row.DISQ_RT;
            const diq_amt =
              field === "DISQ_AMT"
                ? Number(value)
                : field === "DISQ_RT"
                ? (parseFloat(qnty) *
                    parseFloat(pur_price) *
                    parseFloat(value)) /
                  100
                : row.DISQ_AMT;
            const tax1_rate =
              field === "TAX1_RATE" ? Number(value) : row.TAX1_RATE;
            const tax2_rate =
              field === "TAX2_RATE" ? Number(value) : row.TAX2_RATE;
            const tax3_rate =
              field === "TAX3_RATE" ? Number(value) : row.TAX3_RATE;
            const vat_rate =
              field === "VAT_RATE"
                ? parseFloat(value)
                : field === "VAT_AMOUNT"
                ? (parseFloat(value) / 100) * parseFloat(pur_price)
                : row.VAT_RATE;
            const tcs_rate = 0;
            const surcharge_rate =
              field === "SURCHARGE_RATE"
                ? value
                : field === "SURCHARGE_AMT"
                ? (parseFloat(value) / 100) * parseFloat(pur_price)
                : row.SURCHARGE_RATE;

            const EXP_DATE = field === "EXP_DATE" ? value : row.EXP_DATE;

            if (diq_amt > pur_price * qnty && !qnty.toString().includes("-")) {
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: "Dicount amount can not be more than Purchase Price.",
                })
              );

              return row;
            }
            const item = rows.filter((i) => i.id === id);
            const batch = field === "BATCH" ? value : item[0].BATCH;
            if (field === "EXP_DATE") {
              return {
                ...row,
                EXP_DATE: EXP_DATE,
              };
            }

            if (field === "BATCH") {
              return {
                ...row,
                BATCH: batch,
              };
            }

            if (field === "FREE_QNTY") {
              return {
                ...row,
                FREE_QNTY: value,
              };
            }
            const i = FirstIcodeItem(
              id,
              qnty,
              pur_price,
              diq_rate,
              diq_amt,
              tax1_rate,
              tax2_rate,
              tax3_rate,
              vat_rate,
              tcs_rate,
              surcharge_rate,

              partyDetails,
              item[0],
              EXP_DATE,
              value
            );
            return i;
            // getItemByCode(prefix,row.ICODE,token,dispatch).then(item =>{

            // })
          }
          return row;
        });

        setRows(updatedRows);
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Please Enter Number Value",
          })
        );
      }
    }
  };
  useEffect(() => {
    IsCameraAvailval()
      .then(() => {
        setIsDeviceHaveCamera(true);
      })
      .catch(() => {
        setIsDeviceHaveCamera(false);
      });
  }, []);
  const windowWidth = useSelector(selectWidth);
  const footerGrid =
    "mt-3 grid grid-cols-[150px,150px] d:grid-cols-[120px,120px,120px] 2xl:grid-cols-[120px,120px,120px,120px] gap-3 ";
  const footerGrid2 = "mt-3 grid ll:grid-cols-2 grid-cols-1 gap-3";
  const footerGrid3 = "mt-3 grid grid-cols-1 gap-3";

  return (
    <div
      style={{
        height: `calc(100% - ${windowWidth > 1440 ? "141px" : "200px"})`,
      }}
      className="  w-auto "
    >
      <div className="flex text-red-500 pb-2">
        <div className="font-semibold mr-3">Last Purchase Price</div> :
        <div className="ml-3">{lastPrice && FormatNumber2(lastPrice)}</div>
      </div>
      <div
        style={{
          height: `calc(100% - ${
            windowWidth < 1280
              ? "380px"
              : windowWidth < 1536
              ? "260px"
              : windowWidth < 1800
              ? "280px"
              : "240px"
          })`,
        }}
        className=" min-h-[200px]"
      >
        <EditableDataGrid
          nextRow={nextRow}
          keyDown={keyDown}
          columns={columns}
          data={rows}
          onScannerClick={
            isDeviceHaveCamera
              ? (e) => {
                  setScannerItem(e);
                  setScannerStatus(true);
                }
              : false
          }
          deletRow={deletRow}
          onEditCell={CellEdit}
          firstInputRef={inputRef}
          onCellDoubleClick={(e) => {
            if (e.column.editable && e.column.field !== "EXP_DATE") {
              if (true) {
                dispatch(setnumericKeyboardType(e.column.field));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: e.column.field,

                    id: e.row.original.id,
                    value: e.value,
                  })
                );
              } else {
              }
            }
          }}
        />
      </div>

      <div className="flex  mt-[60px]">
        <div
          style={{ border: "1px solid #b5b3b3", borderRadius: "8px" }}
          className="p-3 bg-white "
        >
          <div className="-mt-7 bg-white text-[15px] font-semibold w-fit px-2">
            Taxes
          </div>
          <div className={footerGrid}>
            {muiTextBox ? (
              <TextBoxMui
                readOnly={true}
                type={"number"}
                value={cgstTotal.toFixed(2)}
                label={"Total CGST"}
                align={"right"}
              />
            ) : (
              <GeniustextInput
                title={"Total CGST"}
                type={"number"}
                align={"right"}
                value={cgstTotal.toFixed(2)}
                placeholder={"0.00"}
                readOnly
              />
            )}
            {muiTextBox ? (
              <TextBoxMui
                readOnly={true}
                type={"number"}
                align={"right"}
                value={sgstTotal.toFixed(2)}
                label={"Total SGST"}
              />
            ) : (
              <GeniustextInput
                title={"Total SGST"}
                align={"right"}
                type={"Number"}
                value={sgstTotal.toFixed(2)}
                placeholder={"0.00"}
                readOnly
              />
            )}
            {muiTextBox ? (
              <TextBoxMui
                readOnly={true}
                align={"right"}
                type={"number"}
                value={igstTotal.toFixed(2)}
                label={"Total IGST"}
              />
            ) : (
              <GeniustextInput
                title={"Total IGST"}
                align={"right"}
                type={"Number"}
                value={igstTotal.toFixed(2)}
                placeholder={"0.00"}
                readOnly
              />
            )}
            {muiTextBox ? (
              <TextBoxMui
                readOnly={true}
                align={"right"}
                type={"number"}
                value={vatTotal.toFixed(2)}
                label={"Total VAT"}
              />
            ) : (
              <GeniustextInput
                title={"Total VAT"}
                align={"right"}
                type={"Number"}
                value={vatTotal.toFixed(2)}
                placeholder={"0.00"}
                readOnly
              />
            )}
            {muiTextBox ? (
              <TextBoxMui
                readOnly={true}
                align={"right"}
                type={"number"}
                value={surchargeTotal.toFixed(2)}
                label={"Total Surcharge"}
              />
            ) : (
              <GeniustextInput
                title={"Total Surcharge"}
                align={"right"}
                type={"Number"}
                value={surchargeTotal.toFixed(2)}
                placeholder={"0.00"}
                readOnly
              />
            )}

            {muiTextBox ? (
              <TextBoxMui
                readOnly={true}
                align={"right"}
                type={"number"}
                value={tcsTotal.toFixed(2)}
                label={"Total TCS"}
              />
            ) : (
              <GeniustextInput
                title={"Total TCS"}
                type={"Number"}
                align={"right"}
                value={tcsTotal.toFixed(2)}
                placeholder={"0.00"}
                readOnly
              />
            )}

            {muiTextBox ? (
              <TextBoxMui
                readOnly={true}
                align={"right"}
                type={"number"}
                value={cessTotal.toFixed(2)}
                label={"Total Cess"}
              />
            ) : (
              <GeniustextInput
                align={"right"}
                title={"Total Cess"}
                type={"Number"}
                value={cessTotal.toFixed(2)}
                placeholder={"0.00"}
                readOnly
              />
            )}

            {muiTextBox ? (
              <TextBoxMui
                readOnly={true}
                type={"number"}
                value={qntyTotal}
                label={"Total Qnty."}
              />
            ) : (
              <GeniustextInput
                title={"Total Qnty."}
                type={"Number"}
                width="110px"
                value={qntyTotal}
                placeholder={"0.00"}
                readOnly
              />
            )}
          </div>
        </div>
        <div className="flex ml-3 flex-1">
          <div
            style={{ border: "1px solid #b5b3b3", borderRadius: "8px" }}
            className="p-3 bg-white flex-1 "
          >
            <div className="-mt-7 bg-white text-[15px] font-semibold w-fit px-2">
              Additions
            </div>
            <div className={footerGrid2}>
              {muiTextBox ? (
                <TextBoxMui
                  refrance={frieghtRef}
                  onKeyDown={(e) => {
                    if (e.code === "Enter" || e.code === "NumpadEnter") {
                      const nearestInput = document.getElementById("charge");
                      // Focus on the nearest input if it exists
                      if (nearestInput) {
                        nearestInput.focus();
                      }
                    }
                  }}
                  bgColor="#f1f5f9"
                  type={"number"}
                  align={"right"}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) =>
                    setPartyDetails((o) => {
                      return {
                        ...o,
                        FREIGHT: e.target.value ? e.target.value : 0,
                      };
                    })
                  }
                  value={partyDetails.FREIGHT}
                  label={"Add Freight"}
                />
              ) : (
                <GeniustextInput
                  title={"Add Freight"}
                  type={"number"}
                  onFocus={(e) => {
                    e.current.select();
                  }}
                  align={"right"}
                  placeholder={"0.00"}
                  value={partyDetails.FREIGHT}
                  onChange={(e) =>
                    setPartyDetails((o) => {
                      return {
                        ...o,
                        FREIGHT: e.target.value ? e.target.value : 0,
                      };
                    })
                  }
                />
              )}

              {muiTextBox ? (
                <TextBoxMui
                  onKeyDown={(e) => {
                    if (e.code === "Enter" || e.code === "NumpadEnter") {
                      const nearestInput =
                        document.getElementById("additional");
                      // Focus on the nearest input if it exists
                      if (nearestInput) {
                        nearestInput.focus();
                      }
                    }
                  }}
                  align={"right"}
                  bgColor="#f1f5f9"
                  id={"charge"}
                  type={"number"}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) =>
                    setPartyDetails((o) => {
                      return {
                        ...o,
                        ADD_CHARGES: e.target.value ? e.target.value : 0,
                      };
                    })
                  }
                  value={partyDetails.ADD_CHARGES}
                  label={"Add Charges"}
                />
              ) : (
                <GeniustextInput
                  title={"Add Charges"}
                  type={"number"}
                  align={"right"}
                  placeholder={"0.00"}
                  value={partyDetails.ADD_CHARGES}
                  onChange={(e) =>
                    setPartyDetails((o) => {
                      return {
                        ...o,
                        ADD_CHARGES: e.target.value ? e.target.value : 0,
                      };
                    })
                  }
                />
              )}

              {muiTextBox ? (
                <TextBoxMui
                  id={"additional"}
                  type={"number"}
                  onFocus={(e) => e.target.select()}
                  align={"right"}
                  bgColor="#f1f5f9"
                  onChange={(e) =>
                    setPartyDetails((o) => {
                      return {
                        ...o,
                        ADDITIONAL_CESS: e.target.value ? e.target.value : 0,
                      };
                    })
                  }
                  onKeyDown={(e) => {
                    if (e.code === "Enter" || e.code === "NumpadEnter") {
                      const nearestInput =
                        document.getElementById("SCHEME_AMOUNT");
                      // Focus on the nearest input if it exists
                      if (nearestInput) {
                        nearestInput.focus();
                      }
                    }
                  }}
                  value={partyDetails.ADDITIONAL_CESS}
                  label={"Additional CESS"}
                />
              ) : (
                <GeniustextInput
                  title={"Additional CESS"}
                  type={"number"}
                  align={"right"}
                  placeholder={"0.00"}
                  value={partyDetails.ADDITIONAL_CESS}
                  onChange={(e) =>
                    setPartyDetails((o) => {
                      return {
                        ...o,
                        ADDITIONAL_CESS: e.target.value ? e.target.value : 0,
                      };
                    })
                  }
                />
              )}
            </div>
          </div>
          <div
            style={{ border: "1px solid #b5b3b3", borderRadius: "8px" }}
            className="p-3 ml-3  bg-white flex-1"
          >
            <div className="-mt-7 bg-white text-[15px] font-semibold w-fit px-2">
              Less & Discount
            </div>
            <div className={footerGrid2}>
              {muiTextBox ? (
                <TextBoxMui
                  type={"number"}
                  align={"right"}
                  readOnly={true}
                  value={disqTotal.toFixed(2)}
                  label={"Total Discount"}
                />
              ) : (
                <GeniustextInput
                  title={"Total Discount"}
                  align={"right"}
                  type={"number"}
                  value={disqTotal.toFixed(2)}
                  placeholder={"0.00"}
                  readOnly
                />
              )}

              {muiTextBox ? (
                <TextBoxMui
                  align={"right"}
                  type={"number"}
                  id={"SCHEME_AMOUNT"}
                  bgColor="#f1f5f9"
                  onFocus={(e) => e.target.select()}
                  onChange={(e) =>
                    setPartyDetails((o) => {
                      return {
                        ...o,
                        SCHEME_AMOUNT: e.target.value ? e.target.value : 0,
                      };
                    })
                  }
                  onKeyDown={(e) => {
                    if (e.code === "Enter" || e.code === "NumpadEnter") {
                      const nearestInput =
                        document.getElementById("LESS_CHARGES");
                      // Focus on the nearest input if it exists
                      if (nearestInput) {
                        nearestInput.focus();
                      }
                    }
                  }}
                  value={partyDetails.SCHEME_AMOUNT}
                  label={"Less Scheme Amt."}
                />
              ) : (
                <GeniustextInput
                  title={"Less Scheme Amt."}
                  type={"Number"}
                  align={"right"}
                  placeholder={"0.00"}
                  bgColor="#f1f5f9"
                  onChange={(e) =>
                    setPartyDetails((o) => {
                      return {
                        ...o,
                        SCHEME_AMOUNT: e.target.value ? e.target.value : 0,
                      };
                    })
                  }
                  value={partyDetails.SCHEME_AMOUNT}
                />
              )}

              {muiTextBox ? (
                <TextBoxMui
                  type={"number"}
                  id={"LESS_CHARGES"}
                  onFocus={(e) => e.target.select()}
                  align={"right"}
                  bgColor="#f1f5f9"
                  onKeyDown={(e) => {
                    IsEnterPressed(e).then((v) => {
                      if (v) {
                        setAlertTwoButtonModal({
                          title: "Alert",
                          msg: "Do you want to save this data?",
                          status: true,
                          button1: "Yes",
                          button2: "No",
                          button1Click: () => {
                            setAlertTwoButtonModal((o) => {
                              return {
                                ...o,
                                status: false,
                              };
                            });
                            savePurchaseBill();
                          },
                          button2Click: () => {
                            setAlertTwoButtonModal((o) => {
                              return {
                                ...o,
                                status: false,
                              };
                            });
                          },
                        });
                      }
                    });
                  }}
                  onChange={(e) => {
                    setPartyDetails((o) => {
                      return {
                        ...o,
                        LESS_CHARGES: e.target.value,
                      };
                    });
                  }}
                  value={partyDetails.LESS_CHARGES}
                  label={"Less Charge"}
                />
              ) : (
                <GeniustextInput
                  title={"Less Charge"}
                  align={"right"}
                  type={"Number"}
                  value={partyDetails.LESS_CHARGES}
                  onChange={(e) => {
                    setPartyDetails((o) => {
                      return {
                        ...o,
                        LESS_CHARGES: e.target.value,
                      };
                    });
                  }}
                  placeholder={"0.00"}
                />
              )}
            </div>
          </div>
          <div
            style={{ border: "1px solid #b5b3b3", borderRadius: "8px" }}
            className="px-3 ml-3 bg-white flex-1 "
          >
            <div className={footerGrid2}>
              {muiTextBox ? (
                <TextBoxMui
                  type={"number"}
                  align={"right"}
                  readOnly={true}
                  value={totalSum.toFixed(2)}
                  label={"Total"}
                />
              ) : (
                <GeniustextInput
                  title={"Total"}
                  align={"right"}
                  type={"number"}
                  value={totalSum.toFixed(2)}
                  placeholder={"0.00"}
                  readOnly
                />
              )}

              {muiTextBox ? (
                <TextBoxMui
                  type={"number"}
                  align={"right"}
                  readOnly={true}
                  value={roundOff.toFixed(2)}
                  label={"Round Off"}
                />
              ) : (
                <GeniustextInput
                  title={"Round Off"}
                  align={"right"}
                  type={"Number"}
                  placeholder={"0.00"}
                  value={roundOff.toFixed(2)}
                  readOnly
                />
              )}

              {muiTextBox ? (
                <TextBoxMui
                  align={"right"}
                  type={"number"}
                  readOnly={true}
                  fontWeight={"700"}
                  value={grandTotal}
                  label={"Grand Total"}
                  color={"black"}
                  fontSize={"15px"}
                />
              ) : (
                <GeniustextInput
                  title={"Grand Total"}
                  type={"Number"}
                  titleBold={true}
                  align={"right"}
                  value={grandTotal}
                  placeholder={"0.00"}
                  readOnly
                />
              )}
            </div>
          </div>
          <div
            style={{
              border: "1px solid #b5b3b3",
              borderRadius: "8px",
              display:
                windowWidth < 1280 || (windowWidth > 1434 && windowWidth < 1800)
                  ? "none"
                  : "block",
            }}
            className="px-3 ml-5 bg-white  "
          >
            <div className={footerGrid3}>
              {muiTextBox ? (
                <textarea
                  type={"text"}
                  placeholder="Remarks"
                  value={partyDetails.REMARKS}
                  onChange={(t) =>
                    setPartyDetails((e) => {
                      return {
                        ...e,
                        REMARKS: toProperCase(t.target.value),
                      };
                    })
                  }
                  className="bg-white h-[50px] p-1 outline-none border  rounded-sm p "
                  label={"Remarks"}
                />
              ) : (
                <GeniustextInput
                  title={"Remarks"}
                  type={"text"}
                  value={partyDetails.REMARKS}
                  onChange={(t) =>
                    setPartyDetails((e) => {
                      return {
                        ...e,
                        REMARKS: toProperCase(t.target.value),
                      };
                    })
                  }
                  label={"Remarks"}
                />
              )}

              <div className=" flex justify-end ">
                <div className="mr-1">
                  <PrintButton
                    onClick={() => {
                      if (rows.find((o) => o.ICODE && o.ITEMNAME)) {
                        setBarCodeList(rows);
                        setBarCodeListStatus(true);
                      } else {
                        dispatch(
                          setAlertWithTitle({
                            title: "Alert",
                            msg: "Nothing found to print",
                          })
                        );
                      }
                    }}
                  />
                </div>
                {partyDetails.INVNO ? (
                  <ModifyButton onClick={savePurchaseBill} />
                ) : (
                  <SaveButton onClick={savePurchaseBill} />
                )}

                <UndoButton onClick={clearState} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            windowWidth < 1280 || (windowWidth > 1434 && windowWidth < 1800)
              ? "flex"
              : "none",
        }}
        className=" mt-3"
      >
        <div className="flex">
          <label className="flex items-center">Remarks</label>
          <input
            style={{
              width: windowWidth <= 1300 ? "340px" : "460px",
              border: "1px solid #b5b3b3",
              borderRadius: "5px",
            }}
            className="p-1 ml-3 focus:outline-none"
            value={partyDetails.REMARKS}
            onChange={(t) =>
              setPartyDetails((e) => {
                return {
                  ...e,
                  REMARKS: toProperCase(t.target.value),
                };
              })
            }
          />
        </div>
        <div className="ml-3 flex justify-end w-[660px]">
          <div className="mr-1">
            <PrintButton
              onClick={() => {
                if (rows.find((o) => o.ICODE && o.ITEMNAME)) {
                  setBarCodeList(rows);
                  setBarCodeListStatus(true);
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Nothing found to print",
                    })
                  );
                }
              }}
            />
          </div>
          {partyDetails.INVNO ? (
            <ModifyButton onClick={savePurchaseBill} />
          ) : (
            <SaveButton onClick={savePurchaseBill} />
          )}

          <UndoButton onClick={clearState} />
        </div>
      </div>
      <div
        style={{
          display: "none",
        }}
      >
        <div ref={printRef}>
          <PurchasePrint
            customeHeader={
              <CustomHeader
                currentINVNO={currentINVNO}
                partyDetails={partyDetails}
              />
            }
            headerWidth={true}
            data={rows.filter((row) => row.ITEMNAME !== "")}
            marginTop={"300px"}
            columns={printColumns}
            footer={
              <Footer
                columns={printColumns}
                rows={rows}
                partyDetails={partyDetails}
              />
            }
          />
        </div>
      </div>
      <ItemMasterHelp
        searchText={searchText}
        status={itemMasterHelpStatus}
        onClose={() => {
          setSearchText("");

          setItemMasterHelpStatus(false);
        }}
        onRowClick={(row) => {
          setSearchText("");
          setItemMasterHelpStatus(false);
          const d = {
            id: rowId,
            code: "Enter",
            target: {
              value: row.row.ICODE,
            },
          };
          keyDown(d, "ICODE", d.id);
        }}
      />
      <ModNumericKeyboard
        onChange={(e) => {
          if (e.field === "ICODE") {
            const d = {
              id: e.id,
              code: "Enter",
              target: {
                value: e.value,
              },
            };

            keyDown(d, "ICODE", e.id);
          } else {
            CellEdit(e);
          }
        }}
        editType={""}
        mode={"dark"}
      />
      <BarcodeScanner
        setStatus={setScannerStatus}
        status={scannerStatus}
        onChange={(o) => {
          keyDown(
            { target: { value: o }, code: "Enter" },
            "ICODE",
            scannerItem
          );
        }}
      />
    </div>
  );
};

export default Datagrid;
