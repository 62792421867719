import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  selectPrefix,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setreportsFromDate,
  setreportsTodate,
} from "../../utility/Redux/profile";
import { FromToDate } from "../../utility/component/fromToDate";
import { GetSaleSummary } from "./func";
import { selectToken } from "../../utility/Redux/security";
import { SummaryColumns } from "./columns";
import Footer from "./footer";
import { useReactToPrint } from "react-to-print";
import GeniusTableReports from "../../utility/component/geniusComponents/geniusTableData";
import PrintWithFooter from "../../utility/component/datagrid/printReportWithFooter";
import IsLocalData from "../../utility/component/isLocalData";
import FromToDateMobile from "../../utility/component/fromToDateMobile";

const PaymodeSummary = () => {
  const prefix = useSelector(selectPrefix);
  const [summaryList, setSummaryList] = useState([]);
  const fromDate = useSelector(selectreportFromDate);
  const ToDate = useSelector(selectreportToDate);
  const width = useSelector(selectWidth);
  const printRef = useRef(null);
  const [getCustomerData, setCustomerData] = useState({
    table_prefix: prefix,
    FDATE: moment(fromDate).format("YYYY-MM-DD"),
    TDATE: moment(ToDate).format("YYYY-MM-DD"),
  });
  const columns = SummaryColumns(width);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const okButton = () => {
    GetSaleSummary(getCustomerData, token, dispatch, setSummaryList);
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
  @page {
    size: landscape;
  }
`,
  });

  useEffect(() => {
    setCustomerData((old) => {
      return {
        ...old,
        FDATE: moment(fromDate).format("YYYY-MM-DD"),
        TDATE: moment(ToDate).format("YYYY-MM-DD"),
      };
    });
  }, [fromDate, ToDate]);

  useEffect(() => {
    const callback = (details, data) => {
      setCustomerData(details[0]);
      setSummaryList(data);
      dispatch(setreportsFromDate(details[0].FDATE));
      dispatch(setreportsTodate(details[0].TDATE));
    };
    const elseFunc = () => {
    };
    IsLocalData("payModeSummary", callback, elseFunc);
  }, []);

  return (
    <div className=" bg-white flex flex-col h-full overflow-y-auto   ">
      <div style={{
      }} className=" flex  max-w-[740px]  flex-col h-full p-3  ">
        <div>
          {window.innerWidth <= 1000 ? (
            <>
              <FromToDateMobile
                submit={okButton}
                hideReset={true}
           
                onPrintClick ={ handlePrint}
                reset={() => {
                  setSummaryList([]);
                }}
              />
            </>
          ) : (
            <div className=" min-w-[700px]">
              <FromToDate
                submit={okButton}
                hideReset={true}
  onPrintClick= {handlePrint}
                reset={() => {
                  setSummaryList([]);
                }}
              />
            </div>
          )}
        </div>

        {summaryList[0] ? (
          <div
            style={{
              height: `calc(100% - ${width<1000?120: 60}px)`,
              width: "100%",
              marginTop: 10,
              width: `fit`,
            }}
            className=" mb-2  w-full"
          >
            <GeniusTableReports
          
              data={summaryList}
              columns={columns}
              footer={<Footer columns={columns} rows={summaryList} />}
            />

            <div className="w-full mt-3 ">
              <div
                style={{
                  display: "none",
                }}
              >
                <div ref={printRef}>
                  <PrintWithFooter
                    customeHeader={
                      <div className="items-center  ">
                        <div className="flex items-center">
                          <div className="mr-1 text-sm font-semibold flex justify-center ">
                            Pay Mode Summary
                          </div>
                          <div className="text-sm font-semibold">From :</div>
                          <div className="ml-1 text-sm">
                            {moment(fromDate).format("DD-MM-YYYY")}{" "}
                          </div>
                          <div className="text-sm font-semibold ml-2">To :</div>
                          <div className="ml-1 text-sm">
                            {moment(ToDate).format("DD-MM-YYYY")}{" "}
                          </div>
                        </div>
                      </div>
                    }
                    data={summaryList}
                    columns={columns}
                    marginTop={"120px"}
                    headerWidth={true}
                    footer={
                      <Footer columns={columns} rows={summaryList} />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full flex justify-center items-center text-sm text-gray-400 border mt-3 rounded-sm">
            No Records Found
          </div>
        )}

      
      </div>
    
  
   
    </div>
  );
};

export default PaymodeSummary;
