import moment from "moment";
import { FormatNumber2 } from "../../utility/functions/formatNumber";

export const SaleSummaryPrintColumns = (width) => {
    const columns = [
      {
        field: 'INVNO',
        accessor: 'INVNO',

        type: 'string',
        headerAlign: 'center',
        width: width < 768 ? 150 : width < 1400 ? 80 : 60,
        headerClassName: 'super-app-theme--header',
  
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Invoice'}{' '}
          </div>
        ),
      },

      {
        field: 'DATE',
        accessor: 'DATE',
        type: 'string',
        type:'date',
        headerAlign: 'center',
  
        width: width < 768 ? 100 : 100,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => {
          return (
            <div
              className={`${
                params.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full flex items-center  `}
            >
              {moment(params.value).format('YYYY-MM-DD')}{' '}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Date'}{' '}
          </div>
        ),
      },
      {
        field: 'ACCOUNT_NO',
        accessor: 'ACCOUNT_NO',
        type: 'string',
        width: width < 768 ? 100 : 60,
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Acc. No.'}{' '}
          </div>
        ),
      },
      {
        field: 'NAME',
        accessor: 'NAME',
        type: 'string',
        width: width < 768 ? 100 : width < 1400 ? 180 : 250,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Party Name
          </div>
        ),
      },
  
      {
        field: 'CUSTOMER_NAME',
        accessor: 'CUSTOMER_NAME',
        type: 'string',
        width: width < 768 ? 100 : width < 1400 ? 180 : 200,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Customer Name
          </div>
        ),
      },

      {
        field: 'TOTAL',
        accessor: 'TOTAL',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 120,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
            Total
          </div>
        ),
      },
     
  
  
  
  

      
      {
        field: 'TAX1_AMT',
        accessor: 'TAX1_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 110,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
        CGST Amount
          </div>
        ),
      },
      {
        field: 'TAX2_AMT',
        accessor: 'TAX2_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 110,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== 0 ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
        SGST Amount
          </div>
        ),
      },
   
      {
        field: 'TAX3_AMT',
        accessor: 'TAX3_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 110,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value!== 0  ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
       IGST Amount
          </div>
        ),
      },
  
      {
        field: 'GTOTAL',
        accessor: 'GTOTAL',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 120,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
          Grand Total
          </div>
        ),
      },
    ];
    return columns;
  };