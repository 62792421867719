import { Button, Grow } from '@mui/material';

import React from 'react';
import { useNavigate } from 'react-router-dom';


const Updation = () => {
  const navigation = useNavigate()
  const list = [
    {
      name: 'Payment Voucher',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/payment_voucher')
      },
    },
    {
      name: 'Reciept Voucher',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/reciept_voucher')
      },
    },
  
  ];

  return (
    <div className="h-full p-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 t:grid-cols-3 d:grid-cols-5 ll:grid-cols-6 gap-3  ">
        {list[0] &&
          list.map((e, i) => {
            return (
              <Grow
              key={i}
              in={true}
              style={{ transformOrigin: '0 0 0' }}
              {...(true ? { timeout: i === 0 ? 0 : i* 200 } : {})}
            >
              <Button
                key={i}
                onClick={() => {
                    e.onclick();
                }}
                variant={'contained'}
                style={{
                  textTransform: 'none',
                  backgroundColor: 'transparent',
                  padding: '0px',
                  borderRadius: '7px',
                  overflow: 'hidden',
                }}

              >
                <div className="bg-[#e9e9e5] py-4 w-full text-brown">
                  {e.name}
                </div>
              </Button>
              </Grow>
            );
          })}
      </div>
    
    </div>
  );
};

export default Updation;
