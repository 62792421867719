import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  selectPrefix,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
} from "../../utility/Redux/profile";
import { FromToDate } from "../../utility/component/fromToDate";
import { GetPurchaseSummary } from "./func";
import { selectToken } from "../../utility/Redux/security";
import { PurchaseSummaryColumns,  } from "./columns";
import Footer from "./footer";
import { useReactToPrint } from "react-to-print";
import GeniusTableReports from "../../utility/component/geniusComponents/geniusTableData";
import PrintWithFooter from "../../utility/component/datagrid/printReportWithFooter";
import IsLocalData from "../../utility/component/isLocalData";
import FromToDateMobile from "../../utility/component/fromToDateMobile";
import { ItemMasterHelp } from "../../help";
import { setAlertWithTitle } from "../../utility/Redux/modal";

const ItemWisePurchase = () => {
  const prefix = useSelector(selectPrefix);
  const [purchaseList, setPurchaseList] = useState([]);
  const fromDate = useSelector(selectreportFromDate);
  const ToDate = useSelector(selectreportToDate);
  const width = useSelector(selectWidth);
  const [itemData,setItemData] = useState('');

  const columns = PurchaseSummaryColumns(width);
  const [itemMasterHelpStatus,setItemMasterHelpStatus] = useState(false)
  const printRef = useRef(null);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const okButton = () => {
    if(itemData?.ICODE){
      GetPurchaseSummary({
        table_prefix: prefix,
        ICODE:itemData?itemData?.ICODE:""
      }, token, dispatch, setPurchaseList);
    }else{
      dispatch(setAlertWithTitle({
        title:'Alert',
        msg:'Please Select Item First'
      }))
    }
   
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    
  });

  useEffect(()=>{
    const callback = (details, data) => {
  setPurchaseList(data)

    };
    const elseFunc =()=>{console.log('nothing')}
    IsLocalData('itemPurchaseHistory', callback, elseFunc);
  },[])
  
  return (
    <div className=" bg-white flex flex-col h-full w-full overflow-y-scroll">
      <div className=" flex flex-col h-full p-3  w-full">
        <div>
          {window.innerWidth <= 1000 ? (
            <>
            <FromToDateMobile   
              submit={okButton}
              hideDate={true}
              onItemClick={()=>{
                  setItemMasterHelpStatus(true)

               }}
               itemData={itemData}
               />
            </>
          ) : (
            <FromToDate
            hideDate={true}
hideReset={true}
onPrintClick={handlePrint}
           onItemClick={()=>{
            setItemMasterHelpStatus(true)
           }}
           itemData={itemData}
              submit={okButton}
             
            
            />
          )}
        </div>

        <div
          style={{ height: `calc(100% - 0px)`, width: "100%", marginTop: 6 }}
          className=" mb-2 "
        >
          {purchaseList[0] ? (
            <GeniusTableReports
              columns={columns}
              data={purchaseList}
              footer={<Footer columns={columns} rows={purchaseList} />}
            />
          ) : (
            <div className="h-full flex justify-center items-center text-sm text-gray-400 border mt-3 rounded-sm">
              No Records Found
            </div>
          )}
        </div>

     

  
          

          <div
            style={{
              display: "none",
            }}
          >
            <div ref={printRef}>
              <PrintWithFooter
                  customeHeader={
                    <div className="items-center flex justify-center w-full" >
                   <div className='text-center '>
           
                     </div>
                       <div className="flex items-center">
                       <div className="mr-1 text-sm font-semibold flex justify-center ">
                       </div>
                       <div className="text-sm font-semibold" >
                         From :
                         </div>
                         <div className="ml-1 text-sm">
                            {moment(fromDate).format('DD-MM-YYYY')} </div>
                            <div className="text-sm font-semibold ml-2" >
                         To :
                         </div>
                         <div className="ml-1 text-sm">
                            {moment(ToDate).format('DD-MM-YYYY')} </div>
                          
                            </div>
                          
                      </div>}
            title={'Item Wise Purchase'}
            marginTop={"120px"}
            headerWidth={true}

                data={purchaseList}
                columns={columns}
                footer={<Footer columns={columns} rows={purchaseList} />}
              />
            </div>
          </div>
      </div>
   
  

      <ItemMasterHelp onRowClick={p =>{
        setItemData(p.row)
        setItemMasterHelpStatus(false)


      }} status={itemMasterHelpStatus} onClose={()=>{
        setItemMasterHelpStatus(false)
      }}/>
    </div>
  );
};

export default ItemWisePurchase;
