import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrefix,  selectWidth,  } from '../../utility/Redux/profile';
import { FromToDate } from '../../utility/component/fromToDate';
import { GetItemWiseSaleReport } from './func';
import { selectToken } from '../../utility/Redux/security';
import Footer from './footer';
import { useReactToPrint } from 'react-to-print';
import GeniusTableReports from '../../utility/component/geniusComponents/geniusTableData';
import PrintWithFooter from '../../utility/component/datagrid/printReportWithFooter';
import IsLocalData from '../../utility/component/isLocalData';
import FromToDateMobile from '../../utility/component/fromToDateMobile';
import { ItemWiseSaleReportColumns } from './columns';
import { SectionMasterHelp } from '../../help';
import { setAlertWithTitle } from '../../utility/Redux/modal';
const StockReport = () => {
  const prefix = useSelector(selectPrefix)
  const [summaryList,setSummaryList] = useState([])
 const width = useSelector(selectWidth)
const printRef= useRef(null)
const [sectionData,setSectionData] = useState('')
const [modalStatus,setModalStatus] = useState(false)

  const columns = ItemWiseSaleReportColumns(width)
  const dispatch =  useDispatch()
  const token = useSelector(selectToken)
 const okButton = ()=>{
  if(sectionData?.SECTION_NAME){
    GetItemWiseSaleReport({
      "table_prefix":prefix,
      SECTION_NAME:sectionData?sectionData.SECTION_NAME :""
    },token,dispatch,setSummaryList)
  }else{
    dispatch(setAlertWithTitle({
      title:'Alert',
      msg:'Please select section first.'
    }))
  }
  
 }
 const handlePrint = useReactToPrint({
  content: () =>printRef.current,
  pageStyle: `
  @page {
    size: landscape;
  }
`,

});




useEffect(()=>{
  const callback = (details, data) => {
    setSectionData(details[0])
    setSummaryList(data)

  };
  const elseFunc =()=>{console.log('nothing')}
  IsLocalData('stockReport', callback, elseFunc);
},[])
const windowSize = useSelector(selectWidth)






  return (
    <div className=" bg-white flex flex-col h-full overflow-y-auto   ">
      <div  className="  flex flex-col h-full p-3  ">
        <div>
          {window.innerWidth <= 671 ? (
               <>
                <FromToDateMobile       submit={okButton}
           onSectionClick={()=>{
            setModalStatus(true)
           }}
           onPrintClick={handlePrint}
            hideDate={true}
            sectionData ={sectionData}      />
          
       
             </>
          ) : (
            <FromToDate
            reset={()=>{
              setSummaryList([])
              setSectionData('')
            }}
            hideReset={true}
            onPrintClick={handlePrint}
            hideDate={true}
            clearSection={()=>{
              setSectionData('')
              setSummaryList([])

            }}
            submit={okButton}
           onSectionClick={()=>{
            setModalStatus(true)
           }}
           sectionData ={sectionData}
            />
          )}

        </div>


  {summaryList[0] ?    <div
            style={{ height:`calc(100% - ${windowSize<=671 ?220:80}px)`,
              marginTop: 10 }}
            className=" mb-2  "
          >


            <GeniusTableReports  data={summaryList} columns={columns} footer={<Footer columns={columns} rows={summaryList}/>}/>
 
<div className='w-full mt-3 '>
          
          <div  style={{
        display:'none'
       }}>
        <div ref={printRef}>
          <PrintWithFooter

       customeHeader={
         <div className="items-center  " >
        
            <div className="flex items-center">
            <div className="mr-1 text-sm font-semibold flex justify-center ">
          Stock Report
            </div>
      
               
                 </div>
               
           </div>}
         
            data={summaryList}
            columns={columns}
            marginTop={'120px'}
            headerWidth={true}
            footer={<Footer columns={columns} rows={summaryList}/>}
          />
        </div>
        </div>
          </div>
          </div> : <div className='h-full flex justify-center items-center text-sm text-gray-400 border mt-3 rounded-sm'>
            No Records Found
            </div>}
       
     
     
      </div>
   

        <SectionMasterHelp status={modalStatus}
        onRowClick={p =>{
          setSectionData(p.row)
          setModalStatus(false)
        }}
        onClose={()=> setModalStatus(false)}
        />
    </div>
  );
};

export default StockReport;
