import React, { useState } from 'react';
import './neumoriphism.css'; // Assuming CSS is in this file

const NeumorphicButton = ({ children, onClick }) => {
  return (
    <button className="neumorphism-button" onClick={onClick}>
      {children}
    </button>
  );
};

const NeumorphicSwitch = () => {
    const [isOn, setIsOn] = useState(false);
  
    return (
      <div className={`neumorphism-switch ${isOn ? 'on' : ''}`} onClick={() => setIsOn(!isOn)}>
        <div className="toggle-thumb" />
      </div>
    );
  };
  

  const NeumorphicInput = ({ type = "text", placeholder }) => {
    return <input className="neumorphism-input" type={type} placeholder={placeholder} />;
  };
  

  const NeumorphicIcon = ({ iconClass }) => {
    return <i className={`neumorphism-icon ${iconClass}`} />;
  };

export {NeumorphicButton,NeumorphicIcon,NeumorphicInput,NeumorphicSwitch} ;
