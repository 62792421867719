import moment from "moment";
import { FormatNumber2 } from "../../utility/functions/formatNumber";

export const SummaryColumns = (width) => {
    const columns = [
    
      {
        field: 'DATE',
        accessor: 'DATE',

        type: 'date',
        headerAlign: 'center',
        width:  120,
        headerClassName: 'super-app-theme--header',
  
        renderCell: (param) => {
          return (
            <div
              className={`h-full w-full  flex items-center   `}
            >
              {moment(param.value).format('YYYY-MM-DD') }
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Date'}{' '}
          </div>
        ),
      },
      {
        field: 'BTC_AMOUNT',
        accessor: 'BTC_AMOUNT',
        type: 'number',
        headerAlign: 'center',
        width:  140,
        align: 'right',
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => {
          return (
            <div
              className={`h-full w-full flex items-center justify-end `}
            >
          { FormatNumber2(params.value)}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
          { width<500?'BTC': 'BTC Amount'}
          </div>
        ),
      },
      {
        field: 'CASH_AMOUNT',
        accessor: 'CASH_AMOUNT',
        type: 'number',
        width:  160,
        align: 'right',

        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (params) => {
          return (
            <div
              className={`h-full w-full flex items-center justify-end `}
            >
          { FormatNumber2(params.value)}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            { width<500?'Cash': 'Cash Amount'}
          </div>
        ),
      },
      {
        field: 'CARD_AMOUNT',
        accessor: 'CARD_AMOUNT',
        type: 'number',
        align: 'right',

        width:  135,
        headerAlign: 'center',
        renderCell: (params) => {
          return (
            <div
              className={`h-full w-full flex items-center justify-end `}
            >
          { FormatNumber2(params.value)}
            </div>
          );
        },
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            { width<500?'Card': 'Card Amount'}
          </div>
        ),
      },
  
      {
        field: 'E_AMOUNT',
        accessor: 'E_AMOUNT',
        type: 'number',
        renderCell: (params) => {
          return (
            <div
              className={`h-full w-full flex items-center justify-end pr-4`}
            >
          { FormatNumber2(params.value)}
            </div>
          );
        },
        headerClassName: 'super-app-theme--header',
        width:  150,
        headerAlign: 'center',
        align: 'right',
  
        renderHeader: (params) => (
          <div
            className="text-white  whitespace-normal"
            style={{ fontSize: 13 }}
          >
            {' '}
 E Amount
          </div>
        ),
      },
    
    
    ];

    const mobileColumns = [
    
      {
        field: 'DATE',
        accessor: 'DATE',

        type: 'date',
        headerAlign: 'center',
        width:  80,
        headerClassName: 'super-app-theme--header',
  
        renderCell: (param) => {
          return (
            <div
              className={`h-full w-full  flex items-center   `}
            >
              {moment(param.value).format('YYYY-MM-DD, dddd') }
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Date'}{' '}
          </div>
        ),
      },
      {
        field: 'BTC_AMOUNT',
        accessor: 'BTC_AMOUNT',
        type: 'number',
        headerAlign: 'center',
        width:  70,
        align: 'right',
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => {
          return (
            <div
              className={`h-full w-full flex items-center justify-end `}
            >
          { FormatNumber2(params.value)}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
          { width<500?'BTC': 'BTC Amount'}
          </div>
        ),
      },
      {
        field: 'CASH_AMOUNT',
        accessor: 'CASH_AMOUNT',
        type: 'number',
        width:  70,
        align: 'right',

        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (params) => {
          return (
            <div
              className={`h-full w-full flex items-center justify-end `}
            >
          { FormatNumber2(params.value)}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            { width<500?'Cash': 'Cash Amount'}
          </div>
        ),
      },
      {
        field: 'CARD_AMOUNT',
        accessor: 'CARD_AMOUNT',
        type: 'number',
        align: 'right',

        width:  70,
        headerAlign: 'center',
        renderCell: (params) => {
          return (
            <div
              className={`h-full w-full flex items-center justify-end `}
            >
          { FormatNumber2(params.value)}
            </div>
          );
        },
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            { width<500?'Card': 'Card Amount'}
          </div>
        ),
      },
  
      {
        field: 'E_AMOUNT',
        accessor: 'E_AMOUNT',
        type: 'number',
        renderCell: (params) => {
          return (
            <div
              className={`h-full w-full flex items-center justify-end pr-4`}
            >
          { FormatNumber2(params.value)}
            </div>
          );
        },
        headerClassName: 'super-app-theme--header',
        width:  70,
        headerAlign: 'center',
        align: 'right',
  
        renderHeader: (params) => (
          <div
            className="text-white  whitespace-normal"
            style={{ fontSize: 13 }}
          >
            {' '}
 E Amount
          </div>
        ),
      },
    
    
    ];
    return width <500?mobileColumns : columns;
  };