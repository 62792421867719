import './print.css';
import moment from 'moment';
import React from 'react';
import { useTable } from 'react-table';

const GeniusTableForReports = ({
  date,
  date1,
  stationId,
  columns,
  data,
  title,
  Fotter,
  header,
  headerWidth,
  customeHeader,
}) => {
  // Create an instance of the table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  const companyName = localStorage.getItem('company');
  return (
    <div
      id="main"
      style={{ width: '100%', height: '100%', backgroundColor: 'white' }}
    >
      <div
        id="print-header"
        style={{
          width: '1200px',
          
height:'150px'
       
        }}
        className=" absolute top-0 left-0  px-4"
      >
        <div style={{
             width: '100%',
             height: '100%',
        }} className='pt-5'>
   <div style={{ width: '100%' }}>
          <p
            className="flex justify-center items-center font-semibold"
            style={{ fontSize: '20px' }}
          >
            {companyName}
          </p>
        </div>
        <div
          style={{ width: '100%',  boxSizing: 'border-box' }}
          className="  mt-1"
        >
        
       
          <div className='w-full flex flex-col'>
          <p className="flex justify-center">
            {title ? title + '' + ' ' : ''}{' '}
          </p>
          <div className='flex justify-center'>
          {customeHeader && customeHeader}

          </div>

          </div>
     
        </div>
        </div>
     
      </div>
      {header && header}

      <div
        id="print-component"
        className={`${headerWidth ? `pt-[70px]` : 'pt-10'} px-4`}
        style={
          window.innerWidth < 600
            ? {
                width: '100%',
                height: 'auto',
                overflow: 'hidden',
                marginTop: '10%',
              }
            : {
                width: '100%',
                height: 'auto',
                overflow: 'hidden',
                marginTop: '6.5%',
              }
        }
      >
        <table
          {...getTableProps()}
          style={{ border: '1px solid #888c89' }}
          className="w-full"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                style={{ fontSize: '12px' }}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, index) => (
                  <td
                    {...column.getHeaderProps()}
                    className="whitespace-nowrap text-center py-2 "
                    style={
                      columns[index].width
                        ? {
                            width: columns[index].width,
                            fontWeight: 500,
                            borderRight: '1px solid #888c89',
                            borderBottom: '1px solid #888c89',
                          }
                        : {
                            flex: columns[index].flex,
                            fontWeight: 500,
                            borderRight: '1px solid #888c89',
                            borderBottom: '1px solid #888c89',
                          }
                    }
                  >
                    {/* {columns[index].width} */}

                    {columns[index].renderHeader().props.children}
                  </td>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '8px',
                        borderRight: '1px dashed #888c89',
                        borderTop: '1px dashed black',
                        fontSize: '11px',
                      }}
                    >
                      <div
                        style={{
                          textAlign: cell.render('Cell').props.cell.column.align
                            ? cell.render('Cell').props.cell.column.align
                            : 'left',
                        }}
                        className="whitespace-nowrap"
                      >
                        {cell.render('Cell').props.cell.column.type === 'date'
                          ? moment(cell.render('Cell').props.cell.value).format(
                              'DD-MM-YYYY'
                            )
                          : cell.render('Cell').props.cell.value === '0.00'
                          ? null
                          : cell.render('Cell')}
                      </div>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="w-full flex justify-between">
          <div
            style={{
              fontSize: '12px',
            }}
            className="flex  pt-2"
          >
            <span style={{ fontSize: '10px', color: '#3b3a39' }}>
              {' '}
              Powered by{' '}
            </span>{' '}
            <span
              style={{ fontSize: '11px', color: '#171716' }}
              className="pl-1"
            >
              genius office{' '}
            </span>
          </div>
          <div className="flex pt-2 items-center ">
            <div
              style={{
                fontSize: '11px',
                color: '#3b3a39',
                fontWeight: '600px',
              }}
            >
              Date :{' '}
            </div>

            <div
              style={{
                fontSize: '10px',
                color: '#3b3a39',
                fontWeight: '500px',
              }}
              className="pl-1"
            >
              {moment().format('YYYY-MM-DD HH:mm ')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeniusTableForReports;
