import React from 'react'
import CallAPI from './getData'
import { setSpinnerLoading } from '../Redux/modal'

const IsEnterPressed = (e) => {
   return new Promise((resolve,reject) =>{
    if(e.code === 'Enter' || e.code ==='NumpadEnter'){
        return resolve(true)
    }else{
        reject(false)
    }
   }).catch(()=> false)
}
export const  SaveCustomerMasterAPI  =(details,prefix, token,dispatch)=>{
 

  dispatch(setSpinnerLoading('Saving'))

  const data = {
    table_prefix: prefix,
    CUSTOMER_CODE: details.CUSTOMER_CODE ? details.CUSTOMER_CODE : "",
    CUSTOMER_NAME: details.CUSTOMER_NAME,
    CUSTOMER_ADDRESS:details?.CUSTOMER_ADDRESS?details.CUSTOMER_ADDRESS:"",
    CUSTOMER_MOBILE: details.CUSTOMER_MOBILE,
    CUSTOMER_EMAIL:details?.CUSTOMER_EMAIL?details.CUSTOMER_EMAIL:"",
    CUSTOMER_TYPE:details?.CUSTOMER_TYPE?details.CUSTOMER_TYPE:"",
    CITY: details?.CITY?details.CITY:"",
    COUNTRY:details?.COUNTRY?details.COUNTRY:"",
    PIN_CODE: details?.PIN_CODE?details.PIN_CODE:"",
    DISQ_RATE: details?.DISQ_RATE?details.DISQ_RATE:0,
    LOYALTY_POINTS:details?.LOYALTY_POINTS?details.LOYALTY_POINTS:0,
    CUSTOMER_STATUS: details?.CUSTOMER_STATUS?details.CUSTOMER_STATUS:"",
    PRV_BAL:details?.PRV_BAL ?details.PRV_BAL:0,
    BAL_TYPE: details?.BAL_TYPE? details.BAL_TYPE : "Dr.",
    COMPANY_NAME:details?.COMPANY_NAME? details.COMPANY_NAME:"",
    COMPANY_ADDRESS: details?.COMPANY_ADDRESS? details.COMPANY_ADDRESS:"",
    COMPANY_MOBILE:  details?.COMPANY_MOBILE? details.COMPANY_MOBILE:"",
    TAX_NUMBER: details?.TAX_NUMBER? details.TAX_NUMBER:"",
    PAN_NO: details?.PAN_NO? details.PAN_NO:"",
    TAN: details?.TAN? details.TAN:"",
  };
  return new Promise ((resolve,reject) =>{
    if(details?.CUSTOMER_MOBILE && details?.CUSTOMER_NAME){
      CallAPI(
        "/api/master/saveCustomerMaster",
        data,
        token,
        (e) => {
          if (!e.data.error) {
             resolve()
        
          } else {
            reject('Something went wrong while saving customer')
          }
        },
        dispatch,
        () => {
        
        },()=>{
          reject('Something went wrong while saving customer')
        }
      );
    }else{
      if(details?.CUSTOMER_MOBILE ){
   if(!details?.CUSTOMER_NAME){
reject('Please provide customer name also')
   }
      }else{
        if(details?.CUSTOMER_NAME){
          if(!details?.CUSTOMER_MOBILE){
reject('Please provide customer mobile also')

           }
        }else{
          resolve()
        }
      }
    }
   
  })
 
}


export const  getCustomerByMobile  =(mobile,prefix, token,dispatch)=>{
  dispatch(setSpinnerLoading('Load Customer'))

  const data = {
    table_prefix: prefix,
    CUSTOMER_MOBILE: mobile,
    CUSTOMER_NAME: "",
  };
  return new Promise ((resolve,reject) =>{
    CallAPI(
      "/api/master/listCustomerMaster",
      data,
      token,
      (e) => {
        if (!e.data.error) {
           resolve(e.data.response)
      
        } else {
          reject()
        }
      },
      dispatch,
      () => {
      dispatch(setSpinnerLoading(false))
      },()=>{
        reject()
      }
    );
  })
 
}

 function getLongitudeLattitude() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const coords = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            resolve(coords);
          },
          (error) => {
            reject(error.message);
          }
        );
      } else {
        reject("Geolocation is not supported by this browser.");
      }
    });
  }


   const reverseLocation = (latitude, longitude) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_MAP_KEY}`
        );
        const data = await response.json();
  
        if (data.results.length > 0) {
          const state = data.results[0].address_components.find(component =>
            component.types.includes('administrative_area_level_1')
          );
          resolve(state ? state.long_name : "State not found");
        } else {
          reject("State not found");
        }
      } catch (error) {
        reject("Unable to fetch address.");
      }
    });
  };


export {IsEnterPressed,getLongitudeLattitude,reverseLocation} 
