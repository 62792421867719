import React, {  useState } from 'react';
import Box from '@mui/material/Box';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDefultProfileSetting,
  selectFirebaseStatusField,
  selectPathHistory,
  selectPrefix,
  selectUserCode,
  selectUserType,
  selectWidth,
  setlatitudeRedux,
  setlongitudeRedux,
  setPathHistory,
} from '../utility/Redux/profile';
import 'react-datepicker/dist/react-datepicker.css';
import CustomeTwoButtonAlert from '../utility/component/modals/twoButtonAlert';
import AlertModal from '../utility/component/modals/alert';
import SessionOutModal from '../utility/component/modals/geniusModal';
import { Tooltip } from 'react-tooltip';

import { useEffect } from 'react';

import AppBarDesign from '../utility/component/styles/homeStyles';
import moment from 'moment';
import { toProperCase } from '../utility/component/format';
import { selectToken } from '../utility/Redux/security';
import SpinnerLoading from '../utility/component/modals/spinnerLoading';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import { detectIsAnyOtherUserTryToLogin, getDefaultProfileSetting } from './func';
import Billing from '../billing';
import Dashboard from '../dashboard';
import deleteWholeDatabase from '../utility/functions/deleteWholeDatabase';
import HandleIndexedDB from '../Test/handleIndexDb';
import { Collapse } from '@mui/material';
import { setAlertWithTitle } from '../utility/Redux/modal';
import { Tabs } from '../utility/component/tabs';
import { isFirebaseUserLoginCheckEnabeld } from '../utility/constant';
import BillDashboard from '../dashboard/billDashboard';

const Home = () => {
  const location = useLocation();
  const [isPathShow, setIsPathShown] = useState(window.innerWidth >500? true:false);
  const [expiredIn, setExpiredIn] = useState('');

  const navigate = useNavigate();
  const companyDetails = JSON.parse(localStorage.getItem('companyDetails'));
  const prefix = useSelector(selectPrefix);

  const dispatch = useDispatch();

  const userType = useSelector(selectUserType);

  const token = useSelector(selectToken);

  const [isOnline,setisOnline] = useState(navigator.onLine)
  
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  
const financialYear = JSON.parse(localStorage.getItem('financial')); 

  const windowWidth = useSelector(selectWidth);

  const defaultProfile = useSelector(selectDefultProfileSetting);
  const usercode = useSelector(selectUserCode)

  useEffect(() => {
    if(!defaultProfile.CODE){
      if(usercode){
        const p  =prefix.replace(/_.+_/, "_");
        getDefaultProfileSetting(p,usercode,token,dispatch,navigate,setAlertTwoButtonModal)
      }else{
        dispatch(setAlertWithTitle({
          title:'Alert',
          msg:'User Code Not Found'
        }))
      }
     
    }
  
  }, [usercode]);

  const pathHistory = useSelector(selectPathHistory);

  useEffect(()=>{
    const handleOffline = ()=>{
      setisOnline(navigator.onLine)
    }
    window.addEventListener('offline', handleOffline);
    
    return () => window.removeEventListener('offline', handleOffline);
  },[navigator.onLine])

  useEffect(()=>{
    const handleOffline = ()=>{
      setisOnline(navigator.onLine)
    }
    window.addEventListener('online', handleOffline);
    
    return () => window.removeEventListener('online', handleOffline);
  },[navigator.onLine])
  const removePath = (tab) => {
    const filterdPath = pathHistory.filter((data) => data.path !== tab.path);
    dispatch(setPathHistory(filterdPath));
    const databaseName = 'pathHistory';
    const storeName = 'data';
    const dataToSave = filterdPath;
    deleteWholeDatabase('pathHistory');
    if (!pathHistory[1]) {
      setIsPathShown(false);
    }
    HandleIndexedDB({ databaseName, storeName, dataToSave });
  };


const addpath = (history) => {
    if (
      location.pathname !== '/masters' &&
      location.pathname !== '/reports' &&
      location.pathname !== '/summary' &&
      location.pathname !== '/updation' &&
      location.pathname !== '/summary' &&
      location.pathname !== '/updation' &&
      location.pathname !== '/Mfg' &&
      location.pathname !== '/settings'
    ) {
      const isDup = history.filter((o) => o.path === location.pathname);
      if (!isDup[0]) {
        const list = [
          ...history,
          {
            ICODE: Math.random(),
            path: location.pathname,
          },
        ];
        const availvalSpace = Math.round(window.innerWidth / 220);
        const slice = availvalSpace - 1;
        const dataToSave = list.slice(-slice);
        const databaseName = 'pathHistory';
        const storeName = 'data';
        if (!pathHistory[0]) {
          setIsPathShown(true);
        }
        dispatch(setPathHistory(dataToSave));
        deleteWholeDatabase('pathHistory');
        HandleIndexedDB({ databaseName, storeName, dataToSave });
      }
    }
  };


  useEffect(() => {
    if (pathHistory[0]) {
      addpath(pathHistory);
    } else {
      const databaseName = 'pathHistory';
      const storeName = 'data';

      HandleIndexedDB({
        databaseName,
        storeName,
        callback: (data) => {
          if (data[0]) {
            dispatch(setPathHistory(data));
          }
          addpath(data);
        },
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!expiredIn) {
      const databaseName = 'userDetails';
      const storeName = 'data';

      HandleIndexedDB({
        databaseName,
        storeName,
        callback: (data) => {
          if (data[0]) {
            const endDate = moment(data[0].end_date).diff(
              moment(moment().format('YYYY-MM-DD')),
              'days'
            );
            if (endDate < 11) {
              setExpiredIn(endDate);
            } else {
              if (endDate < 4) {
                dispatch(
                  setAlertWithTitle({
                    title: 'Alert',
                    msg: `License will expire in ${endDate} days.`,
                  })
                );
              }
            }
          }
        },
      });
    }
  }, []);
const statusField = useSelector(selectFirebaseStatusField)

  useEffect(() => {
  

    if (
      prefix &&
      usercode &&
      localStorage.getItem('expiresAt') &&
      !alertTwoButtonModal.status &&
      isFirebaseUserLoginCheckEnabeld
    ) {
      detectIsAnyOtherUserTryToLogin(prefix, setAlertTwoButtonModal, alertTwoButtonModal, dispatch, navigate,usercode,statusField);
    }
  }, []);
  

  useEffect(() => {
    // Push an entry to history stack
    window.history.pushState(null, null, window.location.pathname);

    const handlePopState = (event) => {
      // Prevent back/forward navigation by pushing a new entry
      window.history.pushState(null, null, window.location.pathname);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);
  return (
    <Box className="flex  h-screen w-screen bg-red-500  ">
      <AppBarDesign />

      <div
        style={{
          paddingTop: 50,
        }}
        className="overflow-hidden p:pl-[49px] pb-10 bg-white  w-full "
      >
        <div className={`w-full ${!isOnline?'bg-[#e97a7a]' :'bg-[#e6e6e3]'}  p-2 flex items-center    justify-between z-100`}>
          <div className="flex items-center justify-between   ">
            <span
              style={{
                fontSize: 12,
              }}
              className="text-black font-semibold "
            >
              {location.pathname === '/'
                ? ' Dashboard'
                : toProperCase(
                    location.pathname
                      .substring(1)
                      .replace('_', ' ')
                      .replace('_', ' ')
                      .replace('_', ' ')
                  )} |
            </span>
            <div className="flex items-center">
              <div style={{ fontSize: 12 }} className={'text-[#3b3939] ml-1 '}>
                {moment().format('dddd DD-MMM-YYYY')}
              </div>
              {expiredIn && (
              <div
                style={{
                  letterSpacing: '2px',
                }}
                className="ml-4 text-[13px] font-semibold text-[#cf4848] "
              >
                - License will expired in {expiredIn} days
              </div>
            )}
              {!isOnline && <div className='ml-3 text-[#000000]'>
                Internet not available
                </div>}
            </div>
          </div>

          <div
            style={{
              fontSize: 13,
              paddingRight: '2px',
            }}
            className="text-[#4B4545] hidden md:flex items-center justify-between "
          >
            <label
              style={{
                fontWeight: 600,
              }}
            >
              {companyDetails?.companyName}
            </label>
            <div
              style={{
                borderLeft: '1px solid black',
              }}
              className="h-4  mx-2  whitespace-nowrap"
            />

            <label className="flex pa">
              <label className='mr-1'>Financial Year : </label>
              { financialYear?.year}
            </label>
            {!isPathShow && (
            <button
              onClick={() => {
                setIsPathShown((e) => !e);
              }}
              className=" bg-[#e6e6e3]"
            >
              <i
                className={`bi px-2  flex items-center ${
                  isPathShow ? 'bi-chevron-up' : 'bi-chevron-down'
                }`}
              ></i>
            </button>
          )}
          </div>
        </div>
        <Collapse in={isPathShow} timeout="auto" unmountOnExit>
          <div className="w-full flex bg-[#e6e6e3] justify-between">
            <div className="flex">
              {pathHistory.map((o,k) => {
                return (
                  <div
                  key={k}
                    style={{
                      backgroundColor:
                        location.pathname === o.path ? 'white' : '#e6e6e3',
                      borderRight:
                        location.pathname === o.path
                          ? '1px solid #bfbfbd'
                          : '1px solid #bfbfbd',
                      borderTop:
                        location.pathname === o.path
                          ? '1px solid #bfbfbd'
                          : '1px solid #bfbfbd',
                    }}
                    className={`min-w-[220px] ${
                      location.pathname === o.path && 'font-semibold'
                    } flex rounded-t-sm   px-2 py-1 hover:cursor-pointer text-sm ${
                      o.path === location.pathname && 'rounded-t-sm'
                    }`}
                  >
                    <div
                      onClick={() => {
                        navigate(o.path);
                      }}
                      className="flex-1 text-[#5f5f5e] "
                    >
                      {o.path === '/'
                        ? 'Home'
                        : toProperCase(
                            o.path.replace('/', '').replace(/_/g, ' ')
                          )}
                    </div>
                    <div
                      onClick={() => {
                        removePath(o);
                      }}
                      style={{
                      }}
                      className="flex items-center hover:bg-gray-300 rounded-sm p-[3px]"
                    >
                      <i className="bi bi-x flex items-center "></i>
                    </div>
                  </div>
                );
              })}
            </div>

            <button
              onClick={() => {
                setIsPathShown((e) => !e);
              }}
              className=" bg-[#e6e6e3]"
            >
              <i
                className={`bi px-2  flex items-center ${
                  isPathShow ? 'bi-chevron-up' : 'bi-chevron-down'
                }`}
              ></i>
            </button>
          </div>
        </Collapse>
        <div style={{
             height: `calc(100% - ${isPathShow ? 28 : 0}px)`,
        }} className="flex flex-col   ">
          {location.pathname === '/' ?
            userType !== 'ADMIN'?<Billing />  : userType === 'ADMIN'?<Dashboard />  :""
         
        : (
            <Outlet />
          )}
        </div>
      </div>
      <CustomeTwoButtonAlert />
      <AlertModal />
      <SessionOutModal />
      <SpinnerLoading />
      {windowWidth > 1280 && (
        <Tooltip
          id="tip"
          style={{
            padding: 3,
            backgroundColor: '#f7dda4',
            color: 'black',
          }}
        />
      )}
      {windowWidth > 1280 && (
        <Tooltip
          id="tip2"
          opacity={100}
          effect="solid"
          style={{
            padding: 5,
            backgroundColor: 'black',
            color: 'white',
            paddingLeft: 7,
          }}
        />
      )}
      {windowWidth > 1280 && (
        <Tooltip
          id="tip3"
          opacity={100}
          effect="solid"
          style={{
            padding: 5,
            backgroundColor: 'red',
            color: 'white',
            paddingLeft: 7,
          }}
        />
      )}
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </Box>
  );
};

export default Home;


