import { Button, Grow } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';


const Reports = () => {
  const navigation = useNavigate()
  const list = [
    {
      name: 'Sale Summary',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/sale_summary')
      },
    },
    {
      name: 'Paymode Summary',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/paymode_summary')
      },
    },
    {
      name: 'Voucher Summary',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/voucher_summary')
      },
    },
    {
      name: 'Item Wise Sale Report',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/item_wise_sale_report')
      },
    },
    {
      name: 'Offer Report',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/offer_list')
      },
    },
   

   
   
    {
      name: 'Sale Tax Report',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/sale_tax_report')
      },
    },
    {
      name: 'Expiry Report',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/expiry_report')
      },}

  ];
  const list2 = [


   
   
 {
      name: 'Item Wise Purchase Report',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/item_wise_purchase_report')
      },
    }, {
      name: 'Purchase Summary',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/purchase_summary')
      },
    },
   
   

    {
      name: 'Item Purchase history',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/item_wise_purchase')
      },
    },
    {
      name: 'Item Status',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/item_status')
      },
    },
    {
      name: 'Stock Report',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigation('/stock_report')
      },
      
    },
  
  

   
  ];
  return (
    <div className="h-full p-4 overflow-y-auto">
      <div className='pb-2 font-semibold'>
  Sales Reports :
</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 t:grid-cols-3 d:grid-cols-5 ll:grid-cols-6 gap-3   ">
        {list[0] &&
          list.map((e, i) => {
            return (
              <Grow
              key={i}
              in={true}
              style={{ transformOrigin: '0 0 0' }}
              {...(true ? { timeout: i === 0 ? 0 : i* 200 } : {})}
            >
              <button
                key={i}
                onClick={() => {
                    e.onclick();
                }}
                variant={'contained'}
                style={{
                  textTransform: 'none',
                  backgroundColor: 'transparent',
                  padding: '0px',
                  borderRadius: '7px',
                  overflow: 'hidden',
boxShadow:'3px 3px 8px #BDC2C6, -3px -3px 3px #ffffff'
                }}

              >
                <div className="bg-[#e9e9e5] py-4 w-full text-brown">
                  {e.name}
                </div>
              </button>
              </Grow>
            );
          })}
      </div>

<div className='mt-6'>
<div className='py-2 font-semibold'>
  Purchase Reports :
</div>
<div className="grid grid-cols-1 sm:grid-cols-2 t:grid-cols-3 d:grid-cols-5 ll:grid-cols-6 gap-3   ">
        {list2[0] &&
          list2.map((e, i) => {
            return (
              <Grow
              key={i}
              in={true}
              style={{ transformOrigin: '0 0 0' }}
              {...(true ? { timeout: i === 0 ? 0 : i* 200 } : {})}
            >
              <button
                key={i}
                onClick={() => {
                    e.onclick();
                }}
                variant={'contained'}
                style={{
                  textTransform: 'none',
                  backgroundColor: 'transparent',
                  padding: '0px',
                  borderRadius: '7px',
                  overflow: 'hidden',
boxShadow:'3px 3px 8px #BDC2C6, -3px -3px 3px #ffffff'
                }}

              >
                <div className="bg-[#e9e9e5] py-4 w-full text-brown">
                  {e.name}
                </div>
              </button>
              </Grow>
            );
          })}
      </div>
</div>
    
    </div>
  );
};

export default Reports;
