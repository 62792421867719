import { useDispatch, useSelector } from 'react-redux';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { useState } from 'react';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import { useEffect } from 'react';
import { selectCompanyAddress, selectPrefix, selectWidth } from '../utility/Redux/profile';
import MasterHelp from '../utility/component/modals/masterHelp';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

const CompanyHelp = ({ onRowClick ,status,onClose}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const [rows, setRows] = useState([]);
  const width = useSelector(selectWidth);
  const columns = [

    {
      field: 'code',
      renderHeader: (params) => <div>Code</div>,
      width:
      width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 100 : 100,
      headerAlign: 'center',
      align: 'left',
    },
    {
        field: 'company_email',
        renderHeader: (params) => <div>Company Email</div>,
        width:
        width <= 768 ? 250 : width <= 1024 ? 250 : width <= 1440 ? 250 : 300,
        headerAlign: 'center',
        align: 'left',
      },
      {
        field: 'company_mobile',
        renderHeader: (params) => <div>Company Mobile</div>,
        width:
        width <= 768 ? 200 : width <= 1024 ? 200 : width <= 1440 ? 200 : 200,
        headerAlign: 'center',
        align: 'left',
      },
      {
        field: 'user_email',
        renderHeader: (params) => <div>User Email</div>,
        width:
        width <= 768 ? 200 : width <= 1024 ? 200 : width <= 1440 ? 200 : 200,
        headerAlign: 'center',
        align: 'left',
      },
      {
        field: 'user_name',
        renderHeader: (params) => <div>User Name</div>,
        width:
        width <= 768 ? 150 : width <= 1024 ? 150 : width <= 1440 ? 150 : 150,
        headerAlign: 'center',
        align: 'left',
      },
      {
        field: 'user_mobile',
        renderHeader: (params) => <div>User Mobile</div>,
        width:
        width <= 768 ? 200 : width <= 1024 ? 200 : width <= 1440 ? 200 : 200,
        headerAlign: 'center',
        align: 'left',
      },
  ];

  const tabColumns = [

    {
      field: 'code',
      renderHeader: (params) => <div>Code</div>,
      width:80,

      headerAlign: 'center',
      align: 'left',
    },
    {
        field: 'company_email',
        renderHeader: (params) => <div>Company Email</div>,
        width:120,
        headerAlign: 'center',
        align: 'left',
      },

      {
        field: 'user_email',
        renderHeader: (params) => <div>User Email</div>,
       width:120,

        headerAlign: 'center',
        align: 'left',
      },
      {
        field: 'user_name',
        renderHeader: (params) => <div>User Name</div>,
       width:120,

        headerAlign: 'center',
        align: 'left',
      },
      {
        field: 'user_mobile',
        renderHeader: (params) => <div>User Mobile</div>,
       width:100,

        headerAlign: 'center',
        align: 'left',
      },
  ];
  const mobileColumns = [

    {
      field: 'code',
      renderHeader: (params) => <div>Code</div>,
    width:70,
      headerAlign: 'center',
      align: 'left',
    },
    {
        field: 'company_email',
        renderHeader: (params) => <div>Company Email</div>,
        width:130,

        headerAlign: 'center',
        align: 'left',
      },
  
      {
        field: 'user_email',
        renderHeader: (params) => <div>User Email</div>,
        width:120,

        headerAlign: 'center',
        align: 'left',
      },
   
   
  ];

  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.id.toString().includes(searchQuery);
        } else {
          return item.SECTION_NAME.toLowerCase().includes(
            searchQuery.toLowerCase()
          );
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

const companyDetail = useSelector(selectCompanyAddress)

  const dispatch = useDispatch();
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
  const email = companyDetail.email;

  const hmac = CryptoJS.HmacSHA256(email, secretPass);

  const hmacString = hmac.toString(CryptoJS.enc.Base64);
  const getData = async () => {
    dispatch(setSpinnerLoading('Loading'));
    const data  = {
        company_email: companyDetail.email,
        company_mobile:""
    }
    await axios
      .post(
        '/api/users/companyHelp',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then((p) => {
        if(!p.data.error){
            let list = [];
            const data = p.data.response

            for (let i = 0; i < data.length; i++) {
              list.push({
                ...data[i],
                id:i+1
              });
            }
            setFullList(list);
            setRows(list);
        }
  
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }else{
          dispatch(
            setAlertWithTitle({
              title: 'ERROR',
              msg: 'Something went wrong.',
              lottie: 'reject',
            })
          );
         }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);



  return (
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            onClose();
            setRows([]);
            setSearchQuery('')
          }}
          title={'Company - Help'}
          columns={width <768 ?mobileColumns :width <1280?tabColumns : columns}
          list={rows}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
            setSearchQuery('')

          }}
          goToMaster={()=>{
            navigate('/hsn_master')
          }}
        />
      </div>
  );
};

export default CompanyHelp;
