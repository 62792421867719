import React from 'react'
import reatilIcon from '../images/retailIcon.png'
import retailText from '../images/reatiailText.png'
import tagLine from '../images/taglineRetailx.png'
const RetailxLogo = () => {
  return (
    <div>
   <div className="flex justify-center items-center">
    <img
      src={reatilIcon}
      alt=""
    
      style={{
        height:'30px'
      }}
    />


    <img
      src={retailText}
      alt=""
    
      style={{
        height:'70px'
      }}
    />
  
  </div>

  <div className=' flex justify-center -mt-5 ml-7'>
 <div className='text-[10px] font-semibold text-[#666363]'>
    Bill. Manage. Grow.
 </div>
  {/* <img
      src={tagLine}
      alt=""
    
      style={{
        height:'20px'
      }}
    /> */}
  </div>

  
    </div>
 
  )
}

export default RetailxLogo