import '../src/App.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-simple-keyboard/build/css/index.css';
import {
  selectUserType,
  setHeight,
  setlatitudeRedux,
  setlongitudeRedux,
  setWidth,
} from './utility/Redux/profile';

import AlertModalTwoButton from './utility/component/modals/alertWithTwoButton';
import AlertModal from './utility/component/modals/alert';

import AlertModal2 from './utility/component/modals/alertWithLottie';
import SpinnerLoading from './utility/component/modals/spinnerLoading';
import AppRoutes from './route';


function App() {
  const [screenMode, setScreenMode] = useState(window.screen.orientation.type);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });

  const dispatch = useDispatch();
  const handleResize = () => {
    dispatch(setWidth(window.innerWidth));
    dispatch(setHeight(window.innerHeight));
    setScreenMode(window.screen.orientation.type);
  };
  const userType = useSelector(selectUserType);


  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (window.innerWidth >= 784) {
      if (userAgent.match(/android|webos|ipad|tablet|kindle|playbook|silk/i)) {

        if (screenMode.includes('portrait')) {

          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: true,
            title: 'FullScreen',
            msg: 'To use this app with best experience allow to full screen',
            button1: 'ok',

            button1Click: () => {
              setAlertTwoButtonModal({
                ...alertTwoButtonModal,
                status: false,
              });
              setScreenMode('landscape-primary');

              const element = document.documentElement;
              if (element.requestFullscreen) {
                element.requestFullscreen();

           
                if (window.screen.orientation?.lock) {
                  try {
                    // Lock the screen orientation to portrait mode

                    window.screen.orientation
                      .lock('landscape')

                      .catch((err) => {
                        console.error('Error locking screen orientation:', err);
                      });

                    setScreenMode('landscape-primary');
                  } catch (error) {
                    alert(error);
                  }
                }
              } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();

                setAlertTwoButtonModal({
                  ...alertTwoButtonModal,
                  status: false,
                });
                if (window.screen.orientation?.lock) {
                  try {
                    // Lock the screen orientation to portrait mode
                    window.screen.orientation
                      .lock('landscape')

                      .catch((err) => {
                        console.error('Error locking screen orientation:', err);
                      });
                    setScreenMode('landscape-primary');
                  } catch (error) {
                    alert(error);
                  }
                }
              } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();

                setAlertTwoButtonModal({
                  ...alertTwoButtonModal,
                  status: false,
                });
                if (window.screen.orientation?.lock) {
                  try {
                    // Lock the screen orientation to portrait mode
                    window.screen.orientation
                      .lock('landscape')

                      .catch((err) => {
                        console.error('Error locking screen orientation:', err);
                      });
                    setScreenMode('landscape-primary');
                  } catch (error) {
                    alert(error);
                  }
                }
              }
            },
          });
        }else{
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: false,
          });
        }
      }
    }
  }, [screenMode]);


  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    // Check if Geolocation is available
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          // Send data to Google Analytics
          dispatch(setlongitudeRedux(longitude))
          dispatch(setlatitudeRedux(latitude))
        },
        function (error) {
          console.error("Error Code = " + error.code + " - " + error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []); 



  useEffect(() => {
    // Inject Google Analytics script into the document head
    const script = document.createElement('script');
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-SRDEENSKK3";
    document.head.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){ window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-SRDEENSKK3');

  }, []); // Empty dependency array ensures this runs once when the app loads


  return (
    <>
        <AppRoutes userType={userType} />
      <AlertModalTwoButton details={alertTwoButtonModal} />
      <SpinnerLoading />
      <AlertModal2 />
      <AlertModal />
    </>
  );
}

export default App;
