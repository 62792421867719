import CallAPI from "../utility/functions/getData";
import {
  setAlertWithTitle,
  setItemVarientsList,
  setItemVarientsStatus,
  setSessionExpired,
  setSpinnerLoading,
} from "../utility/Redux/modal";
import axios from "axios";
import moment from "moment";
import { setItemList, setSectionList } from "../utility/Redux/list";

import {
  FontSizeWidth,
  getItemByCode,
  getItemByCodeFromPurchase,
  isPosMod,
} from "../utility/constant";
import HandleIndexedDB from "../Test/handleIndexDb";
import uniqueId from "uniqid";
import { Fade } from "@mui/material";
import { Close } from "@mui/icons-material";
import { toProperCase } from "../utility/component/format";
import { SaveCustomerMasterAPI } from "../utility/functions/common";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import dataToLocal from "../utility/component/dataToLocal";
import deleteWholeDatabase from "../utility/functions/deleteWholeDatabase";

export const GetListItemMaster = (
  prefix,
  sectionName,
  token,
  dispatch,
  dd,
  setFullItemList,
  setList
) => {
  const data = {
    table_prefix: prefix,
    SECTION_NAME: sectionName,
    ICODE: "",
    ITEMNAME: "",
    ALIAS_CODE: "",
  };
  dispatch(setSpinnerLoading("Loading Items"));
  CallAPI(
    "/api/master/listItemMaster",
    data,
    token,
    (e) => {
      if (!e.data.error) {
        setList(e.data.response);
        if (!sectionName) {
          dispatch(setItemList(e.data.response));
          if (isPosMod) {
            const databaseName = "ItemMaster";
            const storeName = "ItemList";
            const dataToSave = e.data.response;
            HandleIndexedDB({ databaseName, storeName, dataToSave });
          }
        }
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
};
export function sectionlistUI(
  sectionList,
  sectionName,
  setSectionName,
  prefix,
  token,
  dispatch,
  setItemList
) {
  return (
    <div className="h-full   flex flex-col grow ml-2">
      <div className="border-[#A09E9E] bg-[#f8f8fd] shadow-sm p-2 border rounded-sm flex flex-col h-full overflow-y-auto">
        <div className="grid grid-cols-1  ll:grid-cols-2 w-full bg-white rounded-b-sm gap-2  ">
          {sectionList.map((item, key) => (
            <div
              key={key}
              className="flex overflow-hidden"
              style={{
                border:
                  item.SECTION_NAME === sectionName
                    ? "2px solid #A8CF45"
                    : "0px solid #A8CF45",
                height: "45px",
                boxShadow: "1px 1px 3px 0px #9F9C9C",
                borderRadius: "7px",
                textTransform: "none",
                backgroundColor: "#f5eb82",
                fontSize: FontSizeWidth(window.innerWidth),
                color: "#4a4a4a",
              }}
            >
              <button
                onClick={(e) => {
                  // searchBoxInuptRef?.forEach((element) => {
                  //   if (element.id === "search" &&  width>1280) {
                  //     element.focus();
                  //   }
                  // })
                  setSectionName(item.SECTION_NAME);
                  GetListItemMaster(
                    prefix,
                    item.SECTION_NAME,
                    token,
                    dispatch,
                    "",
                    "",
                    setItemList
                  );
                }}
                style={{
                  width: `calc(100% - 20px)`,
                }}
                className="text-[#4a4a4a] px-2 text-start  font-semibold whitespace-nowrap "
              >
                {toProperCase(item.SECTION_NAME)}
              </button>
              <Fade
                orientation="horizontal"
                in={item.SECTION_NAME === sectionName}
              >
                <button
                  style={{
                    paddingRight: "5px",
                    paddingLeft: "5px",
                  }}
                  className="bg-green h-full"
                  onClick={() => {
                    setSectionName("");
                    setItemList([]);
                  }}
                >
                  <Close
                    color="white"
                    fontSize={"small"}
                    sx={{
                      fontSize: "15px",
                      color: "white",
                    }}
                  />
                </button>
              </Fade>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export const GetPurchaseBill = (prefix, token, dispatch, setBillCartList) => {
  const data = { table_prefix: prefix, CUSTOMER_NAME: "", CUSTOMER_MOBILE: "" };
  CallAPI(
    "/api/billing/listBills",
    data,
    token,
    (e) => {
      if (!e.data.error) {
        let list = [];
        const data = e.data.response;
        for (let i = 0; i < data.length; i++) {
          list.push({
            ...data[i],
            id: i,
          });
        }
        setBillCartList(list);
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Somthing went wrong",
          })
        );
      }
    },
    dispatch
  );
};

export const listSectionMasterApi = (prefix, token, setRows, dispatch) => {
  const data = {
    table_prefix: prefix,
    SECTION_NAME: "",
  };
  axios
    .post(`/api/master/listSectionMaster`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async function (response) {
      if (!response.data.error) {
        let list = [];
        const data = response.data.response;

        for (let i = 0; i < data.length; i++) {
          list.push({
            ...data[i],
            id: i,
          });
        }

        dispatch(setSectionList(list));
        if (isPosMod) {
          const databaseName = "SectionMaster";
          const storeName = "SectionList";
          const dataToSave = list;
          HandleIndexedDB({ databaseName, storeName, dataToSave });
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Error 112",
            msg: response.data.error,
          })
        );
        console.log(!response.data.error);
      }
    })
    .catch(function (error) {
      if (error.response.data.name === "TokenExpiredError") {
        dispatch(setSessionExpired(true));
        console.log(error.response);
      } else {
        dispatch(
          setAlertWithTitle({
            title: "ERROR",
            msg: "Something went wrong.",
            lottie: "reject",
          })
        );
      }
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};

export const GetlistCustomerList = (prefix, token, setRows, dispatch) => {
  const data = {
    table_prefix: prefix,
    CUSTOMER_MOBILE: "",
    CUSTOMER_NAME: "",
  };
  axios
    .post(`/api/master/listCustomerMaster`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async function (response) {
      if (!response.data.error) {
        const data = response.data.response;

        setRows(data);
        if (isPosMod) {
          const databaseName = "CustomerMaster";
          const storeName = "CustomerList";
          const dataToSave = data;
          HandleIndexedDB({ databaseName, storeName, dataToSave });
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Error 112",
            msg: response.data.error,
          })
        );
        console.log(!response.data.error);
      }
    })
    .catch(function (error) {
      if (error.response.data.name === "TokenExpiredError") {
        dispatch(setSessionExpired(true));
        console.log(error.response);
      } else {
        dispatch(
          setAlertWithTitle({
            title: "ERROR",
            msg: "Something went wrong.",
            lottie: "reject",
          })
        );
      }
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};

export const LoadBill = (
  prefix,
  token,
  dispatch,
  inv,
  setBillCartList,
  setCustomerDetails,
  setBillingDetails,
  setDate,
  bill_performa
) => {
  const data = {
    table_prefix: prefix,
    INVNO: inv,
    BILL_PERFORMA: bill_performa,
  };
  CallAPI(
    "/api/billing/loadBill",
    data,
    token,
    (e) => {
      if (!e.data.error) {
        const data = e.data.response;
        let list = [];
        setDate(moment(data[0].DATE).format("YYYY-MM-DD"));
        for (let i = 0; i < data.length; i++) {
          list.push({
            ...data[i],
            DATE: moment(data[i].DATE).format("YYYY-MM-DD"),
          });
        }
        setBillCartList(list);
        setCustomerDetails({
          CUSTOMER_NAME: data[0].CUSTOMER_NAME,
          CUSTOMER_ADDRESS: data[0].CUSTOMER_ADDRESS,
          CUSTOMER_MOBILE: data[0].CUSTOMER_MOBILE,
          CUSTOMER_EMAIL: "",
          CUSTOMER_TYPE: "",
          CITY: "",
          COUNTRY: "",
          PIN_CODE: "",
          DISQ_RATE: data[0].DISQ_RATE,
          LOYALTY_POINTS: "",
          CUSTOMER_STATUS: "",
          PRV_BAL: "",
          BAL_TYPE: "",
          CUSTOMER_CODE: data[0].CUSTOMER_CODE,
        });

        setBillingDetails({
          mobile: data[0].CUSTOMER_MOBILE,
          customerName: data[0].CUSTOMER_NAME,
          cashAmount: data[0].CASH_AMOUNT,
          cardAmount: data[0].CARD_AMOUNT,
          cardNo: data[0].CARD_NO,
          eAmount: 0,
          btcAmount: data[0].BTC_AMOUNT,
          btcName: data[0].NAME,
          couponAmount: data[0].COUPAN_AMOUNT,
          couponNo: data[0].COUPAN_NO,
        });
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something went wrong",
          })
        );
      }
    },
    dispatch
  );
};

export const EditCell = (
  e,
  setBillCartList,
  dispatch,
  billCartList,
  billingDetails,
  customerDetails,
  setAlertTwoButtonModal,
  setBillingDetails,
  profileSetting
) => {
  if (e.field === "QNTY") {
    if (Number(e.value) === 0) {
      const item = billCartList.filter((c) => c.ICODE === e.id);
      if (item[0].RQNTY < 1) {
        setBillCartList((b) => b.filter((c) => c.ICODE !== e.id));
      }
    } else {
      const box = billCartList.filter((c) => c.ICODE === e.id);
      if (box[0]) {
        itemClick(
          box[0],
          box[0],
          setBillCartList,
          billingDetails,
          customerDetails,
          Number(e.value),
          dispatch,
          setAlertTwoButtonModal,
          true,
          "",
          "",
          "",
          true,
          profileSetting,
          billCartList
        );
      }
    }
  } else {
    if (e.field === "SALE_PRICE") {
      if (e.value > 0) {
        setBillCartList((c) => {
          const data = c.map((b) => {
            if (b.ICODE === e.id) {
              return {
                ...b,
                SALE_PRICE: e.value,
                AMOUNT: e.value * b.QNTY,
                NET_AMOUNT: e.value * b.QNTY,
              };
            } else {
              return b;
            }
          });

          deleteWholeDatabase("billing").then(() => {
            dataToLocal("billing", data, [
              {
                ICODE: 1,
              },
            ]);
          });

          return data;
        });
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Sale Price not found",
          })
        );
      }
    } else {
      if (e.field === "RQNTY") {
        if (Number(e.value) === 0) {
          const item = billCartList.filter((c) => c.ICODE === e.id);
          if (item[0].RQNTY > 0) {
            setBillCartList((b) => {
              const data = b.filter((c) => c.ICODE !== e.id);
              deleteWholeDatabase("billing").then(() => {
                dataToLocal("billing", data, [
                  {
                    ICODE: 1,
                  },
                ]);
              });

              return data;
            });
          } else {
            setBillCartList((c) => {
              const data = c.map((b) => {
                if (b.ICODE === e.id) {
                  return {
                    ...b,
                    RQNTY: e.value,
                    AMOUNT: e.value * b.SALE_PRICE,
                    DISQ_AMT: "0",
                    NET_AMOUNT: e.value * b.SALE_PRICE,
                  };
                } else {
                  return b;
                }
              });
              deleteWholeDatabase("billing").then(() => {
                dataToLocal("billing", data, [
                  {
                    ICODE: 1,
                  },
                ]);
              });
              return data;
            });
          }
        } else {
          setBillCartList((c) => {
            const data = c.map((b) => {
              if (b.ICODE === e.id) {
                return {
                  ...b,
                  RQNTY: e.value,
                  QNTY: "0",
                  AMOUNT: e.value * b.SALE_PRICE,
                  DISQ_AMT: "0",
                  NET_AMOUNT: e.value * b.SALE_PRICE,
                };
              } else {
                return b;
              }
            });
            deleteWholeDatabase("billing").then(() => {
              dataToLocal("billing", data, [
                {
                  ICODE: 1,
                },
              ]);
            });
            return data;
          });
        }
      } else {
        if (e.field === "DISQ_AMT") {
          setBillCartList((c) => {
            const data = c.map((b) => {
              if (b.ICODE === e.id) {
                if (b.SALE_PRICE * b.QNTY > e.value) {
                  const basicRate = b.SALE_PRICE * b.QNTY;
                  const a = b.SALE_PRICE * b.QNTY - e.value;
                  return {
                    ...b,
                    DISQ_AMT: e.value,
                    NET_AMOUNT: a,
                    AMOUNT: b.SALE_PRICE * b.QNTY,

                    DISQ_RATE: (e.value / basicRate) * 100,
                  };
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "You Cannot give discount more than amount.",
                      horizontal: true,
                    })
                  );
                  return b;
                }
              } else {
                return b;
              }
            });

            deleteWholeDatabase("billing").then(() => {
              dataToLocal("billing", data, [
                {
                  ICODE: 1,
                },
              ]);
            });
            return data;
          });
        } else {
          if (e.field === "DISQ_RATE") {
            setBillCartList((c) => {
              const data = c.map((b) => {
                if (b.ICODE === e.id) {
                  const am = Number(b.QNTY) * Number(b.SALE_PRICE);
                  const disqAmount = FormatNumber2(
                    (am * Number(e.value)) / 100
                  );
                  if (am > disqAmount) {
                    const amt = am - disqAmount;
                    return {
                      ...b,
                      DISQ_AMT: disqAmount,
                      NET_AMOUNT: amt,
                      AMOUNT: am,
                      DISQ_RATE: Number(e.value),
                    };
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: "Alert",
                        msg: "You Cannot give discount more than amount.",
                        horizontal: true,
                      })
                    );
                    return b;
                  }
                } else {
                  return b;
                }
              });
              deleteWholeDatabase("billing").then(() => {
                dataToLocal("billing", data, [
                  {
                    ICODE: 1,
                  },
                ]);
              });

              return data;
            });
          } else {
            if (e.field === "CASH") {
              setBillingDetails((o) => {
                return {
                  ...o,
                  cashAmount: Number(e.value),
                };
              });
            } else {
              if (e.field === "CARD_AMT") {
                setBillingDetails((o) => {
                  return {
                    ...o,
                    cardAmount: Number(e.value),
                  };
                });
              } else {
                if (e.field === "CARD_NUMBER") {
                  setBillingDetails((o) => {
                    return {
                      ...o,
                      cardNo: Number(e.value),
                    };
                  });
                } else {
                  if (e.field === "EAMOUNT") {
                    setBillingDetails((o) => {
                      return {
                        ...o,
                        eAmount: Number(e.value),
                      };
                    });
                  } else {
                    if (e.field === "BTC_AMT") {
                      setBillingDetails((o) => {
                        return {
                          ...o,
                          btcAmount: Number(e.value),
                        };
                      });
                    } else {
                      if (e.field === "BTC_NAME") {
                        setBillingDetails((o) => {
                          return {
                            ...o,
                            btcName: Number(e.value),
                          };
                        });
                      } else {
                        if (e.field === "COUPAN_AMT") {
                          setBillingDetails((o) => {
                            return {
                              ...o,
                              couponAmount: Number(e.value),
                            };
                          });
                        } else {
                          if (e.field === "COUPAN_NO") {
                            setBillingDetails((o) => {
                              return {
                                ...o,
                                couponNo: Number(e.value),
                              };
                            });
                          } else {
                            if (e.field === "DISQ_RATE_ALL") {
                              const value = e.value ? e.value : 0;
                              setBillCartList((c) => {
                                const data = c.map((b) => {
                                  if (b.RQNTY === 0 || !b.RQNTY) {
                                    const am =
                                      Number(b.QNTY) * Number(b.SALE_PRICE);
                                    const disqAmount =
                                      (am * Number(value)) / 100;
                                    if (am > disqAmount) {
                                      const amt = am - disqAmount;
                                      const data = {
                                        ...b,
                                        DISQ_AMT: disqAmount,
                                        NET_AMOUNT: amt,
                                        AMOUNT: am,

                                        DISQ_RATE: Number(value),
                                      };
                                      return data;
                                    } else {
                                      dispatch(
                                        setAlertWithTitle({
                                          title: "Alert",
                                          msg: "You Cannot give discount more than amount.",
                                          horizontal: true,
                                        })
                                      );
                                      return b;
                                    }
                                  } else {
                                    return b;
                                  }
                                });
                                deleteWholeDatabase("billing").then(() => {
                                  dataToLocal("billing", data, [
                                    {
                                      ICODE: 1,
                                    },
                                  ]);
                                });

                                return data;
                              });
                            } else {
                              if (e.field === "DISQ_AMT_ALL") {
                                const value = e.value ? e.value : 0;

                                setBillCartList((c) => {
                                  const data = c.map((b) => {
                                    const totalAmt = billCartList.reduce(
                                      (acc, row) =>
                                        acc +
                                        Number(
                                          row.RQNTY > 0 || !row.RQNTY
                                            ? row.QNTY * row.SALE_PRICE
                                            : 0
                                        ),
                                      0
                                    );
                                    const disqAmt = value;
                                    const disqRate = (disqAmt / totalAmt) * 100;
                                    if (b.RQNTY === 0 || !b.RQNTY) {
                                      const am =
                                        Number(b.QNTY) * Number(b.SALE_PRICE);
                                      const disqAmount =
                                        (am * Number(disqRate)) / 100;
                                      if (am > disqAmount) {
                                        const amt = am - disqAmount;
                                        const data = {
                                          ...b,
                                          DISQ_AMT: disqAmount,
                                          NET_AMOUNT: amt,
                                          AMOUNT: am,

                                          DISQ_RATE: Number(disqRate),
                                        };
                                        return data;
                                      } else {
                                        dispatch(
                                          setAlertWithTitle({
                                            title: "Alert",
                                            msg: "You Cannot give discount more than amount.",
                                            horizontal: true,
                                          })
                                        );
                                        return b;
                                      }
                                    } else {
                                      return b;
                                    }
                                  });
                                  deleteWholeDatabase("billing").then(() => {
                                    dataToLocal("billing", data, [
                                      {
                                        ICODE: 1,
                                      },
                                    ]);
                                  });

                                  return data;
                                });
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

export const OnSubmit = (
  billCartList,
  dispatch,
  prefix,
  token,
  Undo,
  TotalAmount,
  setBillNo,
  setAlertTwoButtonModal,
  setViewBillStatus,
  inputFields,
  SaleType,
  setModalStatus,
  isPrintHide,
  longitude,
  latitude,
  isPending,
  customerDetails,
  setIsOriginalBill,
  roundOffAmt
) => {
  if (billCartList[0]) {
    const phoneRegex = /^[6-9]\d{9}$/;
    const isMobileValid =
      billCartList[0].CUSTOMER_MOBILE &&
      phoneRegex.test(billCartList[0].CUSTOMER_MOBILE);
    const totalAmoutWithoutTax = billCartList.reduce(
      (acc, row) => acc + Number(row.AMOUNT),
      0
    );
    if (isMobileValid || !billCartList[0].CUSTOMER_MOBILE) {
      if (
        (billCartList[0].BTC_AMOUNT > 0 && billCartList[0].CUSTOMER_MOBILE) ||
        billCartList[0].BTC_AMOUNT === 0
      ) {
        if (
          (billCartList[0].COUPAN_AMOUNT > 0 && billCartList[0].COUPAN_NO) ||
          (billCartList[0].COUPAN_AMOUNT === 0 && !billCartList[0].COUPAN_NO)
        ) {
          const list = billCartList.map((e) => {
            let saleAmount = parseFloat(e.AMOUNT);
            let disqAmount = parseFloat(e.DISQ_AMT);

            let tax1Rate = parseFloat(e.TAX1_RATE);
            let tax2Rate = parseFloat(e.TAX2_RATE);
            let tax3Rate = parseFloat(e.TAX3_RATE);

            let tax1_amt = 0;
            let tax2_amt = 0;
            let tax3_amt = 0;
            let saleAmount11 = saleAmount - disqAmount;
            if (SaleType?.toLowerCase() === "inclusive") {
              if (
                !isNaN(saleAmount) &&
                !isNaN(tax1Rate) &&
                !isNaN(tax2Rate) &&
                !isNaN(tax3Rate)
              ) {
                let tax1amt =
                  saleAmount11 - saleAmount11 / (1 + tax1Rate / 100);
                tax1_amt = tax1amt.toFixed(2);
                tax2Rate = saleAmount11 - saleAmount11 / (1 + tax2Rate / 100);
                tax2_amt = tax2Rate.toFixed(2);
                let tax3amt =
                  saleAmount11 - saleAmount11 / (1 + tax3Rate / 100);
                tax3_amt = tax3amt.toFixed(2);
              }
            } else {
              if (
                !isNaN(saleAmount) &&
                !isNaN(tax1Rate) &&
                !isNaN(tax2Rate) &&
                !isNaN(tax3Rate)
              ) {
                let tax1amt = (saleAmount11 * tax1Rate) / 100;
                tax1_amt = tax1amt.toFixed(2);
                tax2Rate = (saleAmount11 * tax2Rate) / 100;
                tax2_amt = tax2Rate.toFixed(2);
                let tax3amt = (saleAmount11 * tax3Rate) / 100;
                tax3_amt = tax3amt.toFixed(2);
              }
            }

            const data = {
              ...e,
              TAX1_AMT: tax1_amt,
              TAX2_AMT: tax2_amt,
              TAX3_AMT: tax3_amt,
              TOTAL: totalAmoutWithoutTax,
              GTOTAL: TotalAmount,
              SALE_TYPE: SaleType,
              LATITUDE: latitude ? latitude : "",
              LONGITUDE: longitude ? longitude : "",
              IP_ADDRESS: "",
              BILL_STATUS: isPending ? "Hold" : "Settled",
              ROUND_OFF: roundOffAmt,
              BATCH: "",
              DISQ_AMT: FormatNumber2(e.DISQ_AMT),
              DISQ_RATE: e.DISQ_RATE.toFixed(2),
              NET_AMOUNT: e.NET_AMOUNT.toFixed(2),
              NET_AMOUNT: e.NET_AMOUNT.toFixed(2),
              DISQ_AMT: parseInt(e.DISQ_AMT).toFixed(2),
            };
            return data;
          });

          SaveCustomerMasterAPI(customerDetails, prefix, token, dispatch)
            .then(() => {
              const data = {
                table_prefix: prefix,
                bill_cart: list,
                NEW_MODIFY: list[0].INVNO ? "Modify" : "New",
                BILL_PERFORMA: "bills",
              };
              if (list[0].INVNO) {
                setIsOriginalBill(false);
              } else {
                setIsOriginalBill(true);
              }
              dispatch(setSpinnerLoading("Saving"));
              if (navigator.onLine) {
                CallAPI(
                  "/api/billing/saveBill",
                  data,
                  token,
                  (e) => {
                    if (!e.data.error) {
                      deleteWholeDatabase("billing");
                      if (setModalStatus) {
                        setModalStatus();
                      }
                      if (!isPending) {
                        setBillNo(e.data?.INVNO);
                      }
                      if (!isPrintHide && !isPending) {
                        setAlertTwoButtonModal((e) => {
                          return {
                            ...e,
                            title: `Bill Saved`,
                            msg: `Do You want to print this bill ?`,
                            status: true,
                            button1: "Yes",
                            button1Click: () => {
                              setAlertTwoButtonModal((e) => {
                                return {
                                  ...e,
                                  status: false,
                                };
                              });
                              setViewBillStatus(true);
                            },
                            button2: "Cancel",
                            button1Bg: "#34b4eb",
                            button2Bg: "#e1e2e3",
                            button2Color: "black",

                            button2Click: () => {
                              setAlertTwoButtonModal((e) => {
                                return {
                                  ...e,
                                  status: false,
                                };
                              });
                            },
                          };
                        });
                        Undo();
                      } else {
                        dispatch(
                          setAlertWithTitle({
                            title: "Alert",
                            msg: `Bill Saved for Hold`,
                          })
                        );
                        Undo();
                      }
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: "Error",
                          msg: e.data.error,
                        })
                      );
                    }
                  },
                  dispatch,
                  () => {
                    dispatch(setSpinnerLoading(false));
                  }
                );
              } else {
                if (isPosMod) {
                  const databaseName = "BillingList";
                  const storeName = uniqueId();
                  const dataToSave = list;
                  const afterUpdate = () => {
                    setAlertTwoButtonModal((e) => {
                      return {
                        ...e,
                        title: "Success",
                        msg: "Bill saved successfully locally",
                        status: true,
                        button1: "Ok",
                        button2: null,
                        button1Click: () => {
                          setAlertTwoButtonModal((e) => {
                            return {
                              ...e,
                              status: false,
                            };
                          });
                        },
                      };
                    });
                    dispatch(setSpinnerLoading(false));

                    Undo();
                  };
                  HandleIndexedDB({
                    databaseName,
                    storeName,
                    dataToSave,
                    afterUpdate,
                  });
                }
              }
            })
            .catch((msg) => {
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: msg,
                })
              );
            });
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Fill both field Coupan Amount and Coupan Number.",
            })
          );
          inputFields.forEach((element) => {
            if (element.id === "Coupan_no") {
              element.focus();
            }
          });
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Please select any account to save BTC amount.",
          })
        );
        inputFields.forEach((element) => {
          if (element.id === "Btc_name") {
            element.focus();
          }
        });
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "Customer Mobile Invalid.",
        })
      );
      // inputFields.forEach((element) => {
      //   if (element.id === "Customer_mobile") {
      //     element.focus();
      //   }
      // });
    }
  } else {
    dispatch(
      setAlertWithTitle({
        title: "Alert",
        msg: "No data found to save",
      })
    );
  }
};

export const isItemHaveOffer = (prefix, icode, token, dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setSpinnerLoading("Loading"));
    axios
      .post(
        `/api/offerMaster/findOffer`,
        {
          table_prefix: prefix,
          ICODE: icode,
          CDATE: moment().format("YYYY-MM-DD"),
          CTIME: moment().format("HH:mm"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          resolve(res.data.response);
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        console.log(err);
        reject([]);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  });
};

export const itemClick = (
  item,
  box,
  setBillCartList,
  billingDetails,
  customerDetails,
  q,
  dispatch,
  setAlertTwoButtonModal,
  edit,
  isStockCheck,
  prefix,
  token,
  isAllreadyInBillCart,
  profileSetting,
  billCartList
) => {
  const qty = q ? Number(q) : 1;
  const isInBill = billCartList.filter((o) => o.ICODE === box.ICODE);
  if (
    isAllreadyInBillCart ||
    profileSetting?.OFFER_SALE_PRICE === "No" ||
    isInBill[0]
  ) {
    if (isAllreadyInBillCart || isInBill[0]) {
      dispatch(setSpinnerLoading(false));
      addItem(
        setBillCartList,
        box,
        edit,
        qty,
        isStockCheck,
        setAlertTwoButtonModal,
        customerDetails,
        item,
        billingDetails
      );
    } else {
      getItemByCodeFromPurchase(prefix, item.ICODE, token, dispatch).then(
        (variants) => {
          if (variants[1]) {
            dispatch(
              setItemVarientsList(
                variants.map((v) => {
                  return {
                    ...item,
                    PUR_BILL_NO: v.INVNO,
                    BATCH: v.BATCH,
                    SALE_PRICE: v.SALE_PRICE,
                    BAL_QNTY: v.BAL_QNTY,
                  };
                })
              )
            );
            dispatch(setItemVarientsStatus(true));
          } else {
            // add variant details default
            console.log(variants);
            if (variants[0]) {
              const boxex = {
                ...box,
                PUR_BILL_NO: variants[0].INVNO,
                BATCH: variants[0].BATCH,
                SALE_PRICE: variants[0].SALE_PRICE,
              };
              const itemx = {
                ...item,
                PUR_BILL_NO: variants[0].INVNO,
                BATCH: variants[0].BATCH,
                SALE_PRICE: variants[0].SALE_PRICE,
              };
              addItem(
                setBillCartList,
                boxex,
                edit,
                qty,
                isStockCheck,
                setAlertTwoButtonModal,
                customerDetails,
                itemx,
                billingDetails
              );
            } else {
              // else details from item master
              addItem(
                setBillCartList,
                box,
                edit,
                qty,
                isStockCheck,
                setAlertTwoButtonModal,
                customerDetails,
                item,
                billingDetails
              );
            }
          }
        }
      );
    }
  } else {
    isItemHaveOffer(prefix, box.ICODE, token, dispatch).then((offerList) => {
      if (offerList[0]) {
        const itemDetail = {
          ...box,
          SALE_PRICE: offerList[0].OFFER_SALE_PRICE,
        };
        const itemsDetails = {
          ...item,
          SALE_PRICE: offerList[0].OFFER_SALE_PRICE,
        };
        addItem(
          setBillCartList,
          itemDetail,
          edit,
          qty,
          isStockCheck,
          setAlertTwoButtonModal,
          customerDetails,
          itemsDetails,
          billingDetails,
          true
        );
      } else {
        addItem(
          setBillCartList,
          box,
          edit,
          qty,
          isStockCheck,
          setAlertTwoButtonModal,
          customerDetails,
          item,
          billingDetails
        );
      }
    });
  }
};
export const addItem = (
  setBillCartList,
  box,
  edit,
  qty,
  isStockCheck,
  setAlertTwoButtonModal,
  customerDetails,
  item,
  billingDetails,
  isOffer
) => {
  setBillCartList((oldCart, i) => {
    const isItemExist = oldCart.filter((i) => i.ICODE === box.ICODE);
    if (isItemExist[0]) {
      const itemBal = edit
        ? box.BAL_STOCK - qty
        : box.BAL_STOCK - isItemExist[0].QNTY - qty;
      if (itemBal >= 0 || !isStockCheck) {
        const data = oldCart.map((o) => {
          if (o.ICODE === box.ICODE) {
            const q = edit ? qty : Number(o.QNTY) + qty;

            const basicRate = o.SALE_PRICE * q;
            return {
              ...o,
              QNTY: q,
              NET_AMOUNT: Number(o.SALE_PRICE * q) - o.DISQ_AMT,
              AMOUNT: Number(o.SALE_PRICE * q),
              RQNTY: 0,
              DISQ_RATE: (o.DISQ_AMT / basicRate) * 100,
              IS_OFFER: o?.IS_OFFER ? o?.IS_OFFER : false,
            };
          } else
            return {
              ...o,
            };
        });
        deleteWholeDatabase("billing").then(() => {
          dataToLocal("billing", data, [
            {
              ICODE: 1,
            },
          ]);
        });
        return data;
      } else {
        setAlertTwoButtonModal((old) => {
          return {
            title: "Alert",
            msg: ` Balance Stock ${box.BAL_STOCK} .`,
            status: true,
            button1Click: () => {
              setAlertTwoButtonModal((o) => {
                return {
                  ...o,
                  status: false,
                };
              });
            },
            button1: "Ok",
          };
        });

        return [...oldCart];
      }
    } else {
      const itemBal = box.BAL_STOCK - qty;
      if (itemBal >= 0 || !isStockCheck) {
        const data = [
          ...oldCart,
          {
            DATE: moment().format("YYYY-MM-DD HH:mm:ss"),
            USER: "",
            BILL_TYPE: "Retail",
            ACCOUNT_NO: "0",
            NAME: "",
            ADDRESS: "",
            MOBILE: "",
            TAX_NUMBER: "",
            CUSTOMER_CODE: customerDetails.CUSTOMER_CODE,
            CUSTOMER_NAME: customerDetails.CUSTOMER_NAME,
            CUSTOMER_ADDRESS: customerDetails.CUSTOMER_ADDRESS,
            CUSTOMER_MOBILE: customerDetails.CUSTOMER_MOBILE,
            ICODE: item.ICODE,
            ITEMNAME: item.ITEMNAME,
            ITEM_DESC: item.ITEM_DESC,
            PACKING: item.PACKING,
            UNIT: item.UNIT,
            SECTION_NAME: item.SECTION_NAME,
            SUB_SEC_NAME: item.SUB_SEC_NAME,
            COLOR: "",
            SIZE: "",
            CATEGORY: "",
            BRAND_NAME: item.BRAND_NAME,
            PUR_PRICE: item.PUR_PRICE,
            MRP_RATE: item.MRP_RATE,
            SALE_PRICE: item.SALE_PRICE,
            QNTY: qty,
            NET_AMOUNT: item.SALE_PRICE * qty,
            AMOUNT: item.SALE_PRICE * qty,

            DISQ_RATE: 0,
            DISQ_AMT: 0,
            HSN_CODE: item.HSN_CODE,
            TAX1_RATE: item.TAX1_RATE ? item.TAX1_RATE : "0",
            TAX1_AMT: 0.0,
            TAX2_RATE: item.TAX2_RATE ? item.TAX2_RATE : "0",
            TAX2_AMT: 0.0,
            TAX3_RATE: item.TAX3_RATE ? item.TAX3_RATE : "0",
            TAX3_AMT: 0.0,
            CESS_RATE: "0",
            CESS_AMOUNT: item.CESS_RATE,
            ROUND_OFF: 0.0,
            TOTAL: 0,
            GTOTAL: 0,
            REMARKS: "",
            CARD_NO: billingDetails.cardNo,
            CARD_NAME: "",
            SALES_MAN: "",
            NO_POINTS: 0,
            LOYALTY_AMOUNT: 0,
            LESS_LOYALITY: 0.0,
            AMOUNT_RCV: 0,
            ORDER_NO: 0,
            PRINT_ORDER_SLIP: item.PRINT_ORDER_SLIP,
            CASH_AMOUNT: billingDetails.cashAmount,
            E_AMOUNT: billingDetails.eAmount,
            CARD_AMOUNT: billingDetails.cardAmount,
            BTC_AMOUNT: billingDetails.btcAmount,
            COUPAN_AMOUNT: billingDetails.couponAmount,
            COUPAN_NO: billingDetails.couponNo,
            INVNO: "",
            RQNTY: 0,
            IS_OFFER: isOffer ? true : false,
            PUR_BILL_NO: item?.PUR_BILL_NO ? item?.PUR_BILL_NO : 0,
          },
        ];
        deleteWholeDatabase("billing").then(() => {
          dataToLocal("billing", data, [
            {
              ICODE: 1,
            },
          ]);
        });

        return data;
      } else {
        setAlertTwoButtonModal((old) => {
          return {
            title: "Alert",

            msg: ` Balance Stock ${box.BAL_STOCK} .`,
            status: true,
            button1Click: () => {
              setAlertTwoButtonModal((o) => {
                return {
                  ...o,
                  status: false,
                };
              });
            },
            button1: "Ok",
          };
        });

        return [...oldCart];
      }
    }
  });

  setTimeout(() => {
    const nearestInput = document.getElementById(box.ICODE + "-" + "QNTY");
    if (nearestInput) {
      nearestInput.focus();
    }
  }, 200);
  setTimeout(() => {
    const nearestInput = document.getElementById("search");
    if (nearestInput) {
      nearestInput.focus();
    }
  }, 500);
};
