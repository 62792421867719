import React from 'react'
import SwipeableEdgeDrawer from './drawer';
import { Close } from '@mui/icons-material';
import { Fade, Grow } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import { toProperCase } from '../utility/component/format';
import { mobileViewWidth } from './common';
import { FontSizeWidth } from '../utility/constant';

export const SectionBillhelp = ({setIsSectionDraweropen,horizontal,isSectionDrawerOpen,sectionList,sectionName,setSectionName,GetListItemMaster,setItemList}) => {

  const prefix = useSelector(selectPrefix)
  const token = useSelector(selectToken)
  const dispatch = useDispatch() 
  const width = useSelector(selectWidth)
  return ( <div className='flex'>
  <button
  
           onClick={() => {
            setIsSectionDraweropen(true);
  
          }}
            style={{
              height: '100%',
             width:width>mobileViewWidth && !horizontal?'30px':'100%'
            }}
            className="rounded-sm   bg-gray-100 hover:cursor-pointer  overflow-hidden  "
          >
            <div className=' '>
        <div className='p-2 t:p-1' style={ width>mobileViewWidth && !horizontal?{
          writingMode:'vertical-rl',
          transform:'scale(-1)',
          textOrientation: 'mixed', 
  
        }:{backgroundColor:'#0d0d0d',color:'white',fontSize:'12px' ,padding:'8px'}}>
          {sectionName ?'Section : '+ sectionName:"Section - Help"}
  
        </div>
             </div>
          </button>
  
  <SwipeableEdgeDrawer setStatus={setIsSectionDraweropen}  status={isSectionDrawerOpen} data= {   <div className=' rounded-sm bg-white flex flex-col h-full '>
      
  
     <div className='border-[#A09E9E] bg-white  border rounded-sm flex flex-col h-full'>
     <div className="flex justify-between rounded-t-sm bg-slate-100">
                      <div className="flex items-center pl-3 text-sm font-semibold">
                        Section Master - Help
                      </div>
                      <button
                      onClick={() => {
                        setIsSectionDraweropen(false);
            
                      }}
                        className="w-fit p-1 "
                      >
                        <Close
                          sx={{
                            color: 'red',
                          }}
                        />
                      </button>
                    </div>
    
     <div
                     
                     className="grid grid-cols-1 w-full bg-white rounded-b-sm gap-2  p-2  overflow-y-scroll"
                   >
               
                    
                           { sectionList.map((item, key) => (
                             <Grow
                             in={true}
                             key={key}
                             style={{ transformOrigin: '0 0 0' }}
                             {...(true ? { timeout: key === 0 ? 0 : key*100 } : {})}
                           >
                                <div
                                   key={key}
                                   className="flex overflow-hidden"
                                   style={{
                                     border:
                                       item.SECTION_NAME === sectionName
                                         ? '2px solid #A8CF45'
                                         : '0px solid #A8CF45',
                                     height: '45px',
                                     boxShadow:"1px 1px 3px 0px #9F9C9C",
                                     borderRadius: '7px',
                                     textTransform: 'none',
                                     backgroundColor: '#f5eb82',
                                     fontSize: '15px',
                                     color: '#4a4a4a',
                                   }}
                                 >
                                   <button
                                  onClick={(e)=>{
                                   setSectionName(item.SECTION_NAME)
                                   setIsSectionDraweropen(false)
                                   GetListItemMaster(prefix,item.SECTION_NAME,token,dispatch,"","",setItemList)
                                  }}
                                  style={{
                                   width:`calc(100% - 20px)`
                                  }}
                                     className="text-[#4a4a4a] px-2 text-[15px] text-start  font-semibold whitespace-nowrap "
                                   >
                                     {toProperCase(item.SECTION_NAME)}
                                   </button>
                                       <Fade orientation="horizontal" in={item.SECTION_NAME === sectionName}>
                                
                                     <button
                                       style={{
                                         paddingRight: '5px',
                                         paddingLeft: '5px',
  
                                       }}
                                       className='bg-green h-full'
                                       onClick={() => {
                                         setSectionName('');
                                   setIsSectionDraweropen(false)
      setItemList([])
                                        }}
                                     >
                                       <Close
                                       color='white'
                                         fontSize={'small'}
                                         sx={{
                                           fontSize: '15px',
  color:'white'
                                         }}
                                     
                                       />
                                     </button>
                                     </Fade>
                                 </div>
                           </Grow>
                               
                               ))
                            }
         
                   </div>
     </div>
  
  
   </div>}
       />
       </div> )
}


export const SectionWiseSale = ({setIsSectionDraweropen,isSectionDrawerOpen,data,normaltext,section}) => {

const width = useSelector(selectWidth)
  return ( <div className='flex'>
  <button
  
           onClick={() => {
            setIsSectionDraweropen(true);
  
          }}
            style={{
      
              height: '100%',
             width:'100%'
            }}
            className="rounded-r-sm rounded-l-sm t:rounded-l-[0px] bg-gray-100 hover:cursor-pointer  overflow-hidden rounded-t-sm "
          >
            <div className=' '>
        <div className='p-2 t:p-1 font-bold' style={ normaltext?{fontSize:FontSizeWidth(width)}:{
          writingMode:'vertical-rl',
          transform:'scale(-1)',
          textOrientation: 'mixed', 
          fontSize:FontSizeWidth(width)
        }} >
          {section ?section :'Select Section'}

        </div>
             </div>
          </button>
  
  <SwipeableEdgeDrawer setStatus={setIsSectionDraweropen}  status={isSectionDrawerOpen} data= {   <div className=' rounded-sm bg-white flex flex-col h-full '>
      
  
     <div className='border-[#A09E9E] bg-white  border rounded-sm flex flex-col h-full'>
     <div className="flex justify-between rounded-t-sm bg-slate-100">
                      <div className="flex items-center pl-3 text-sm font-semibold">
            Section Wise Sale
                      </div>
                      <button
                      onClick={() => {
                        setIsSectionDraweropen(false);
            
                      }}
                        className="w-fit p-1 "
                      >
                        <Close
                          sx={{
                            color: 'red',
                          }}
                        />
                      </button>
                    </div>
    
     {data}
     </div>
  
  
   </div>}
       />
       </div> )
}
export default SectionBillhelp