import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { toProperCase } from "../utility/component/format";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import { Popconfirm } from "antd";
import { emphasize } from "@mui/material";
import { mobileViewWidth } from "./common";
import deleteWholeDatabase from "../utility/functions/deleteWholeDatabase";
import dataToLocal from "../utility/component/dataToLocal";
export const columns = (width, setBillCartList, setAlertTwoButtonModal) => {
  const c = [
    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      width: width < 800 ? 140 : width < 1350 ? 170 : 250,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      
      renderCell: (p) => {
   
        return (
          <div className="flex  ">
            <div>
            <Popconfirm
    title="Remove This Item"
    description="Are you sure to remove this item?"
    placement="bottomRight"
    okText="Yes"
    okButtonProps={{ style: { backgroundColor: 'red', color: '#ffffff' } }} 
    cancelText="Cancel"
    onConfirm={()=>{
      setBillCartList((e) => {
  
        const data =       e.filter((i) => i.ICODE !== p.row.original.ICODE)
        deleteWholeDatabase('billing').then(()=>{
          dataToLocal('billing', data, [
            {
             ICODE:1
            },
          ]);
        })
        return data
      }
                        );
                        setAlertTwoButtonModal((e) => {
                          return {
                            ...e,
                            status: false,
                          };
                        });
                     
    }}
  >
                <i className="bi text-red-500 bi-trash3 mr-2 "></i>
                </Popconfirm>
     
             
            </div>
            <div className="pl-2  border-l border-[#b5b3b3]">{toProperCase(p.value)}</div>
          </div>
        );
      },
      renderHeader: (params) => (
        <div style={{ fontSize: 13 }}>{"Item Name"}</div>
      ),
    },
    {
      field: "QNTY",
      accessor: "QNTY",
      width: width < 800 ? 50 :width < 1280 ? 50: width < 1350 ? 80 : 70,
      align: "right",
      headerAlign: "center",
editable:true,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.value !== "0") {
          return params.value
        } else {
          return "";
        }
      },
      renderHeader: (params) => <div style={{ fontSize: 13 }}>{"Qnty."}</div>,
    },
    {
      field: "RQNTY",
      accessor: "RQNTY",
      align: "right",
      width: width < 800 ? 60 :width < 1280 ? 50:  width < 1350 ? 60 : 60,
      renderCell: (params) => {
        if (params.value !== 0) {
          return params.value
        } else {
          return "";
        }
      },
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => <div style={{ fontSize: 13 }}>{"Return"}</div>,
    },
    {
      field: "SALE_PRICE",
      accessor: "SALE_PRICE",
      width: width < 800 ? 80 :width <1280 ? 60 : width < 1350 ? 100 : 80,
      align: "right",
      renderCell: (params) => {
        if (params.value !== 0) {
          return <div className="flex justify-end w-full">
{FormatNumber2(params.value)}
          </div> 
        } else {
          return "";
        }
      },
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => <div style={{ fontSize: 13 }}>{"Rate"}</div>,
    },
    {
      field: "DISQ_RATE",
      accessor: "DISQ_RATE",
      align: "right",
      width: width < 800 ? 60 : width < 1350 ? 60 : 60,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.value !== 0) {
          return <div className="flex justify-end w-full">
{(params.value.toFixed(0) +'%') }
          </div> 
        } else {
          return "";
        }
      },
      renderHeader: (params) => (
        <div style={{ fontSize: 13 }}>{"Disq %"}</div>
      ),
    },
    {
      field: "DISQ_AMT",
      accessor: "DISQ_AMT",
      align: "right",
      width: width < 800 ? 100 :width <= 1280 ? 80 : width < 1350 ? 100 : 80,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.value !== 0) {
          return <div className="flex justify-end w-full">
{FormatNumber2(params.value)}
          </div> 
        } else {
          return "";
        }
      },
      renderHeader: (params) => (
        <div style={{ fontSize: 13 }}>{"Disq. Amt."}</div>
      ),
    },
    
    {
      field: "AMOUNT",
      accessor: "AMOUNT",
      type:'number',
      align: "right",
      width: width < 800 ? 110 :width < 1280 ? 70 : width < 1350 ? 110 : 110,
       renderCell: (params) => {
        if (params.value !== 0) {
          return <div className="flex justify-end w-full">
{FormatNumber2(params.value)}
          </div> 
        } else {
          return "";
        }
      },
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => <div style={{ fontSize: 13 }}>{"Amount"}</div>,
    },

    {
      field: "NET_AMOUNT",
      accessor: "NET_AMOUNT",
      type:'number',
      align: "right",
      width: width < 800 ? 110 :width < 1280 ? 70 : width < 1350 ? 110 : 110,
       renderCell: (params) => {
        if (params.value !== 0 && params.value) {
          return <div className="flex justify-end w-full">
{FormatNumber2(params.value)}
          </div> 
        } else {
          return "";
        }
      },
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => <div style={{ fontSize: 13 }}>{"Net Amt."}</div>,
    },
  ];

 
  return c
};

export const MobileColumnDataGrid = (width, setBillCartList, setAlertTwoButtonModal)=>{
  const mobile = [
    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      width: width >1000?160: 120 ,
      headerAlign: "center",
    
      headerClassName: "super-app-theme--header",
      fontSize:'10px',
   
      renderHeader: (params) => (
        <div style={{ fontSize: 8 }}>{"Item Name"}</div>
      ),
    },
    {
      field: "QNTY",
      fontSize:'10px',
      editable:true,

      accessor: "QNTY",
      width: width >1000?60: 40 ,
      align: "right",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.value !== "0") {
          return params.value
        } else {
          return "";
        }
      },
      renderHeader: (params) => <div style={{ fontSize: 13 }}>{"Qnty."}</div>,
    },
   


    {
      field: "AMOUNT",
      accessor: "AMOUNT",
      align: "right",
      fontSize:'10px',

   width: width >1000?80: 60 ,
       renderCell: (params) => {
        if (params.value !== 0) {
          return FormatNumber2(params.value)
        } else {
          return "";
        }
      },
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => <div style={{ fontSize: 13 }}>{"Amount"}</div>,
    },
  ];

  return mobile
}


export const BillSettelmetColumnDataGrid = (width, setBillCartList, setAlertTwoButtonModal)=>{
 
 const firstWidth = width >768? 250: 120
 const secWidth =  width >768? 50:40
 const thirWidth = 60
 const fourthWidth = 80
 const extraWidth = width
 const sixtWidth =  extraWidth -  (firstWidth+secWidth+thirWidth+ fourthWidth+10)
  const mobile = [
    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      width: firstWidth ,
      headerAlign: "center",
    
      renderCell: (p) => {
   
        return (
          <div className="flex  ">
            <div>
            {width>mobileViewWidth?    <Popconfirm
    title="Remove This Item"
    description="Are you sure to remove this item?"
    placement="bottomRight"
    okText="Yes"
    okButtonProps={{ style: { backgroundColor: 'red', color: '#ffffff' } }} 
    cancelText="Cancel"
    onConfirm={()=>{
      setBillCartList((e) => {
  
        const data =       e.filter((i) => i.ICODE !== p.row.original.ICODE)
        deleteWholeDatabase('billing').then(()=>{
          dataToLocal('billing', data, [
            {
             ICODE:1
            },
          ]);
        })
        return data
      }
                        );
                        setAlertTwoButtonModal((e) => {
                          return {
                            ...e,
                            status: false,
                          };
                        });
                     
    }}
  >
                <i className="bi text-red-500 bi-trash3 mr-2 "></i>
                </Popconfirm> : <button onClick={()=>{
                  console.log('hi')
                  setAlertTwoButtonModal({
                    title:'Alert',
                    msg:'Do you want to remove this item ?',
                    button1:'Yes',
                    status:true,
                    button2:'Cancel',
                    button1Click:()=>{
                      setAlertTwoButtonModal( e =>{
                        return {
                          ...e,
                          status: false,
                        }
                      })
                      setBillCartList((e) =>
                        e.filter((i) => i.ICODE !== p.row.original.ICODE)
                      );
                      setAlertTwoButtonModal((e) => {
                        return {
                          ...e,
                          status: false,
                        };
                      });
                    },
                    button2Click:()=>{
                      setAlertTwoButtonModal( e =>{
                        return {
                          ...e,
                          status: false,
                        }
                      })
                  
                    }
                  })
                }}>

                <i className="bi text-red-500 bi-trash3 mr-2 "></i>
                  </button>}
       
            </div>
            <div className="pl-2  border-l border-[#b5b3b3]">{toProperCase(p.value)}</div>
          </div>
        );
      },
      headerClassName: "super-app-theme--header",
      fontSize:width >768? '12px':'10px',
      renderHeader: (params) => (
        <div style={{ fontSize: 8 }}>{"Item Name"}</div>
      ),
    },
    {
      field: "QNTY",
      fontSize:width >768? '12px':'10px',
      editable:true,

      accessor: "QNTY",
      width:  secWidth,
      align: "right",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.value !== "0") {
          return params.value
        } else {
          return "";
        }
      },
      renderHeader: (params) => <div style={{ fontSize: 13 }}>{"Qnty."}</div>,
    },
    {
      field: "RQNTY",
      accessor: "RQNTY",
      fontSize:width >768? '12px':'10px',
      align: "right",
      width: thirWidth,
      renderCell: (params) => {
        if (params.value !== 0) {
          return params.value
        } else {
          return "";
        }
      },
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => <div style={{ fontSize: 13 }}>{"Return"}</div>,
    },
    {
      field: "DISQ_AMT",
      accessor: "DISQ_AMT",
      align: "right",
      width:  fourthWidth,
      fontSize:width >768? '12px':'10px',
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.value !== 0) {
          return FormatNumber2(params.value)
        } else {
          return "";
        }
      },
      renderHeader: (params) => (
        <div style={{ fontSize: 13 }}>{"Disq. Amt."}</div>
      ),
    },

    {
      field: "AMOUNT",
      accessor: "AMOUNT",
      align: "right",
      fontSize:width >768? '12px':'10px',

      width:  sixtWidth,
       renderCell: (params) => {
        if (params.value !== 0) {
          return FormatNumber2(params.value)
        } else {
          return "";
        }
      },
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => <div style={{ fontSize: 13 }}>{"Amount"}</div>,
    },
  ];

  return mobile
}


