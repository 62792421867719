import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { SelectSection } from "./refresh";

export const Masters = createSlice({
  name: "masters",
  initialState: {

    expense: {
     
   }
   
  },

  reducers: {

  },
});

export default Masters.reducer;
export const {
} = Masters.actions;

