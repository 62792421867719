import moment from "moment";
import { FormatNumber, FormatNumber2 } from "../../utility/functions/formatNumber";

export const SummaryColumns = (width) => {
    const columns = [
    
      {
        field: 'BILL_NO',
        accessor: 'BILL_NO',

        type: 'string',
        headerAlign: 'center',
        width: 100,
        headerClassName: 'super-app-theme--header',
  
        renderCell: (param) => {
          return (
            <div
              className={`h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Bill No.'}{' '}
          </div>
        ),
      },
      {
        field: 'DATE',
        accessor: 'DATE',
        type: 'string',
        type:'date',
        headerAlign: 'center',
        width:  100,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => {
          return (
            <div
              className={`h-full w-full flex items-center  `}
            >
              {moment(params.value).format('YYYY-MM-DD')}{' '}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Date'}{' '}
          </div>
        ),
      },
      {
        field: 'HSN_CODE',
        accessor: 'HSN_CODE',
        type: 'number',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        headerClassName: 'super-app-theme--header',
        width: 100,
        headerAlign: 'center',
        align: 'left',
  
        renderHeader: (params) => (
          <div
            className="text-white  whitespace-normal"
            style={{ fontSize: 13 }}
          >
            {' '}
        Hsn Code
          </div>
        ),
      },

      {
        field: 'AMOUNT',
        accessor: 'AMOUNT',
        type: 'number',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {FormatNumber2(param.value) }
            </div>
          );
        },
        headerClassName: 'super-app-theme--header',
        width: 100,
        headerAlign: 'center',
        align: 'right',
  
        renderHeader: (params) => (
          <div
            className="text-white  whitespace-normal "
            style={{ fontSize: 13 }}
          >
            {' '}
   Amount
          </div>
        ),
      },
      {
        field: 'CGST_RATE',
        accessor: 'CGST_RATE',
        type: 'string',
        width:80,
        align: 'right',

        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value+ '%' }
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
        CGST %
          </div>
        ),
      },
      {
        field: 'CGST_AMT',
        accessor: 'CGST_AMT',
        type: 'string',
        align: 'right',

        width: 100,
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {FormatNumber2(param.value) }
            </div>
          );
        },
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'CGST Amount '}{' '}
          </div>
        ),
      },
  
    
    
      {
        field: 'SGST_RATE',
        accessor: 'SGST_RATE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width:80,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
            {param.value+ '%' }
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
SGST %
          </div>
        ),
      },
   
      {
        field: 'SGST_AMT',
        accessor: 'SGST_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 100,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
      SGST Amount
          </div>
        ),
      },
      {
        field: 'IGST_RATE',
        accessor: 'IGST_RATE',
        type: 'string',
        width:80,
        align: 'right',

        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
           {param.value+ '%' }
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
         IGST %
          </div>
        ),
      },
      {
        field: 'IGST_AMT',
        accessor: 'IGST_AMT',
        type: 'string',
        width: 100,
        align: 'right',

        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {FormatNumber2(param.value) }
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            IGST Amount
          </div>
        ),
      },
      {
        field: 'NET_AMOUNT',
        accessor: 'NET_AMOUNT',
        type: 'string',
        width: 130,
        align: 'right',

        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {FormatNumber2(param.value) }
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Net Amount
          </div>
        ),
      },
    ];
    return columns;
  };