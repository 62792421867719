import axios from "axios";
import { setAlertWithTitle, setSessionExpired, setSpinnerLoading } from "../../utility/Redux/modal";

 
export const saveSUbSectionMasterApi = (setSubSecFoc,secName,rows,dispatch,listSectionMasterApi,prefix,id,setId,setRows,token,setSecName,afterCreate) => {
    if (secName) {
      const isDuplicateMobile = rows.some(
        (row) => row.SUB_SEC_NAME === secName
      );

      if (isDuplicateMobile) {
       
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'This Sub. Section is already exists. Please choose a different.',
          })
      
        );
        setSecName('')
        setSubSecFoc()
        return;
      }

      const data = {
        table_prefix: prefix,
        SUB_SEC_NAME: secName,
        "CODE":id? id:"",
    
      };

      id
        ? dispatch(setSpinnerLoading('Updating'))
        : dispatch(setSpinnerLoading('Saving'));
      axios
        .post(`/api/master/saveSubSectionMaster`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async function (response) {
          if (!response.data.error) {
            id
              ? dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Updated successfully.',
                    lottie: 'success',
                  })
                )
              : dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Saved successfully.',
                    lottie: 'success',
                  })
                );
                listSectionMasterApi()
                if(afterCreate){
                  afterCreate()
                }
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error 112',
                msg: response.data.error,
              })
            );
            console.log(!response.data.error);
          }
        })
        .catch(function (error) {
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
            console.log(error.response);
          }else{
            dispatch(
              setAlertWithTitle({
                title: 'ERROR',
                msg: 'Something went wrong.',
                lottie: 'reject',
              })
            );
           }
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
          setSecName('');
          if(setId){
            setId('');

          }
        });
    } else {
      if (!secName) {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'Please Enter Sub. Section Name.',
          })
        );
        setSubSecFoc()
      }
    }
  };