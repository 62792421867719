import React, {  useState } from 'react';
import Modal from '@mui/material/Modal';
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from '../../Redux/modal';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../images/icon.svg';
import { CancelButton } from '../buttons';
import { Button } from '@mui/material';
import CallAPI from '../../functions/getData';
import { selectPrefix } from '../../Redux/profile';
import { selectToken } from '../../Redux/security';

export default function ActionRemarks({
  remarksStatus,
  setRemarksStatus,
  currentInvoiceNumber,setSummaryList,onBack
}) {
  const dispatch = useDispatch();
  const [remarks, setRemarks] = useState('');
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    // border: '1px solid white',
    boxShadow: 24,
    borderRadius: '7px',
    outline: 'none',
  };

  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);

  return (
    <div>
      <Modal open={remarksStatus}>
        <div style={style} className=" w-full min-w-[280px] max-w-[320px]">
          <div className={'h-full w-full bg-white  rounded-sm  p-4'}>
            <div className=" flex justify-center w-full rounded-sm">
              <div
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: -30,

                  borderRadius: 100,
                }}
                className="bg-white p-[2px]"
              >
                <img
                  src={logo}
                  alt=""
                  style={{
                    width: '50px',
                    height: 'auto',
                  }}
                />
              </div>
            </div>

            <div className="w-full justify-center text-center text-[#1c1a1a] flex mt-2 font-semibold items-top">
              Do you really want to Delete this Bill?
            </div>
            <div
              style={{
                fontSize: '13px',
              }}
              className="w-full justify-center border border-[#b5b3b3] rounded-sm overflow-hidden text-center text-[#1c1a1a] flex mt-2 "
            >
              <textarea
                value={remarks}
                autoFocus
                rows={4}
                maxLength={100}
                placeholder="Remarks"
                className="outline-none w-full p-2"
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>

            <div className="w-full flex justify-end mt-3">
              <div className="w-auto mr-1">
                {' '}
                <CancelButton
                  onClick={() => {
                    setRemarksStatus(false);
                  }}
                />
              </div>
              <Button
                size={'small'}
                onClick={() => {
                  if (remarks) {
                    dispatch(setSpinnerLoading('Voiding'));
                    CallAPI(
                      '/api/billing/changeSaleBillStatus',
                      {
                        table_prefix: prefix,
                        INVNO: currentInvoiceNumber,
                        BILL_STATUS: 'Void',
                        REMARKS: remarks,
                      },
                      token,
                      (e) => {
                        if (!e.data.error) {
                    setRemarksStatus(false);
                    setRemarks('')
                    onBack()
                          dispatch(
                            setAlertWithTitle({
                              title: 'Success',
                              msg: `Bill successfully  ${'Voided'}`,
                            })
                          );
                          if(setSummaryList){
                            setSummaryList(p =>p.map(old =>{
                              if(old.INVNO === currentInvoiceNumber){
                                return {
                                  ...old,
                                  BILL_STATUS :'Void'
                                }
                              }else{
                                return old
                              }
                            }))
                          }
                        } else {
                          dispatch(
                            setAlertWithTitle({
                              title: 'Alert',
                              msg: 'Something went wrong',
                            })
                          );
                        }
                      },
                      dispatch,
                      () => {
                        dispatch(setSpinnerLoading(false));
                      }
                    );
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Alert',
                        msg: 'Remarks required',
                      })
                    );
                  }
                }}
                style={{
                  backgroundColor: '#e97a7a',
                  color: 'white',

                  textTransform: 'none',
                  width: 'auto',
                  justifyContent: 'start',
                }}
                variant="contained"
              >
                <div className="px-6">{'Void'}</div>{' '}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
