import React from 'react'
import CallAPI from '../../utility/functions/getData';
import { setAlertWithTitle, setSpinnerLoading } from '../../utility/Redux/modal';
import dataToLocal from '../../utility/component/dataToLocal';
import deleteWholeDatabase from '../../utility/functions/deleteWholeDatabase';

export const GetSaleSummary = (data,token,dispatch,setSummaryList) => {
    dispatch(setSpinnerLoading('loading'))
    deleteWholeDatabase('sale_summary')
    CallAPI(
        "/api/saleReports/saleSummary",
        data,
        token,
        (e) => {
            if (!e.data.error) {
                if(e.data.response[0]){
                    setSummaryList(e.data.response)
                    dataToLocal('sale_summary', e.data.response, [
                        {
                         ...data,
                         ICODE:1
                        },
                      ]);
                }else{
                     dispatch(
                     setAlertWithTitle({
                     title: 'Alert',
                     msg: 'No Records Found',
                      }))
                }
            } else {
                dispatch(
                    setAlertWithTitle({
                        title: "Alert",
                        msg: "Something Went Wrong.",
                    })
                );
            }
        },
        dispatch,()=>{
            dispatch(setSpinnerLoading(false))
        }
        )
}
