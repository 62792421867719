import axios from 'axios';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../Redux/modal';

const CallAPI = (path, data, token, then, dispatch, final, c) => {
  axios
    .post(path, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((t) => {
      then(t);
      if (t?.data?.error) {
        console.log('err');
        dispatch(setSpinnerLoading(false));
      }
    })
    .catch((error) => {
  
      // dispatch(setSpinnerLoading(false));
      console.log(error);
   
      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));

      } else {
        if (c) {
          c(error);
        }else{
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something went wrong',
            })
          );
        }
 

      }
    })
    .finally(final ? final : () => {});
};

export default CallAPI;
