import moment from 'moment';
import React, { useRef } from 'react';
import { useTable } from 'react-table';
import '../../../styles/print.css';
import { useSelector } from 'react-redux';
import { selectWidth } from '../../Redux/profile';

const GeniusTableReports = ({
    onRowDoubleClick,headerStyle,
  columns,tableBgColor,
  data,borderBottom,onRowClick,
  footer,onCellDoubleClick,rowBgColor,hideRightMargin,isVoided
}) => {
  // Create an instance of the table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
    const div1Ref = useRef(null);
    const div2Ref = useRef(null);
    const div3Ref = useRef(null);

    const handleDiv1Scroll = () => {
        // Get the scroll position of div1
        const scrollTop = div2Ref.current.scrollLeft;
        // Set the scroll position of div2 to match div1
        div1Ref.current.scrollLeft = scrollTop;
        div3Ref.current.scrollLeft = scrollTop;

    };

    const handleDiv2Scroll = () => {
      // Get the scroll position of div1
      const scrollTop = div3Ref.current.scrollLeft;
      // Set the scroll position of div2 to match div1
      div1Ref.current.scrollLeft = scrollTop;
      div2Ref.current.scrollLeft = scrollTop;

  };
  const handleDiv3Scroll = () => {
    // Get the scroll position of div1
    const scrollTop = div1Ref.current.scrollLeft;
    // Set the scroll position of div2 to match div1
    div3Ref.current.scrollLeft = scrollTop;
    div2Ref.current.scrollLeft = scrollTop;

};
    const windowWidth = useSelector(selectWidth)
    return (

    <div
    
      style={{ width: '100%', height: '100%', backgroundColor: 'white'  ,}}
      className=' border rounded-t-sm  '
    >
   <div ref={div1Ref} onScroll={handleDiv3Scroll} style={{
     overflowX: 'auto',
     scrollbarWidth: 'none', // Hide scrollbar for Firefox
     WebkitOverflowScrolling: 'touch', // Enable momentum scrolling in iOS
   }} >
<table>
  <thead >
  {headerGroups.map((headerGroup) => (
              <tr
              style={ headerStyle?headerStyle:{
                backgroundColor:'#0d0d0d',
                color:'white',
                border:'1px solid black'
              }}
             className='   rounded-t-sm overflow-hidden'
                {...headerGroup.getHeaderGroupProps()}
              >

                {headerGroup.headers.map((column, index) => (

                  <td
                    {...column.getHeaderProps()}
                    className="whitespace-nowrap  text-center py-2 "
                    style={
                      columns[index].width
                        ? {
                            minWidth: columns[index].width,
                            fontWeight: 500,
                            borderRight: '1px solid #888c89',
                            fontSize: windowWidth<500? '11px': windowWidth<1281? '12px':columns[index]?.fontSize ?columns[index].fontSize:'14px'
                          }
                        : {
                            flex: columns[index].flex,
                            fontWeight: 500,
                            borderRight: '1px solid #888c89',
 fontSize: windowWidth<500? '11px': windowWidth<1281? '12px':columns[index]?.fontSize ?columns[index].fontSize:'14px'

                          }
                    }
                  >
                    {/* {columns[index].width} */}

                    {columns[index].renderHeader().props.children}
                  </td>

                ))}

              </tr>
            ))}
  </thead>
</table>


            
            </div>
      <div
      style={{
        maxHeight:`calc(100% -  ${footer ? 71 :37}px)`,
        overflowX: 'auto',
        scrollbarWidth: 'none', // Hide scrollbar for Firefox
        WebkitOverflowScrolling: 'touch', // Enable momentum scrolling in iOS
        backgroundColor:tableBgColor?tableBgColor:'white'
      }}
      onScroll={handleDiv2Scroll}
      ref={div3Ref}
        className='  w-full overflow-y-auto   '
     
      >
        <table
          {...getTableProps()}
     
          ref={div3Ref}
        style={{
          width: columns.reduce((acc, row) => acc + Number(row.width), 0),
        }}
          className=" "
        >
          <thead  className=''>
            {headerGroups.map((headerGroup) => (
              <tr
               style={{ fontSize:windowWidth>500? '15px':'12px' }}
               className='text-white '
                {...headerGroup.getHeaderGroupProps()}
              >

                {headerGroup.headers.map((column, index) => (

                  <td
                    {...column.getHeaderProps()}
                    className="whitespace-nowrap  text-center border-t border-black "
                    style={
                      columns[index].width
                        ? {
                            minWidth: columns[index].width,
                            fontWeight: 500,
                          }
                        : {
                            flex: columns[index].flex,
                            fontWeight: 500,
                            borderRight: '1px solid #888c89',
                          }
                    }
                  >
                    {/* {columns[index].width} */}

                    {/* {columns[index].renderHeader().props.children} */}
                  </td>

                ))}

              </tr>
            ))}
          </thead>
         
          <tbody style={{
            backgroundColor:tableBgColor ?tableBgColor :'white',
          }} {...getTableBodyProps()} className='   '>
     
            {rows.map((row,rowIndex) => {
               const targetedStyle = isVoided?  isVoided(row.original) :''
              prepareRow(row);
              return (
                <tr className='hover:cursor-pointer' onClick={onRowClick ? ()=>onRowClick(row) :null} {...row.getRowProps()} onDoubleClick={onRowDoubleClick ? ()=>onRowDoubleClick(row) :null} {...row.getRowProps()}>
                  {row.cells.map((cell,i) => {
                    
                    return(
                    <td
                      {...cell.getCellProps()}
                      style={{
                        borderRight: '1px dashed #b5b3b3',
                        borderTop:rowIndex ===0 ? '0 dashed #b5b3b3':  '1px dashed #b5b3b3',
                        borderBottom:  '1px dashed #b5b3b3',
                        maxWidth: cell.column.width,
                    
 fontSize: windowWidth<500? '11px': windowWidth<1281? '12px': cell.column.fontSize ? cell.column.fontSize:'14px'
                      }}
                      onDoubleClick={()=> {

                        if(onCellDoubleClick){
                          onCellDoubleClick({
                            ...cell,
                            row:{
                              ...cell.row,
                              values:data[rowIndex]
                            }
                          })}
                        }
                      }
                    >
                      <div
                      
                        style={{
                          textAlign: cell.render('Cell').props.cell.column.align
                            ? cell.render('Cell').props.cell.column.align
                            : 'left',
                            color:targetedStyle?.color?targetedStyle.color :'black',
                           
                            backgroundColor:targetedStyle?.backgroundColor?targetedStyle.backgroundColor : tableBgColor ?tableBgColor :'white',
                            overflow: 'hidden', // Hide any content that overflows the box
                            whiteSpace: 'nowrap', // Prevent text from wrapping
                            textOverflow: 'ellipsis' ,
                        }}
                        className=" p-1 h-[30px] "
                      >
<div style={{
                            marginRight: hideRightMargin ?0 :i === row.cells.length -1 ? '15px' :0,

}}>
        
        {cell.render('Cell').props.cell.column.type === 'date'
                          ? moment(cell.render('Cell').props.cell.value).format(
                              'YYYY-MM-DD'
                            )
                          : cell.render('Cell').props.cell.value === '0.00' ||cell.render('Cell').props.cell.value === 0 ||cell.render('Cell').props.cell.value === "0"
                          ? " "
                          : cell.column.renderCell ? cell.column.renderCell(cell.render('Cell').props.cell) : cell.render('Cell')}                  


</div>
                      </div>
                    </td>
                  ) })}
                </tr>
              );
            })}
          </tbody>
        </table>

    
      </div>
    
      <div   onScroll={handleDiv1Scroll}  className='w-full' style={{
           overflowX: 'auto',
      }} ref={div2Ref} >
       {footer ? footer :''}

      </div>
    </div>
  );
};

export default GeniusTableReports;
