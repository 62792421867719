import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Login from './auth/login'
import MobileSignUp from './auth/mobilesignup';
import FinancialYearForm from './auth/financial-year';
import { ResetEmail } from './auth/resetUserEmail';
import PartyMaster from './masters/account-master';
import OfferMaster from './masters/offer-master';
import HsnMaster from './masters/hsn-master';
import { ItemWiseSaleReport, Reports } from './reports';
import SaleSummary from './reports/saleSummary';
import Purchase from './purchase';
import ItemWisePurchaseReport from './reports/itemWisePurchaseSummary';
import PurchaseSummary from './reports/purchaseSummary';
import UserMaster from './settings/userMaster';
import ExpenseMaster from './masters/expense';
import PaymentVoucher from './updation/paymentVoucher';
import VoucherSummary from './reports/voucherSummary';
import MessageSetupMaster from './settings/messageSetupMaster';
import CompanyMaster from './settings/companyMaster';
import OfferList from './reports/offerSummery';
import RecieptVoucher from './updation/recieptVoucher';
import Dashboard from './dashboard';
import ForgotPassword from '../src/auth/changePassword'
import InvoiceBill from './invoice';
import {CustomerMaster, DepartMentMaster,ItemMaster, SectionMaster, SubSecMaster} from './masters/index'
import Masters from './masters/dashboard';
import UserDefaultSetting from './settings/userDefaultSetting';
import {ActivationAccount, Auth,NotFound} from './auth'
import ProtectedRoute from './auth/util/protectedRoute';
import Home from './home';
import VerifyEmail from './auth/verifyEmail';
import Updation from './updation/dashboard';
import Settings from './settings/dashboard';
import NewLoginDesign from './auth/loginNewDesign';
import SaleTaxReport from './reports/saleTaxReport';
import PaymodeSummary from './reports/payModeSummary';
import ItemWisePurchase from './reports/itemWiseHistory';
import ItemStatus from './reports/itemStatus';
import StockReport from './reports/stockReport';
import QRCodeScanner from './utility/component/AllScanner';
import CreateCompanyDb from './settings/createDatabase';
import Billing from './billing';
import DemoRegister from './auth/login/demoRegister';
import BillDashboard from './dashboard/billDashboard';
import ExpiryReport from './reports/expiryReport';

const AppRoutes = ({ userType }) => {
  const authRoutes = [
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'demoRegister',
      element: <DemoRegister />,
    },
    {
      path: 'mobile_signup',
      element: <MobileSignUp />,
    },
    {
      path: 'barCode',
      element: <QRCodeScanner />,
    },
    {
      path: 'financial_year',
      element: <FinancialYearForm />,
    },
    {
      path: 'reset_password_email',
      element: <ResetEmail />,
    },
  ];
  const privateRoutes = [
    { path: '/account_master', element: PartyMaster, userType: 'allowed' },
    { path: '/newLogin', element: NewLoginDesign, userType: 'allowed' },
    { path: '/sale_tax_report', element: SaleTaxReport, userType: 'allowed' },
    { path: '/paymode_summary', element: PaymodeSummary, userType: 'allowed' },
    { path: '/select_bill_type', element: BillDashboard, userType: 'allowed' },

    { path: '/company_master', element: CompanyMaster, userType: 'ADMIN' },
    { path: '/customer_master', element: CustomerMaster, userType: 'allowed' },
    { path: '/dashboard', element: Dashboard, userType: 'ADMIN' },
    { path: '/department_master', element: DepartMentMaster, userType: 'allowed' },
    { path: '/expense_master', element: ExpenseMaster, userType: 'MANAGER ADMIN' },
    { path: '/hsn_master', element: HsnMaster, userType: 'allowed' },
    { path: '/invoice', element: InvoiceBill, userType: 'allowed' },
    { path: '/item_master', element: ItemMaster, userType: 'allowed' },
    { path: '/item_wise_purchase_report', element: ItemWisePurchaseReport, userType: 'allowed' },
    { path: '/item_wise_sale_report', element: ItemWiseSaleReport, userType: 'allowed' },
    { path: '/masters', element: Masters, userType: 'allowed' },
    { path: '/message_setup_master', element: MessageSetupMaster, userType: 'ADMIN' },
    { path: '/offer_list', element: OfferList, userType: 'allowed' },
    { path: '/offer_master', element: OfferMaster, userType: 'allowed' },
    { path: '/stock_report', element: StockReport, userType: 'allowed' },
    { path: '/payment_voucher', element: PaymentVoucher, userType: 'allowed' },
    { path: '/purchase', element: Purchase, userType: 'allowed' },
    { path: '/purchase_summary', element: PurchaseSummary, userType: 'allowed' },
    { path: '/reciept_voucher', element: RecieptVoucher, userType: 'allowed' },
    { path: '/reports', element: Reports, userType: 'allowed' },
    { path: '/sale_summary', element: SaleSummary, userType: 'allowed' },
    { path: '/expiry_report', element: ExpiryReport, userType: 'allowed' },
    { path: '/section_master', element: SectionMaster, userType: 'allowed' },
    { path: '/item_wise_purchase', element: ItemWisePurchase, userType: 'allowed' },
    { path: '/settings', element: Settings, userType: 'allowed' },
    { path: '/sub_section_master', element: SubSecMaster, userType: 'allowed' },
    { path: '/updation', element: Updation, userType: 'allowed' },
    { path: '/user_default_setting', element: UserDefaultSetting, userType: 'MANAGER ADMIN' },
    { path: '/item_status', element: ItemStatus, userType: 'allowed' },
    { path: '/create_db', element: CreateCompanyDb, userType: 'ADMIN' },
    { path: '/bill', element: Billing, userType: 'allowed' },
    { path: '/performa', element: Billing, userType: 'allowed' },

    { path: '/user_master', element: UserMaster, userType: 'MANAGER ADMIN' },
    { path: '/voucher_summary', element: VoucherSummary, userType: 'allowed' },

  ];
  
  const otherRouter = [
    '/changeUserPassword',
    '/activateUser',
    '/emailVerified','invoice','/auth/mobile_signup','/auth/barCode','/auth/demoRegister'
  ];


  const expires = localStorage.getItem('expiresAt');
  const date = new Date().getTime();
  const navigate = useNavigate();
  const location = useLocation();

  const UserTime = () => {
    if (expires) {
      if (expires < date) {
        navigate('/auth/login');
      }
    } else {
      navigate('/auth/login');
    }
  };
  
  useEffect(() => {
    const isOtherRoute = otherRouter.filter((r) => r === location.pathname);
    if (!isOtherRoute[0]) {
      UserTime();
    }
  }, []);
  return (
    <>
      <Routes>
        <Route path="/auth" element={<Auth />}>
          {authRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        >
          {privateRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  route.userType.includes(userType) ||
                  route.userType === 'allowed' ? (
                    <ProtectedRoute>
                      {' '}
                      <route.element />
                    </ProtectedRoute>
                  ) : (
                    <NotFound />
                  )
                }
              />
            );
          })}
        </Route>
        <Route path="/changeUserPassword" element={<ForgotPassword />} />
        <Route path="/activateUser" element={<ActivationAccount />} />
        <Route path="/emailVerified" element={<VerifyEmail />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
