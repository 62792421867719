import axios from "axios";
import { isFirebaseUserLoginCheckEnabeld } from "../../utility/constant";
import { getLongitudeLattitude, reverseLocation } from "../../utility/functions/common";
import { database } from "../../utility/functions/firebase";
import SendOtp from "../../utility/functions/sendOtp";
import { setAlertWithTitle, setSpinnerLoading } from "../../utility/Redux/modal";
import { selectFirebaseStatusField, setfirebaseStatusField, setUserCode } from "../../utility/Redux/profile";
import { setOtpMobile, setOtpStatus, setRandomOtp, setToken } from "../../utility/Redux/security";
import { child, get, set, ref } from 'firebase/database';
import HandleIndexedDB from "../../Test/handleIndexDb";
import IsLocalData from "../../utility/component/isLocalData";
import deleteWholeDatabase from "../../utility/functions/deleteWholeDatabase";
import moment from "moment";

const getVaildOTPDays = (code,prefix,token)=>{
  return new Promise((resolve, reject) => {
    const data = {
      code: code,
      table_prefix: prefix.replace(/_.+_/, "_"),
    };
    axios
      .post(
        `/api/userSettings/userDefaultSetting`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            resolve(res.data.response);
          } else {
            resolve([{
              LOGIN_OTP:'No',
              VALID_FOR_DAYS:1

            }]);
          }
        } else {
          resolve([{
            LOGIN_OTP:'No',
            VALID_FOR_DAYS:1

          }]);
        }
      })
      .catch((err) => {
        console.log(err);
        resolve([{
          LOGIN_OTP:'No',
          VALID_FOR_DAYS:1

        }]);
      });
  });
}




function checkStateAllowedOrNot(fetchAllowedState, data, dispatch, e, rememberMe, storeRememberData, username, password, move) {
    fetchAllowedState(data.company_email).then(allowedState => {
  
      getLongitudeLattitude().then(data => {
        reverseLocation(data.latitude, data.longitude).then(curentState => {
          const isInAllowedStates = allowedState.filter(s => s.STATE_NAME.toLowerCase() === curentState.region.toLowerCase());
          if (isInAllowedStates[0]) {
            runLoginFunc(dispatch, e, data, rememberMe, storeRememberData, username, password, move);
          } else {
            dispatch(setAlertWithTitle({
              title: 'Access Denied',
              msg: 'Login is restricted to authorized locations only.'
            }));
          }
  
        }).catch(() => {
          dispatch(setAlertWithTitle({
            title: 'Access Denied',
            msg: 'Login is restricted to authorized locations only.'
          }));
        });
      }).catch(() => {
        dispatch(setAlertWithTitle({
          title: 'Alert',
          msg: 'Location access was denied. Please enable location permissions in your browser settings to use this feature.'
        }));
      });
  
  
  
  
    });
  }
  
  function runLoginFunc(dispatch, e, data, rememberMe, storeRememberData, username, password, move) {
    const expiresAt = new Date().getTime() + 60 * 60000
    localStorage.setItem("expiresAt", expiresAt);
   if(isFirebaseUserLoginCheckEnabeld && !data.table_prefix.includes('go_')){
 if(data.user_type ==='MANAGER' || data.user_type === 'ADMIN'){
    dispatch(setSpinnerLoading('Checking your connection status...'))
    checkFirebaseLoginManager(data.table_prefix,data.code,dispatch,expiresAt,'status').then(()=>{
        successfullyLogin(e,data,dispatch,rememberMe,storeRememberData,username,password,move) 
    }).catch(()=>{
        checkFirebaseLoginManager(data.table_prefix,data.code,dispatch,expiresAt,'status2').then(()=>{
            successfullyLogin(e,data,dispatch,rememberMe,storeRememberData,username,password,move) }).catch(()=>{
                dispatch(setAlertWithTitle({
                    title:'Alert',
                    msg:'Already Connected'
                }))
            })
    })
 }else{
    checkFirebaseLoginManager(data.table_prefix,data.code,dispatch,expiresAt,'status').then(()=>{
        successfullyLogin(e,data,dispatch,rememberMe,storeRememberData,username,password,move) 
    }).catch(()=>{
        dispatch(setAlertWithTitle({
            title:'Alert',
            msg:'Already Connected'
        }))
    })
 }
 
   }else{
    successfullyLogin(e,data,dispatch,rememberMe,storeRememberData,username,password,move) 

   }
  
    
  }

  function getRandomNumber() {
    const min = 1000;
    const max = 9999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }


const successfullyLogin = (e,data,dispatch,rememberMe,storeRememberData,username,password,move)=>{
  const elseFunc = ()=>{
    getVaildOTPDays(data.code,data.table_prefix,e.data.token).then((d)=>{
const isOtpRequired = d[0]?.LOGIN_OTP === 'Yes'
      if(isOtpRequired){
        const randomeNumber = getRandomNumber()
    
        SendOtp(data.company_mobile,data.table_prefix.replace(/_.+_/, "_"),randomeNumber,data.user_email,dispatch,e.data.token).then(()=>{
          
          })
      
        dispatch(setOtpStatus(true))
          dispatch(setRandomOtp(randomeNumber))
          dispatch(setOtpMobile(data.company_mobile))
        dispatch(setToken(e.data.token));
          dispatch(setUserCode(data.code));
          if (rememberMe) {
            storeRememberData(username, password);
          } else {
            localStorage.removeItem("rememberd");
          }
          dispatch(setSpinnerLoading(false));
      }else{
        dispatch(setToken(e.data.token));
        dispatch(setUserCode(data.code));
        if (rememberMe) {
          storeRememberData(username, password);
        } else {
          localStorage.removeItem("rememberd");
        }
        dispatch(setSpinnerLoading(false));
          move("/auth/financial_year", {
        state: {
          CompanyEmail: data?.company_email,
          UserEmail: username,
          companyName: data?.company_name,
        },
      });
      if(data){
        const databaseName = 'userDetails';
              const storeName = 'data';
          deleteWholeDatabase(databaseName);
    
          const dataToSave = [
            {
              start_date: data.start_date,
              end_date: data.end_date,
              currency_name: data.currency_name,
              currency_symbol: data.currency_symbol,
              user_email: data.user_email,
              ICODE: 1,
              lastLoginDate:""
            },
          ];
    
          HandleIndexedDB({
            databaseName,
            storeName,
            dataToSave,
          });
      }
      }
      
    })
   
      
  }
  const callback = (details, localData) => {
    if(localData[0]){
      if(localData[0].user_email=== data.user_email){
        getVaildOTPDays(data.code,data.table_prefix,e.data.token).then(d =>{
          const validDays = d[0].VALID_FOR_DAYS
          const lastLoginDate =localData[0]?.lastLoginDate? moment(localData[0]?.lastLoginDate).format('YYYY-MM-DD'):moment().format('YYYY-MM-DD')
          const currentDate = moment();
    
          const differenceInDays = currentDate.diff(lastLoginDate, 'days');
          if(Number(validDays ) > Number(differenceInDays) &&localData[0]?.lastLoginDate ){
           
        
       
          dispatch(setToken(e.data.token));
            dispatch(setUserCode(data.code));
            if (rememberMe) {
              storeRememberData(username, password);
            } else {
              localStorage.removeItem("rememberd");
            }
            dispatch(setSpinnerLoading(false));
              move("/auth/financial_year", {
            state: {
              CompanyEmail: data?.company_email,
              UserEmail: username,
              companyName: data?.company_name,
            },
          });
       
   
          }else{
            elseFunc()
          }
        })
      }else{
        elseFunc()
      }
    
   

    }else{
      elseFunc()

    }

  };


  const databaseName = 'userDetails';
  const storeName = 'data';
  IsLocalData(databaseName, callback, elseFunc);

 



}
const checkIsRejected = (connectedRef, statusField, dispatch) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        get(child(connectedRef, `/${statusField}/msg`)).then((msg) => {
          if (msg.exists()) {
            const data = msg.val();
            if (!data.includes('rejected')) {

              resolve();  // Successfully resolved
            } else {
              reject(new Error('Connection rejected'));  // Rejected with an error
            }
          } else {

            reject(new Error('No message found'));  // Rejected due to missing message
          }
        }).catch((err) => {
            console.log(err)

          reject(err);  // Handle any errors from the Firebase get function
        });
      }, 2000);
    });
  };
  

  const checkFirebaseLoginManager = (prefix, userCode, dispatch, expiresAt,statusField) => {
    return new Promise((resolve, reject) => {
      const connected = ref(
        database,
        `loginAuth/${prefix}/user/${userCode}`
      );
      const setConnectMsgRef = ref(
        database,
        `loginAuth/${prefix}/user/${userCode}/${statusField}/msg`
      );
      const setConnectRef = ref(
        database,
        `loginAuth/${prefix}/user/${userCode}/${statusField}/connection`
      );
  
      // Dispatch initial state
      dispatch(setfirebaseStatusField(statusField));
  
      // Set the connection message
      set(setConnectMsgRef, `isConnected`);
  
      // Proceed to check rejection status
      checkIsRejected(connected, statusField, dispatch)
        .then(() => {
            set(setConnectRef, `c-${expiresAt}`);
            dispatch(setfirebaseStatusField(statusField))
          // If checkIsRejected resolves, resolve the outer promise
          resolve();
        })
        .catch((error) => {
            console.log(error)
          // If checkIsRejected rejects, reject the outer promise with the error
          reject(error);
        });
    });
  };

  export {checkStateAllowedOrNot,runLoginFunc}