import React, {  useEffect, useRef, useState } from 'react'
import ViewBillImage from '../billing/viewBillImage'
import { decryptData, } from '../utility/component/encryptDecrypt';
import CallAPI from '../utility/functions/getData';
import CryptoJS from "crypto-js";
import { useDispatch } from 'react-redux';


const InvoiceBill = () => {
    const [billStatus,setBillStatus] = useState(true)
  const [orderList,setOrderList] = useState([])
const componentRef = useRef(null)
  const url = window.location.search.substring(1)
  const decodeUrl = decodeURIComponent(url)
const decrytpurl =  decryptData(decodeUrl)
const dispatch = useDispatch()

const [companyData,setCompanyData] = useState( {

    "code": "",
    "company_email": "",
    "company_name": "",
    "company_mobile": "",
    "company_website": "",
    "group_name": null,
    "address": "",
    "city": "",
    "state": ""
})

 function parseQueryParams(urlString) {
    const queryParams = {};
    const queryString = urlString.split('?')[1];
    if (queryString) {
        const pairs = queryString.split('&');
        pairs.forEach(pair => {
            const [key, value] = pair.split('=');
            queryParams[key] = value;
        });
    }
    return queryParams;
}
const queryParams = parseQueryParams('http://localhost:3000/invoice?'+decrytpurl);




 const bill = queryParams['b'];
 const companyEmail =  queryParams['c']
 const prefix =  queryParams['p'];
 useEffect(()=>{
if(companyEmail){
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
  const hmac = CryptoJS.HmacSHA256(companyEmail, secretPass);

  const hmacString = hmac.toString(CryptoJS.enc.Base64);
  const d ={
    "company_email":companyEmail,
    "company_mobile":""

  }
  CallAPI('/api/users/companyInfo',d,hmacString,(e)=>{
    if(e.data.response[0]){
      setCompanyData(e.data.response[0])


    }
  },dispatch)
}
 },[companyEmail])


  return (
    <div className='p-2 w-full flex justify-center'>
        <div className='border w-fit'>
            
        <ViewBillImage bill_performa={'bills'} companyData={companyData} tPrefix={prefix}  byHmac={true} setBillStatus={setBillStatus } setOrderList={setOrderList} ref={componentRef} billNo={bill} />

        </div>

    </div>
  )
}

export default InvoiceBill