import React from 'react'
import { FormatNumber2 } from '../../utility/functions/formatNumber'
import { useSelector } from 'react-redux'
import { selectWidth } from '../../utility/Redux/profile'

const Footer = ({columns,rows}) => {
  const windowWidth = useSelector(selectWidth)
  return (
    <div className='flex  w-full text-sm mt-1'>
        <div className='flex'>
        {
     columns.map((e,i) => {
        return (
            <div key={i}  style={{
                width:e.width,
borderLeft :i === 0 ? '1px solid black' : '1px solid black',
borderRight :i === columns.length -1 ? '1px solid black' : '0px solid black',
                borderBottom: '1px solid black',
                borderTop: '1px solid black',
                fontSize:windowWidth>500?'14px':'11px'
            }} className='p-1 text-right'>
    {e.field ==='DATE' &&  'Total'}

    {e.field ==='CASH_AMOUNT' &&rows.reduce((acc, row) => acc + Number(row.CASH_AMOUNT), 0)>0&&   FormatNumber2(rows.reduce((acc, row) => acc + Number(row.CASH_AMOUNT), 0)) }
      
    {e.field ==='E_AMOUNT' && rows.reduce((acc, row) => acc + Number(row.E_AMOUNT), 0)>0&&  FormatNumber2(rows.reduce((acc, row) => acc + Number(row.E_AMOUNT), 0)) }
    {e.field ==='CARD_AMOUNT' && rows.reduce((acc, row) => acc + Number(row.CARD_AMOUNT), 0) > 0&&  FormatNumber2(rows.reduce((acc, row) => acc + Number(row.CARD_AMOUNT), 0)) }
    {e.field ==='BTC_AMOUNT' && rows.reduce((acc, row) => acc + Number(row.BTC_AMOUNT), 0) >0 &&  FormatNumber2(rows.reduce((acc, row) => acc + Number(row.BTC_AMOUNT), 0)) }
                </div>
        )
     })   
        
        
    }  
        </div>
</div>
  )
}

export default Footer