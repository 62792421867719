import React, { useEffect, useRef, useState } from "react";
import GeniustextInput from "../../utility/component/mastertext";
import moment from "moment";
import { SaveButton, UndoButton } from "../../utility/component/buttons";
import { useDispatch, useSelector } from "react-redux";
import { setAlertWithTitle } from "../../utility/Redux/modal";
import { useNavigate } from "react-router-dom";
import MasterDesign from "../../utility/component/styles/MasterDesign";
import { saveOfferMaster } from "./func";
import { selectPrefix, selectWidth } from "../../utility/Redux/profile";
import { selectToken } from "../../utility/Redux/security";
import { ItemMasterHelp } from "../../help";
import ModalHeaderStyle from "../../utility/component/styles/modalHeader";
import AlertModalTwoButton from "../../utility/component/modals/alertWithTwoButton";
import OfferHelp from "../../help/offerHelp";
import { GetOfferList } from "../../reports/offerSummery/func";
import GeniusTableReports from "../../utility/component/geniusComponents/geniusTableData";
import { SummaryColumns } from "../../reports/offerSummery/columns";

const OfferMaster = () => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const [tabScreen, setTabScreen] = useState(1);
  const [summaryList,setSummaryList] = useState([])

  const prefix = useSelector(selectPrefix);
const [offerHelpStatus,setOfferHelpStatus]= useState(false)
  const [listItemMasterHelpStatus, setListItemMAsterHelpSattuses] =
    useState(false);
  const [data, setData] = useState({
    table_prefix: prefix,
    SERIAL_NO: "",
    DATE: moment().format("YYYY-MM-DD"),
    ALIAS_CODE: "",
    ICODE: "",
    ITEMNAME: "",
    SECTION_NAME: "",
    PACKING: "",
    UNIT: "PACK",
    PUR_PRICE: "0",
    SALE_PRICE: "0",
    MRP_RATE: "0",
    DISQ_RATE: "0",
    DISQ_AMT: "0",
    OFFER_SALE_PRICE: "0",
    FDATE: "",
    TDATE: "",
    FTIME: "",
    TTIME: "",
    OFFER_TYPE:'Regular Offer'
  });
  const undo = () => {
    setData({
      table_prefix: prefix,
      SERIAL_NO: "",
      DATE: moment().format("YYYY-MM-DD"),
      ALIAS_CODE: "",
      ICODE: "",
      ITEMNAME: "",
      SECTION_NAME: "",
      PACKING: "",
      UNIT: "PACK",
      PUR_PRICE: "0",
      SALE_PRICE: "0",
      MRP_RATE: "0",
      DISQ_RATE: "0",
      DISQ_AMT: "0",
      OFFER_SALE_PRICE: "0",
      FDATE: "",
      TDATE: "",
      FTIME: "",
      TTIME: "",
    OFFER_TYPE:'Regular Offer'

    });
  };
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });

  useEffect(()=>{
    GetOfferList({
      "table_prefix":prefix,
      FDATE :"",
      TDATE:""
    },token,dispatch,setSummaryList)
  },[])
  const width = useSelector(selectWidth)
  const columns = SummaryColumns(width)

  useEffect(() => {
    function handleKeyPress(event) {
      // Check if Ctrl or Alt key is pressed along with 'S'
      if ((event.ctrlKey || event.altKey) && event.key === "s") {
        // Call your function here
        if (data.ICODE) {
          if (data.FDATE && data.TDATE) {
            saveOfferMaster(data, token, dispatch, setData);
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "Please enter Offer Period Date",
              })
            );
          }
        } else {
          setAlertTwoButtonModal((e) => {
            return {
              ...e,
              title: "Alert",
              msg: "Please select any item first to save",
              status: true,
              button1: "Yes",
              button1Bg: '#383535',
              button1Click: () => {
                setAlertTwoButtonModal((e) => {
                  return {
                    ...e,
                    status: false,
                  };
                });
                setListItemMAsterHelpSattuses(true);
              },
            };
          });
        }
      }
    }

    // Add event listener when component mounts
    window.addEventListener("keydown", handleKeyPress);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [data]);

  const windowWidth = useSelector(selectWidth);
  return (
    <div
      ref={formRef}
      className="w-full  flex justify-center bg-[#f5f5f7] h-full "
    >
      <div className="text-sm  rounded-sm   flex flex-col  py-2 h-full ">
        <div>
          <ModalHeaderStyle
            header={"Offer Master"}
            onClose={() => {
              navigate("/masters");
            }}
          />
        </div>

        <div
          className="text-sm rounded-sm flex flex-col p-2 bg-white"
          style={{
            height:
              windowWidth <= 1280 ? `calc(100% - 80px)` : `calc(100% - 90px)`,
          }}
        >
              <div
              
              className=" w-full  rounded-sm flex "
            >
              <div
                onClick={() => {
                  setTabScreen(1);
                }}
                style={{
                  color: tabScreen === 1 ? "white" : "gray",
                }}
                className={`flex-1 p-2 ${
                  tabScreen === 1
                    ? "bg-[#0d0d0d] "
                    : " hover:bg-[#f1f5f9]  bg-[#ebe8e8] hover:cursor-pointer "
                }  flex justify-center items-center rounded-l-sm `}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: tabScreen === 1 ? 600 : 400,
                  }}
                  className=" h-full flex items-center"
                >
                  Master
                </div>
              </div>
              <div
                style={{
                  color: tabScreen === 2 ? "white" : "gray",
                }}
                onClick={() => {
                  setTabScreen(2);
                }}
                className={`flex-1 p-2 ${
                  tabScreen === 2
                    ? "bg-[#0d0d0d] "
                    : " hover:bg-[#f1f5f9] bg-[#ebe8e8] hover:cursor-pointer "
                }  flex justify-center items-center rounded-r-sm `}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: tabScreen === 2 ? 600 : 400,
                  }}
                  className="  flex items-center"
                >
                  Offers List
                </div>
              </div>
            </div>

            {tabScreen === 1 &&         <div className=" w-full  h-full  p-2 px-3 rounded-b-sm  overflow-y-auto ">
            <div className=" w-fit  p-1">
              <div className="flex flex-col l:flex-row">
          
                <div className="">
                  <div className="font-semibold">Item Details</div>

                  <div className="border p-2 rounded-sm bg-white mt-2 grid grid-cols-1 gap-2" >
                  <div className="font-semibold flex items-center">
                Offer  <div>
                <button onClick={()=>{
setOfferHelpStatus(true)
                }} className="flex items-center">
                <i className="bi bi-search text-orange ml-2 flex items-center"></i>
              </button>
                </div>
              </div>
                    <GeniustextInput
                      value={data.ALIAS_CODE}
                      max={15}
                      width={"400px"}
                      grid={true}
                      type={"text"}
                      title={"Bar Code"}
                    />

                    <GeniustextInput
                      value={data.ITEMNAME}
                      onChange={(e) => {
                        setData((old) => {
                          return {
                            ...old,
                            ITEMNAME: e.target.value,
                          };
                        });
                      }}
                      max={100}
                      grid={true}

                      width={"400px"}
                      type={"text"}
                      title={"Item Name"}
                      search={() => {
                        setListItemMAsterHelpSattuses(true);
                      }}
                    />
                    <GeniustextInput
                      value={data.SECTION_NAME}
                      width={"400px"}
                      type={"text"}
                      grid={true}
                      
                      title={"Section Name"}
                    />
                    <div className="grid grid-cols-2 gap-2 ">
                      <GeniustextInput
                        value={data.UNIT}
                        max={20}
                        width={"196px"}
                        type={"text"}
                      grid={true}

                        title={"Unit"}
                      />

                        <GeniustextInput
                          value={data.PACKING}
                          width={"196px"}
                      grid={true}

                          type={"text"}
                          title={"Packing"}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-2 ">
                      <GeniustextInput
                        value={data.MRP_RATE}
                        width={"196px"}
                        type={"text"}
                        title={"MRP"}
                      grid={true}

                      />
                        <GeniustextInput
                          value={data.SALE_PRICE}
                          width={"196px"}
                          type={"text"}
                      grid={true}

                          title={"Sale Price"}
                        />
                    </div>

                    <div className="grid grid-cols-2 gap-2 ">

                      <GeniustextInput
                        value={data.DISQ_RATE}
                        width={"196px"}
                        type={"text"}
                        title={"Discount %"}
                        titleBold={true}
                      grid={true}

                        onChange={(e) => {
                          const v =
                            Number(e.target.value) > 0
                              ? Number(e.target.value)
                              : 0;
                          const dAmt = v * (data.SALE_PRICE / 100);

                          setData((old) => {
                            return {
                              ...old,
                              DISQ_RATE: v,
                              DISQ_AMT: dAmt,
                              OFFER_SALE_PRICE: data.SALE_PRICE - dAmt,
                            };
                          });
                        }}
                      />

                        <GeniustextInput
                          value={data.DISQ_AMT}
                          onChange={(e) => {
                            setData((old) => {
                              return {
                                ...old,
                                DISQ_AMT: e.target.value,
                              };
                            });
                          }}
                      grid={true}

                          width={"196px"}
                          type={"text"}
                          title={"Discount Amount"}
                          titleBold={true}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-2 ">

                    <GeniustextInput
                      value={
                        data.OFFER_SALE_PRICE > 0 ? data.OFFER_SALE_PRICE : ""
                      }
                      grid={true}

                      onChange={(e) => {
                        setData((old) => {
                          return {
                            ...old,
                            OFFER_SALE_PRICE: e.target.value,
                          };
                        });
                      }}
                      width={"196px"}
                      type={"text"}
                      title={"Offer Sale Price"}
                      titleColor={"red"}
                    />
                    <GeniustextInput
                      value={
                        data.OFFER_TYPE
                      }
                      grid={true}

                      onChange={(e) => {
                        setData((old) => {
                          return {
                            ...old,
                            OFFER_TYPE: e.target.value,
                          };
                        });
                      }}
                      option={[
                        {
                          value:'Regular Offer',
                          name:'Regular Offer'
                        },    {
                          value:'Festival Offer',
                          name:'Festival Offer'
                        }
                      ]}
                      width={"196px"}
                      title={"Offer Type"}
                      titleColor={"red"}
                    />
                    </div>
                  </div>
                </div>

                <div className="l:ml-6 sm:mt-3 l:mt-[0px]">
                  <div className="font-semibold">Offer Period</div>
                  <div
                   
                    className="border p-2 rounded-sm bg-white mt-2 grid grid-cols-1 gap-2  "
                  >
                    <div className="grid grid-cols-2 gap-2 mb-[14px] ">
                      <div className="flex-1">
                        <div className="font-semibold text-[16px]">
                          From Date
                        </div>
                        <div
                          style={{
                            border: "1px solid #b5b3b3",
                   
                          }}
                          className="p-2 rounded-sm w-full bg-[#fdfffe]"
                        >
                          <input
                            type="date"
                            className="outline-none w-full bg-[#fdfffe]"
                            value={moment(data.FDATE).format("YYYY-MM-DD")}
                            onChange={(e) => {
                              setData((old) => {
                                return {
                                  ...old,
                                  FDATE: e.target.value,
                                };
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex-1">
                        <div className="w-full">
                          <div className="font-semibold text-[16px]">
                            To Date
                          </div>
                          <div
                            style={{
                              border: "1px solid #b5b3b3",
                            }}
                            className="p-2 rounded-sm w-full bg-[#fdfffe]"
                          >
                            <input
                              type="date"
                              className="outline-none w-full"
                              value={moment(data.TDATE).format("YYYY-MM-DD")}
                              onChange={(e) => {
                                setData((old) => {
                                  return {
                                    ...old,
                                    TDATE: e.target.value,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                       
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-2 ">
                      <div className="flex-1">
                        <div className="font-semibold text-[16px]">
                          From Time
                        </div>
                        <div
                          style={{
                            border: "1px solid #b5b3b3",
                          }}
                          className="p-2 rounded-sm w-full bg-[#fdfffe]"
                        >
                          <input
                            type="time"
                            className="outline-none w-full"
                            value={data.FTIME}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setData((old) => {
                                return {
                                  ...old,
                                  FTIME: e.target.value,
                                };
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex-1">
                        <div className="w-full">
                          <div className="font-semibold text-[16px]">
                            To Time
                          </div>
                          <div
                            style={{
                              border: "1px solid #b5b3b3",
                            }}
                            className="p-2 rounded-sm w-full bg-[#fdfffe]"
                          >
                            <input
                              type="time"
                              className="outline-none w-full bg-[#fdfffe]"
                              value={data.TTIME}
                              onChange={(e) => {
                                setData((old) => {
                                  return {
                                    ...old,
                                    TTIME: e.target.value,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div className="flex p-2   justify-end">
                <SaveButton
                  onClick={() => {
                    if (data.ICODE) {
                      if (data.FDATE && data.TDATE) {
                        saveOfferMaster({
                          ...data,
                          table_prefix:prefix
                        }, token, dispatch, setData);
                      } else {
                        dispatch(
                          setAlertWithTitle({
                            title: "Alert",
                            msg: "Please enter Offer Period Date",
                          })
                        );
                      }
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "Please select any item first to save",
                        })
                      );
                    }
                  }}
                />
                <UndoButton onClick={undo} />
              </div>
                </div>
                
              </div>
      
              <ItemMasterHelp
                onClose={() => {
                  setListItemMAsterHelpSattuses(false);
                }}
                status={listItemMasterHelpStatus}
                onRowClick={(e) => {
                  setListItemMAsterHelpSattuses(false);
                  setData((old) => {
                    return {
                      ...old,
                      ALIAS_CODE: e.row.ALIAS_CODE,
                      ICODE: e.row.ICODE,
                      SECTION_NAME: e.row.SECTION_NAME,
                      UNIT: e.row.UNIT,
                      PUR_PRICE: e.row.PUR_PRICE,
                      SALE_PRICE: e.row.SALE_PRICE,
                      DISQ_RATE: e.row.DISQ_RATE,
                      MRP_RATE: e.row.MRP_RATE,
                      ITEMNAME: e.row.ITEMNAME,
                    };
                  });
                }}
              />
              <AlertModalTwoButton
                details={alertTwoButtonModal}
                setAlertTwoButtonModal={setAlertTwoButtonModal}
              />
              <OfferHelp onRowClick={(p)=>{
                setOfferHelpStatus(false)
                setData({
                  ...p.row,
                  DATE: moment(p.row.DATE).format("YYYY-MM-DD"),
                  TDATE: moment(p.row.TDATE).format("YYYY-MM-DD"),
                  FDATE: moment(p.row.FDATE).format("YYYY-MM-DD"),
                })
              }} onClose={()=>setOfferHelpStatus(false)} status={offerHelpStatus}/>
            </div>
          </div>}

          {tabScreen ===2 && <div className="h-full px-3">
            
            {summaryList[0] ?    <div
            style={{ height:`calc(100% - 50px)`, width:'100%', marginTop: 20 }}
            className=" mb-2  overflow-y-auto "
          >


            <GeniusTableReports headerStyle={{
backgroundColor:'#ebe8e8',
color:'black',
borderTop:'1px solid black',
borderLeft:'1px solid black',
            }} onRowDoubleClick={(p)=>{
             setTabScreen(1)
               setData({
                ...p.original,
                DATE: moment(p.original.DATE).format("YYYY-MM-DD"),
                TDATE: moment(p.original.TDATE).format("YYYY-MM-DD"),
                FDATE: moment(p.original.FDATE).format("YYYY-MM-DD"),
              })
            }}  data={summaryList} columns={columns} />
 

          </div> : <div className='h-full flex justify-center items-center text-sm text-gray-400 border mt-3 rounded-sm'>
            No Records Found
            </div>}
            
            </div>}
  
        </div>
      </div>
    </div>
  );
};

export default OfferMaster;
