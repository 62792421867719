import { setAlertWithTitle, setSpinnerLoading } from "../utility/Redux/modal";
import checkLastPurchasePrice from "./common";
import FirstIcodeItem from "./icodeEnterValue";
import uniqeid from "uniqid";
import moment from "moment";

export function enterRowWithItemDetails(filteredItems, rows, id, prefix, partyDetails, token, dispatch, setLastPrice, setRows, value, setAlertTwoButtonModal, setItemMasterStatus) {
    if (filteredItems[0] ) {
      const isSameIdExist = rows.filter((row) => row.id === id);
  
      if (isSameIdExist[0]) {
        checkLastPurchasePrice(prefix, partyDetails.NAME, filteredItems[0].ICODE, filteredItems[0].PUR_PRICE, token, dispatch).then((data) => {
          const difrent = data[0] ? Number(data[0].PUR_PRICE) - Number(filteredItems[0].PUR_PRICE) : 0;
          const lastPrice = data[0] ? data[0].PUR_PRICE : 0;
          setLastPrice(lastPrice);
  
          if (difrent !== 0) {
            dispatch(setAlertWithTitle({
              title: 'Alert',
              msg: `The current purchase price ${filteredItems[0].PUR_PRICE} differs from the last purchase price ${lastPrice}.`
            }));
          }
          setRows((old) => {
            const list = old.map((item) => {
              if (item.id === id) {
                const qnty = 1;
                const pur_price = filteredItems[0].PUR_PRICE;
                const diq_rate = 0;
                const diq_amt = 0;
                const tax1_rate = filteredItems[0].TAX1_RATE;
                const tax2_rate = filteredItems[0].TAX2_RATE;
                const tax3_rate = filteredItems[0].TAX3_RATE;
                const vat_rate = 0;
                const tcs_rate = 0;
                const surcharge_rate = 0;
  
                const EXP_DATE = "";
  
                const i = FirstIcodeItem(
                  id,
                  qnty,
                  pur_price,
                  diq_rate,
                  diq_amt,
                  tax1_rate,
                  tax2_rate,
                  tax3_rate,
                  vat_rate,
                  tcs_rate,
                  surcharge_rate,
  
                  partyDetails,
                  filteredItems[0],
                  EXP_DATE, value
                );
  
                return i;
              } else {
                return item;
              }
            });
            const isEmptyRow = list.filter((r) => r.ITEMNAME === "");
            if (isEmptyRow[0]) {
              return [...list];
            } else {
              return [
                ...list,
                {
                  id: uniqeid(),
                  INVNO: "",
                  DATE: moment().format("YYYY-MM-DD"),
                  PUR_BILL_NO: "",
                  PUR_BILL_DATE: moment().format("YYYY-MM-DD"),
                  PUR_BILL_AMOUNT: "0",
                  ENT_TIME: moment().format("YYYY-MM-DD"),
                  BILL_MODE: rows[0].BILL_MODE,
                  BILL_TYPE: rows[0].BILL_TYPE,
                  ACCOUNT_NO: "",
                  NAME: "",
                  ADDRESS: "",
                  MOBILE: "",
                  TAX_NUMBER: "",
                  ICODE: "",
                  ITEMNAME: "",
                  PACKING: "",
                  UNIT: "",
                  SECTION_NAME: "",
                  SUB_SEC_NAME: "",
                  BRAND_NAME: "",
                  PUR_PRICE: "0",
                  MRP_RATE: "0",
                  SALE_PRICE: "0",
                  QNTY: "0",
                  AMOUNT: "0",
                  DISQ_RT: "0",
                  DISQ_AMT: "0",
                  TAX1_RATE: "0",
                  TAX1_AMT: "0",
                  TAX2_RATE: "0",
                  TAX2_AMT: "0",
                  TAX3_RATE: "0",
                  TAX3_AMT: "0.00",
                  NET_AMT: "0.00",
                  BASIC_RATE: "0.00",
                  BASIC_AMT: "0.00",
                  TOTAL: "0",
                  FREIGHT: "0.00",
                  REMARKS: "",
                  ROUND_OFF: "0",
                  GTOTAL: "0",
                  HSN_CODE: "",
                  GR_NO: "0",
                  PO_NO: "0",
                  INCL_EXCL: "Excluded",
                  CESS_RATE: "0",
                  CESS_AMOUNT: "0",
                  TCS_RATE: "0",
                  TCS_AMOUNT: "0",
                  ADDITIONAL_CESS: "0",
                  VAT_AMOUNT: "0",
                  SURCHARGE_RATE: "0",
                  SURCHARGE_AMT: "0.00",
                  VAT_RATE: "0",
                  SCHEME_RATE: "0",
                  SCHEME_AMOUNT: "0.00",
                  TOTAL_SCHEME_AMOUNT: "0.00",
                  ADD_CHARGES: "0.00",
                  LESS_CHARGES: "0.00",
                  INCL_TAX: "No",
                  DEP_NAME: "",
                  PURCHASE_PIC: "",
                  EXP_DATE: "",
                  BATCH: "",
                  MARK_RATE:0,
                  FREE_QNTY:0,
                  
                },
              ];
            }
          });
        });
  
  
      } else {
        checkLastPurchasePrice(prefix, partyDetails.NAME, filteredItems[0].ICODE, filteredItems[0].PUR_PRICE, token, dispatch).then(((data) => {
          const difrent = data[0] ? Number(data[0].PUR_PRICE) - Number(filteredItems[0].PUR_PRICE) : 0;
          const lastPrice = data[0] ? data[0].PUR_PRICE : 0;
          setLastPrice(lastPrice);
  
          if (difrent !== 0) {
            dispatch(setAlertWithTitle({
              title: 'Alert',
              msg: `The current purchase price ${filteredItems[0].PUR_PRICE} differs from the last purchase price ${lastPrice}.`
            }));
          }
          setRows((old) => {
            const qnty = 1;
            const pur_price = filteredItems[0].PUR_PRICE;
            const diq_rate = 0;
            const diq_amt = 0;
            const tax1_rate = filteredItems[0].TAX1_RATE;
            const tax2_rate = filteredItems[0].TAX2_RATE;
            const tax3_rate = filteredItems[0].TAX3_RATE;
            const vat_rate = 0;
            const tcs_rate = 0;
            const surcharge_rate = 0;
  
            const EXP_DATE = "";
  
            const i = FirstIcodeItem(
                uniqeid(),
              qnty,
              pur_price,
              diq_rate,
              diq_amt,
              tax1_rate,
              tax2_rate,
              tax3_rate,
              vat_rate,
              tcs_rate,
              surcharge_rate,
  
              partyDetails,
              filteredItems[0],
              EXP_DATE, value
            );
            return [
              ...old,
              {
                ...i,
              },
              {
                id: uniqeid(),
                INVNO: "",
                DATE: moment().format("YYYY-MM-DD"),
                PUR_BILL_NO: "",
                PUR_BILL_DATE: moment().format("YYYY-MM-DD"),
                PUR_BILL_AMOUNT: "0",
                ENT_TIME: moment().format("YYYY-MM-DD"),
                BILL_MODE: rows[0].BILL_MODE,
                BILL_TYPE: rows[0].BILL_TYPE,
                ACCOUNT_NO: "",
                NAME: "",
                ADDRESS: "",
                MOBILE: "",
                TAX_NUMBER: "",
                ICODE: "",
                ITEMNAME: "",
                PACKING: "",
                UNIT: "",
                SECTION_NAME: "",
                SUB_SEC_NAME: "",
                BRAND_NAME: "",
                PUR_PRICE: "0",
                MRP_RATE: "0",
                SALE_PRICE: "0",
                QNTY: "0",
                AMOUNT: "0",
                DISQ_RT: "0",
                DISQ_AMT: "0",
                TAX1_RATE: "0",
                TAX1_AMT: "0",
                TAX2_RATE: "0",
                TAX2_AMT: "0",
                TAX3_RATE: "0",
                TAX3_AMT: "0.00",
                NET_AMT: "0.00",
                BASIC_RATE: "0.00",
                BASIC_AMT: "0.00",
                TOTAL: "0",
                FREIGHT: "0.00",
                REMARKS: "",
                ROUND_OFF: "0",
                GTOTAL: "0",
                HSN_CODE: "",
                GR_NO: "0",
                PO_NO: "0",
                INCL_EXCL: "Excluded",
                CESS_RATE: "0",
                CESS_AMOUNT: "0",
                TCS_RATE: "0",
                TCS_AMOUNT: "0",
                ADDITIONAL_CESS: "0",
                VAT_AMOUNT: "0",
                SURCHARGE_RATE: "0",
                SURCHARGE_AMT: "0.00",
                VAT_RATE: "0",
                SCHEME_RATE: "0",
                SCHEME_AMOUNT: "0.00",
                TOTAL_SCHEME_AMOUNT: "0.00",
                ADD_CHARGES: "0.00",
                LESS_CHARGES: "0.00",
                INCL_TAX: "No",
                DEP_NAME: "",
                PURCHASE_PIC: "",
                EXP_DATE: "",
                BATCH: "",
                MARK_RATE:0,
                FREE_QNTY:0
              },
            ];
          });
        }));
  
  
      }
  
  
  
  
  
  
      // }
    } else {
        setAlertTwoButtonModal({
          status: true,
          title: "Confirmation",
          msg: "Item Not Found . Do you want to create the Item?",
          button1: "Yes",
          button1Click: () => {
            dispatch(setSpinnerLoading(false));
            // const nearestInput = document.getElementById(
            //   id + "-" +'ICODE'
            // );
            // if (nearestInput) {
            //   nearestInput.focus();
            //   nearestInput.select();
            // }
            setItemMasterStatus(true);
            setAlertTwoButtonModal({ status: false });
          },
          button2: "No",
          button2Click: () => {
    
    
    
            const nearestInput = document.getElementById(
              id + "-" + 'ICODE'
            );
            if (nearestInput) {
              nearestInput.focus();
              nearestInput.select();
            }
            dispatch(setSpinnerLoading(false));
            setAlertTwoButtonModal({ status: false });
          },
        });
    
    }
  }