import moment from "moment";
import { FormatNumber2 } from "../../utility/functions/formatNumber";

export const VoucherPrintColumns = (width) => {
    const columns = [
      {
        field: 'VOUCHER_NO',
        accessor: 'VOUCHER_NO',

        type: 'string',
        headerAlign: 'center',
        width: width < 768 ? 150 : width < 1400 ? 80 : 60,
        headerClassName: 'super-app-theme--header',
  
        renderCell: (param) => {
          return (
            <div
              className={`h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Voucher'}{' '}
          </div>
        ),
      },

      {
        field: 'DATE',
        accessor: 'DATE',
        type: 'string',
        type:'date',
        headerAlign: 'center',
        width: width < 768 ? 100 : 80,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => {
          return (
            <div
              className={`h-full w-full flex items-center  `}
            >
              {moment(params.value).format('YYYY-MM-DD')}{' '}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Date'}{' '}
          </div>
        ),
      },
      {
        field: 'ACCOUNT_NO',
        accessor: 'ACCOUNT_NO',
        type: 'string',
        width: width < 768 ? 100 : 60,
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Acc. No.'}{' '}
          </div>
        ),
      },
      {
        field: 'NAME',
        accessor: 'NAME',
        type: 'string',
        width: 90 ,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Party Name
          </div>
        ),
      },
      {
        field: 'MOBILE',
        accessor: 'MOBILE',
        type: 'number',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 130,
        headerAlign: 'center',
        align: 'left',
  
        renderHeader: (params) => (
          <div
            className="text-white  whitespace-normal"
            style={{ fontSize: 13 }}
          >
            {' '}
            Mobile
          </div>
        ),
      },
      {
        field: 'CUSTOMER_NAME',
        accessor: 'CUSTOMER_NAME',
        type: 'string',
        width:170,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Customer Name
          </div>
        ),
      },
      {
        field: 'CUSTOMER_MOBILE',
        accessor: 'CUSTOMER_MOBILE',
        type: 'string',
        width: width < 768 ? 100 : width < 1400 ? 180 : 120,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Customer Mobile
          </div>
        ),
      },
  
     
  
  
  
  
  
      {
        field: 'DEBIT',
        accessor: 'DEBIT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 70,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
            Debit
          </div>
        ),
      },
      {
        field: 'CREDIT',
        accessor: 'CREDIT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 70,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0" ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
Credit
          </div>
        ),
      },
      {
        field: 'EXPENSE_NAME',
        accessor: 'EXPENSE_NAME',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 160,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
     Expense Name
          </div>
        ),
      },
      {
        field: 'DESC_NAME',
        accessor: 'DESC_NAME',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 160,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
   Description
          </div>
        ),
      },   {
        field: 'PAY_MODE',
        accessor: 'PAY_MODE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 80,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value}
            </div>
          );
        },
        headerAlign: 'center',
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
        Paymode
          </div>
        ),
      },
      {
        field: 'VOUCHER_TYPE',
        accessor: 'VOUCHER_TYPE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 120,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value }
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
    Voucher Type
          </div>
        ),
      },
      {
        field: 'REMARKS',
        accessor: 'REMARKS',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 150,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
       Remarks
          </div>
        ),
      },
    
    ];
    return columns;
  };