import React, { useEffect, useRef, useState } from "react";
import ItemDetails from "./itemDetails";
import ItemPrice from "./itemPrice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ProfilePicFrame from "../../utility/component/styles/profilePicFrame";
import UploadImageButtons from "../../utility/component/UploadImageButtons";
import ModalHeaderStyle from "../../utility/component/styles/modalHeader";
import { selectPrefix, selectWidth } from "../../utility/Redux/profile";
import { selectToken } from "../../utility/Redux/security";
import { useNavigate } from "react-router-dom";
import { ItemMasterHelp } from "../../help";
import { OnSubmit } from "./func";
import SectionMasterHelp from "../../help/sectionMasterHelp";
import SubSecMasterHelp from "../../help/subSectionMasterJHelp";
import DepartmentMasterHelp from "../../help/departmentHelp";
import { DownloadFile, UploadFile } from "../../utility/functions/firebase";
import HsnMasterHelp from "../../help/hsnMasterHelp";
import axios from "axios";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import { toProperCase } from "../../utility/component/format";
import { Close } from "@mui/icons-material";
import { Fade, Skeleton } from "@mui/material";
import ItemBox2 from "./itemBox";
import UploadImageModal from "./uploadImg";
import HandleIndexedDB from "../../Test/handleIndexDb";
import AlertModalTwoButton from "../../utility/component/modals/alertWithTwoButton";
import deleteWholeDatabase from "../../utility/functions/deleteWholeDatabase";
import dataToLocal from "../../utility/component/dataToLocal";
import IsLocalData from "../../utility/component/isLocalData";

const ItemMaster = ({
  handleClose,
  refreshItem,
  setRows,
  rows,
  setItemMasterStatus,
}) => {
  const [sectionMasterhelpStatus, setSectionMaterHelpStatus] = useState(false);
  const [subSectionMasterhelpStatus, setSubSectionMaterHelpStatus] =
    useState(false);
  const [departMentMasterHelpStatus, setDepartmentMasterHelpStatus] =
    useState(false);
  const  [itemImgList,setItemImgList] = useState([])
  const [refreshItems,setRefreshItems] = useState(false);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const [HsnMasterHelpStatus, setHsnMasterHelpStatus] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [ListsectionName, setListSecname] = useState("");
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [Path, setpath] = useState("");
  const navigate = useNavigate();
  const [uploadImgStatus,setUploadImghStatus]= useState(false)
  const [uploadImgCode,setUploadImghCode] = useState('')
  const [tabScreen, setTabScreen] = useState(1);
  const [itemImgListIndexDb,setItemImgListIndexDb] = useState([])
  const [selectedItem,setSelecteditem] = useState('')
  const [filterList,setFilteredList] = useState([])
  const [searchText,setSearchText] = useState('')
  const [data, setdata] = useState({
    table_prefix: prefix,
    ICODE: "",
    ALIAS_CODE: "",
    HEAD_CODE: "",
    ITEMNAME: "",
    ITEM_DESC: "",
    SECTION_NAME: "",
    SUB_SEC_NAME: "",
    UNIT: "",
    PACKING: "",
    BRAND_NAME: "",
    PUR_PRICE: "",
    SALE_PRICE: "",
    MRP_RATE: "",
    MIN_STOCK: "",
    MAX_STOCK: "",
    TAX1_RATE: "",
    TAX2_RATE: "",
    TAX3_RATE: "",
    OPBAL: "",
    OPBAL_DATE: moment().format("YYYY-MM-DD"),
    MARK_RATE: "",
    HSN_CODE: "",
    DISQ_YN: "",
    DISQ_RATE: "",
    STOP_YN: "No",
    STOCKYN: "",
    CESS_RATE: "",
    OPBAL_RATE: "",
    OPBAL_AMOUNT: "",
    PRINT_ORDER_SLIP: "No",
    WHOLE_SALE_PRICE: "",
    GIFT_PACK: "No",
    DEP_NAME: "",
    NON_MOVING: "",
    ITEM_TYPE: "",
    AISLE: "",
    SHELF: "",
    BAY: "",
  });
 const [firstTabWidth,setFirstTabWidth] = useState('')
  const [customerFirebasePic, setFirebaseUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [webcamStatus, setWebcamStatus] = useState("");
  const [capturedImage, setCapturedImage] = useState("");
  const [itemList, setItemList] = useState([]);
  const webcamRef = useRef(null);
  const [itemMasterHelpStatus, setItemMasterHelpStatus] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    if (formRef) {
      const inputFields = formRef.current.querySelectorAll("input, select");
      const f = (event, index) => {
        if (event.key === "Enter") {
          event.preventDefault(); // Prevent form submission
          const nextIndex = (index + 1) % inputFields.length;
          if (nextIndex === 3) {
            if (data.ITEMNAME) {
              inputFields[nextIndex].focus();
            }
          } else {
            if (nextIndex === 5) {
              // if(data.SECTION_NAME){
              //   inputFields[nextIndex].focus();
              // }else{
              //   setSectionMaterHelpStatus(true)
              // }
            } else {
              if (nextIndex === 6) {
                // if(data.SUB_SEC_NAME){
                //   inputFields[nextIndex].focus();
                // }else{
                //   setSubSectionMaterHelpStatus(true)
                // }
              } else {
                if (nextIndex === 7) {
                } else {
                  inputFields[nextIndex].focus();
                }
              }
            }
          }
        }
      };

      // Add a keydown event listener to each input and select field
      inputFields.forEach((field, index) => {
        field.addEventListener("keydown", (e) => {
          f(e, index);
        });
      });

      // Cleanup event listeners on unmount
      return () => {
        inputFields.forEach((field, index) => {
          field.removeEventListener("keydown", (e) => {
            f(e, index);
          });
        });
      };
    }
  }, [data.SECTION_NAME, data.ITEMNAME]);

  useEffect(() => {
    function handleKeyPress(event) {
      // Check if Ctrl or Alt key is pressed along with 'S'
      if ((event.ctrlKey || event.altKey) && event.key === "s") {
        // Call your function here
        OnSubmit(
          data,
          token,
          dispatch,
          formRef,
          setdata,
          capturedImage,
          selectedFile,
          setSelectedFile,
          setCapturedImage,
          setFirebaseUrl,
          setRows,
          rows,
          setItemMasterStatus,
          getData
        );
      }
    }

    // Add event listener when component mounts
    window.addEventListener("keydown", handleKeyPress);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [data, capturedImage, selectedFile]);

  const windowSize = useSelector(selectWidth);

  const getSectionList = () => {
    deleteWholeDatabase('sectionList')
    axios
      .post(
        "/api/master/listSectionMaster",
        {
          table_prefix: prefix,
          SECTION_NAME: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSectionList(res.data.response);
        if(res.data.response[0]){
          dataToLocal('sectionList', res.data.response, [
            {
       
             ICODE:1
            },
          ]);  
        }
      })
      .catch((e) => {
        if (e.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      });
  };

  const getData = async (s) => {
    dispatch(setSpinnerLoading("Loading"));
    deleteWholeDatabase('itemsList')
    await axios
      .post(
        "/api/master/listItemMaster",
        {
          table_prefix: prefix,
          SECTION_NAME: s ? s : "",
              "ICODE": "",
    "ALIAS_CODE": "",
    ITEMNAME:"",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        setItemList(p.data.response);
        setFilteredList(p.data.response);
        if(p.data.response[0]){
          dataToLocal('itemsList', p.data.response, [
            {
       
             ICODE:1
            },
          ]);  
        }
        getSectionList();
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(()=>{
    const callback = (details, data) => {
      if(data[0]){
        setItemList(data);
        setFilteredList(data);
      }
    
    };
    const elseFunc =()=>{        getData("")}
    IsLocalData('itemsList', callback, elseFunc);
  },[])

  useEffect(()=>{
    const callback = (details, data) => {
      if(data[0]){
        setSectionList(data);
      }
    
    };
    const elseFunc =()=>{        getData("")}
    IsLocalData('sectionList', callback, elseFunc);
  },[])
  useEffect(() => {
    
    if (searchText) {

      const regex = /\d+/;

      const searchItems = filterList.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.ICODE.toString().includes(searchText) ;
        } else {
          return item.ITEMNAME.toLowerCase().includes(
            searchText.toLowerCase()
          );
        }
      });
      setItemList(searchItems);
    } else {
      setItemList(filterList);
    }
  }, [searchText]);
  const firstTabWidthRef = useRef()

  useEffect(()=>{
    const databaseName = 'ItemsImages';
    const storeName = 'images';

    HandleIndexedDB({
      databaseName,
      storeName,
      callback: (data) => {
        if (data[0]) {
      setItemImgList(data)
        }
      },
    });
  },[])

  useEffect(()=>{
if(firstTabWidthRef){
  if(firstTabWidthRef.current){
    setFirstTabWidth(firstTabWidthRef.current?.offsetWidth)
  }
}
  },[firstTabWidthRef,firstTabWidthRef.current?.offsetWidth])
  return (
    <div
      ref={formRef}
      className="w-full  flex justify-center bg-[#f5f5f7] h-full px-2 "
    >
      <div className="text-sm   rounded-sm   flex flex-col  justify-center h-full ">
        <div>
          <ModalHeaderStyle
            header={"Item Master"}
            onClose={() => {
              if (handleClose) {
                handleClose();
              } else {
                navigate("/masters");
              }
            }}
          />
        </div>

        <div
          style={{
            height: `calc(100% - 80px)`,
          }}
          className="   rounded-b-sm bg-white flex flex-col p-2"
        >
          <div className="h-full flex flex-col  ">
            <div
              
              className=" w-full  rounded-sm flex "
            >
              <div
                onClick={() => {
                  setTabScreen(1);
                }}
                style={{
                  color: tabScreen === 1 ? "white" : "gray",
                }}
                className={`flex-1 p-2 ${
                  tabScreen === 1
                    ? "bg-[#0d0d0d] "
                    : " hover:bg-[#f1f5f9]  bg-[#ebe8e8] hover:cursor-pointer "
                }  flex justify-center items-center rounded-l-sm `}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: tabScreen === 1 ? 600 : 400,
                  }}
                  className=" h-full flex items-center"
                >
                  Master
                </div>
              </div>
              <div
                style={{
                  color: tabScreen === 2 ? "white" : "gray",
                }}
                onClick={() => {
                  setTabScreen(2);
                }}
                className={`flex-1 p-2 ${
                  tabScreen === 2
                    ? "bg-[#0d0d0d] "
                    : " hover:bg-[#f1f5f9] bg-[#ebe8e8] hover:cursor-pointer "
                }  flex justify-center items-center rounded-r-sm `}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: tabScreen === 2 ? 600 : 400,
                  }}
                  className="  flex items-center"
                >
                  Item List
                </div>
              </div>
            </div>

            <div className=" flex flex-col overflow-y-auto mt-4 overflow-x-hidden ">
              <div className="h-full">
                {tabScreen === 1 && (
                  <div
                    ref={firstTabWidthRef}
                    className=" h-full text-black   flex-col l:flex l:flex-row p-4 pt-0   "
                  >
                    <div className="h-fit  ">
                      <ItemDetails
                        itemList={itemList}
                        formRef={formRef}
                        setFirebaseUrl={setFirebaseUrl}
                        setpath={setpath}
                        setDepartmentMasterHelpStatus={
                          setDepartmentMasterHelpStatus
                        }
                        setAlertTwoButtonModal={setAlertTwoButtonModal}
                        setSubSectionMaterHelpStatus={
                          setSubSectionMaterHelpStatus
                        }
                        setSectionMaterHelpStatus={setSectionMaterHelpStatus}
                        setItemMasterHelpStatus={setItemMasterHelpStatus}
                        data={data}
                        setdata={setdata}
                      />
                    </div>

                    <div className=" l:ml-4 ">
                      <ItemPrice
                        undo={() => {
                          getData("");
                    setFirebaseUrl('')
                    setSelectedFile('')
                          setdata({
                            table_prefix: data.table_prefix,
                            ICODE: "",
                            ALIAS_CODE: "",
                            HEAD_CODE: "",
                            ITEMNAME: "",
                            ITEM_DESC: "",
                            SECTION_NAME: "",
                            SUB_SEC_NAME: "",
                            UNIT: "",
                            PACKING: "",
                            BRAND_NAME: "",
                            PUR_PRICE: "",
                            SALE_PRICE: "",
                            MRP_RATE: "",
                            MIN_STOCK: "",
                            MAX_STOCK: "",
                            TAX1_RATE: "",
                            TAX2_RATE: "",
                            TAX3_RATE: "",
                            OPBAL: "",
                            OPBAL_DATE: moment().format("YYYY-MM-DD"),
                            MARK_RATE: "",
                            HSN_CODE: "",
                            DISQ_YN: "",
                            DISQ_RATE: "",
                            STOP_YN: "",
                            STOCKYN: "",
                            CESS_RATE: "",
                            OPBAL_RATE: "",
                            OPBAL_AMOUNT: "",
                            PRINT_ORDER_SLIP: "No",
                            WHOLE_SALE_PRICE: "",
                            GIFT_PACK: "No",
                            DEP_NAME: "",
                            NON_MOVING: "",
                            ITEM_TYPE: "",
                            AISLE: "",
                            SHELF: "",
                            BAY: "",
                          });
                        }}
                        setHsnMasterHelpStatus={setHsnMasterHelpStatus}
                        data={data}
                        setdata={setdata}
                        onSubmit={() => {
                          OnSubmit(
                            data,
                            token,
                            dispatch,
                            formRef,
                            setdata,
                            capturedImage,
                            selectedFile,
                            setSelectedFile,
                            setCapturedImage,
                            setFirebaseUrl,
                            setRows,
                            rows,
                            setItemMasterStatus,
                            getData
                          );
                        }}
                      />
                    </div>

                    <div className="flex justify-center">
                      <div className=" l:ml-4  ">
                        <div
                          style={{
                            border: "1px solid #b5b3b3",
                            width: "150px",
                            height: "150px",
                          }}
                          className=" p-2 rounded-sm mt-3  "
                        >
                          <ProfilePicFrame
                            customerFirebasePic={customerFirebasePic}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            webcamStatus={webcamStatus}
                            setWebcamStatus={setWebcamStatus}
                            capturedImage={capturedImage}
                            setCapturedImage={setCapturedImage}
                            webcamRef={webcamRef}
                            path={Path}
                            setFirebaseUrl={setFirebaseUrl}
                          />
                        </div>
                        <div
                          className="flex mt-2"
                          style={{
                            width: "150px",
                          }}
                        >
                          <UploadImageButtons
                            title={"Upload Image"}
                            setSelectedFile={setSelectedFile}
                            webcamStatus={webcamStatus}
                            setWebcamStatus={setWebcamStatus}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {tabScreen === 2 && (
                  <div
                    style={{
                      width: firstTabWidth
                    }}
                    className="  min-w-[340px] bg-white  px-3 py-3 pt-3 "
                  >
                  <div className=" pl-2 ">
                  <div
          style={{
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            borderBottom: '1px solid #4a4948',
            borderTop: '1px solid #4a4948',
            fontSize: '14px',
          }}
          className="flex p-2 rounded-sm "
        >
          <i className="bi bi-search pr-2"></i>
          <input
          value={searchText}
            type={'text'}
            placeholder="Search"
      onChange={e =>setSearchText(e.target.value)}
        
            className="w-full outline-0 bg-white"
          />
        </div>
                    </div>
                    <div className=" flex mt-2" >

                    <div
                      style={{
                        height: `700px`,
                      }}
                      className=" overflow-y-scroll "
                    >
                      <div className="grid  gap-1 grid-cols-1">
                        {sectionList
                          ? sectionList.map((item, key) => (
                              <>
                                <div
                                  key={key}
                                  className="flex overflow-hidden mx-2"
                                  style={{
                                    border:
                                      item.SECTION_NAME === ListsectionName
                                        ? "2px solid #A8CF45"
                                        : "0px solid #A8CF45",
                                    height: "45px",
                                    boxShadow: "1px 1px 3px 0px #9F9C9C",
                                    borderRadius: "7px",
                                    textTransform: "none",
                                    backgroundColor: "#f5eb82",
                                    fontSize: "15px",
                                    color: "#4a4a4a",
                                  }}
                                >
                                  <button
                                    onClick={() => {
                                      getData(item.SECTION_NAME);
                                      setListSecname(item.SECTION_NAME);
                                    }}
                                    style={{
                                      width: `calc(100% - 20px)`,
                                    }}
                                    className="text-[#4a4a4a] px-2 text-[15px] text-start  font-semibold whitespace-nowrap "
                                  >
                                    {toProperCase(item.SECTION_NAME)}
                                  </button>
                                  <Fade
                                    orientation="horizontal"
                                    in={item.SECTION_NAME === ListsectionName}
                                  >
                                    <button
                                      style={{
                                        paddingRight: "5px",
                                        paddingLeft: "5px",
                                      }}
                                      className="bg-green h-full"
                                      onClick={() => {
                                        getData("");
                                        setListSecname("");
                                      }}
                                    >
                                      <Close
                                        color="white"
                                        fontSize={"small"}
                                        sx={{
                                          fontSize: "15px",
                                          color: "white",
                                        }}
                                      />
                                    </button>
                                  </Fade>
                                </div>
                              </>
                            ))
                          : [
                              "1",
                              "2",
                              "3",
                              "4",
                              "5",
                              "6",
                              "7",
                              "8",
                              "9",
                              "10",
                              "11",
                              "12",
                              "13",
                              "14",
                              "15",
                              "16",
                            ].map(() => {
                              return (
                                <Skeleton
                                  variant="rectangular"
                                  width={136}
                                  animation="wave"
                                  height={70}
                                  sx={{ fontSize: "1rem", borderRadius: "7px" }}
                                />
                              );
                            })}
                      </div>
                    </div>
                    <div
                      style={{
                        height: `700px`,

                      }}
                      className="   bg-white overflow-y-scroll  "
                    >
                      <div className="grid px-2  gap-2 grid-cols-1 l:grid-cols-3 ">
                        {itemList[0]
                          ? itemList.map((i, index) => {
                              return (
                                <ItemBox2
                                refreshItems={refreshItems}
                                  key={index}
                                  item={i}
                                  itemImgList={itemImgList}
                                  index={index}
                                  indexDbImgList={itemImgListIndexDb}
                                  itemNameStyle={{
                                    width: "100%",
                                    height: "70px",
                                    textTransform: "none",
                                    backgroundColor: "#fadb3f",
                                    borderRadius: "7px",
                                    color: "#4a4a4a",
                                    padding: "0px",
                                    fontSize: "12px",
                                  }}
                                  onClick={()=>{
setUploadImghCode(i.ICODE)
setUploadImghStatus(true)
setSelecteditem(i)
                                  }}
                                />
                              );
                            })
                          : null}
                      </div>
                    </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <ItemMasterHelp
          onRowClick={(e) => {
            const i = e.row;
            setdata({
              table_prefix: prefix,
              ICODE: i.ICODE,
              ALIAS_CODE: i.ALIAS_CODE,
              HEAD_CODE: i.HEAD_CODE,
              ITEMNAME: i.ITEMNAME,
              ITEM_DESC: i.ITEM_DESC,
              SECTION_NAME: i.SECTION_NAME,
              SUB_SEC_NAME: i.SUB_SEC_NAME,
              UNIT: i.UNIT,
              PACKING: i.PACKING,
              BRAND_NAME: i.BRAND_NAME,
              PUR_PRICE: i.PUR_PRICE,
              SALE_PRICE: i.SALE_PRICE,
              MRP_RATE: i.MRP_RATE,
              MIN_STOCK: i.MIN_STOCK,
              MAX_STOCK: i.MAX_STOCK,
              TAX1_RATE: i.TAX1_RATE,
              TAX2_RATE: i.TAX2_RATE,
              TAX3_RATE: i.TAX3_RATE,
              OPBAL: i.OPBAL,
              OPBAL_DATE: moment(i.OPBAL_DATE).format("YYYY-MM-DD"),
              MARK_RATE: i.MARK_RATE,
              HSN_CODE: i.HSN_CODE,
              DISQ_YN: i.DISQ_YN,
              DISQ_RATE: i.DISQ_RATE,
              STOP_YN: i.STOP_YN,
              STOCKYN: i.STOCKYN,
              CESS_RATE: i.CESS_RATE,
              OPBAL_RATE: i.OPBAL_RATE,
              OPBAL_AMOUNT: i.OPBAL_AMOUNT,
              PRINT_ORDER_SLIP: i.PRINT_ORDER_SLIP,
              WHOLE_SALE_PRICE: i.WHOLE_SALE_PRICE,
              GIFT_PACK: i.GIFT_PACK,
              DEP_NAME: i.DEP_NAME,
              NON_MOVING: i.NON_MOVING,
              ITEM_TYPE: i.ITEM_TYPE,
              AISLE: i.AISLE,
              SHELF: i.SHELF,
              BAY: i.BAY,
            });
            const icode = i.ICODE;
            const path = `retailx/items/${prefix + icode}`;
            console.log(path)
            DownloadFile(path)
              .then((e) => {
                if (!e.includes("FirebaseError")) {
                  setFirebaseUrl(e);
                  setpath(path);
                } else {
                  setpath("");

                  setFirebaseUrl("");
                }
              })
              .catch((e) => {
                // setCapturedImage('');
                setFirebaseUrl("");
              });
            setItemMasterHelpStatus(false);
          }}
          status={itemMasterHelpStatus}
          onClose={() => {
            setItemMasterHelpStatus(false);
          }}
        />
        <SectionMasterHelp
          onRowClick={(row) => {
            setSectionMaterHelpStatus(false);
            setdata((e) => {
              return {
                ...e,
                SECTION_NAME: row.row.SECTION_NAME,
              };
            });
          }}
          status={sectionMasterhelpStatus}
          onClose={() => {
            setSectionMaterHelpStatus(false);
          }}
        />
        <SubSecMasterHelp
          onRowClick={(row) => {
            setSubSectionMaterHelpStatus(false);
            setdata((e) => {
              return {
                ...e,
                SUB_SEC_NAME: row.row.SUB_SEC_NAME,
              };
            });
          }}
          status={subSectionMasterhelpStatus}
          onClose={() => {
            setSubSectionMaterHelpStatus(false);
          }}
        />

        <DepartmentMasterHelp
          onRowClick={(row) => {
            setDepartmentMasterHelpStatus(false);
            setdata((e) => {
              return {
                ...e,
                DEP_NAME: row.row.DEP_NAME,
              };
            });
          }}
          status={departMentMasterHelpStatus}
          onClose={() => {
            setDepartmentMasterHelpStatus(false);
          }}
        />

        <HsnMasterHelp
          onRowClick={(row) => {
            setHsnMasterHelpStatus(false);
            setdata((e) => {
              return {
                ...e,
                HSN_CODE: row.row.HSN_CODE,
                TAX2_RATE: row.row.TAX_RATE / 2,
                TAX1_RATE: row.row.TAX_RATE / 2,
                TAX3_RATE: row.row.TAX_RATE,
              };
            });
          }}
          status={HsnMasterHelpStatus}
          onClose={() => {
            setHsnMasterHelpStatus(false);
          }}
        />

{uploadImgStatus &&   <UploadImageModal setRefreshItems={setRefreshItems} setItemImgList={setItemImgList} setUploadImghStatus={setUploadImghStatus} selectedItem= {selectedItem}  selectedFile={selectedFile} setSelectedFile={setSelectedFile} onSubmit={()=>{
       if (capturedImage || selectedFile) {
      setUploadImghStatus(false)

        const camera = capturedImage
          ? fetch(capturedImage).then((response) =>
              response.blob()
            )
          : "";
        const file = selectedFile ? selectedFile : camera;
        const name =
          data.table_prefix + uploadImgCode
        
        const path = "retailx/items/";
        UploadFile(file, path, name);
        setItemImgList(o =>{
          return [
            ...o,
            {
              ICODE:uploadImgCode,
              img:selectedFile
            }
          ]
        })
        console.log(refreshItem)
        setRefreshItems(o => !o)
        setSelectedFile('')

      }else{
        dispatch(setAlertWithTitle({
          title:'Alert',
          msg:'Please select image to upload'
        }))
      }
}} onCancel={()=>{
  setUploadImghStatus(false)
  setSelectedFile('')
}} code={
          uploadImgCode
        } />}
     
      </div>
      <AlertModalTwoButton details={alertTwoButtonModal} setAlertTwoButtonModal={setAlertTwoButtonModal} />
      
    </div>
  );
};

export default ItemMaster;
