import { ExpandMoreOutlined } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';

const GeniusSelectWithTitle = ({country,notEditable,title,setValue, onClickOpen, width,onKeyDown, value, options, height, handleClick, onChange,placeholder ,type,id,fontStyle}) => {
    const [focus, setFocus] = useState(false);
    const [fullOption, setFullOption] = useState(options);
    const myDivRef = useRef(null);
    const [selectOpen, setSelectOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
  const [parmanentText,setParmanentText] = useState('');
    const handleOptionClick = (optionValue) => {
        setSelectOpen(false); // Close the dropdown after selecting an option
        setFocus(false); // Reset focus
        setParmanentText('')
        if (handleClick) {
            handleClick(optionValue);
        }
    };

    const handleKeyDown = (e) => {
        if(selectOpen){
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, fullOption.length - 1));
                scrollOptionIntoView();
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
                scrollOptionIntoView();
            } else if (e.key === 'Enter') {
                setSelectOpen(false); // Close the dropdown after selecting an option
                setFocus(false); // Reset focus
                if(fullOption[0]){
                    handleOptionClick(fullOption[selectedIndex ]?.value ? fullOption[selectedIndex ]?.value :fullOption[selectedIndex ]?.name);
    
                }else{
                    onKeyDown(e)
                }
            }
        }else{

            onKeyDown(e)

        }
     
    };

    const scrollOptionIntoView = () => {
        const selectedOption = document.querySelector('.selected-option');
        if (selectedOption) {
            selectedOption.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (myDivRef.current && !myDivRef.current.contains(event.target)) {
                setSelectOpen(false); // Close the dropdown after selecting an option
                setFocus(false); // Reset focus
            }
        }

        // Attach event listener when component mounts
        document.addEventListener('click', handleClickOutside);

        // Detach event listener when component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    useEffect(()=>{

       if(parmanentText) {
        setValue(parmanentText)

        setFullOption(r => options.filter( d => d.value ? d.value.toLowerCase().toString().includes(parmanentText.toLowerCase().toString()) : d.name.toLowerCase().toString().includes(parmanentText.toLowerCase().toString()) ) )

       }else{
  setFullOption(options)
  setParmanentText('')

       }
    },[parmanentText])

    useEffect(()=>{
        setFullOption(options)
    },[options])
    return (
        <div ref={myDivRef} className="text-sm pb-2">
            {title && <div style={{
                fontWeight:  500,
            }} className='font-semibold text-[16px]'> {title} </div>}
            
            <div
                style={{
                    width: width,
                    border: `${focus ? '1px solid #43bff7' : '1px solid #A09E9E'}`,
                    borderBottom: `${
                        focus && !selectOpen ? '1px solid #43bff7' : focus && selectOpen ? '0px solid #43bff7' : '1px solid #A09E9E'
                    }`,
                    borderTopRightRadius: selectOpen ? '7px' : '7px',
                    borderTopLeftRadius: selectOpen ? '7px' : '7px',
                    borderBottomLeftRadius: selectOpen ? '0px' : '7px',
                    borderBottomRightRadius: selectOpen ? '0px' : '7px',
                }}
                onClick={() => {
                    if (fullOption[0]) {
                        setSelectOpen((e) => !e);
                    } else {
                        setSelectOpen((e) => false);
                    }
                    setFocus((e) => true);
                }}
                className="flex bg-white py-1 pl-2 overflow-hidden items-center"
            >
                <input
                    onKeyDown={handleKeyDown}
                    id={id?id:""}
                    style={fontStyle}
                   
                    readOnly={notEditable ? true : false}
                    onBlur={() => {
                        setFocus(false);
                    }}
                    onChange={(e)=>{
                        setParmanentText(e.target.value.toUpperCase())
                    }}
                    type={type?type :'text'}
                    value={parmanentText ? parmanentText : value}
                    placeholder={placeholder}
                    className="outline-none w-full p-1 placeholder:font-normal"
                   
                />
                <ExpandMoreOutlined color='black' onClick={() =>
                        setSelectOpen((e) => {
                            setFocus(true);
                            return !e;
                        })}  fontSize={'small'} />
                {/* <i
                    onClick={() =>
                        setSelectOpen((e) => {
                            setFocus(true);
                            return !e;
                        })
                    }
                    className="bi p-2 bi-chevron-down text-[10px] font-semibold text black"
                ></i> */}
            </div>

<div>
{selectOpen && (
                <div
                    style={{
                        border: '1px solid #43bff7',
                        borderTop: '0px solid #43bff7',
                        width,
                        height,
                        borderBottomLeftRadius: '7px',
                        borderBottomRightRadius: '7px',
                    }}
                    className="absolute  bg-white overflow-hidden"
                >
                    <div className="overflow-y-scroll h-full">
                        {fullOption.map((e, k) => {
                            return (
                                <div
                                    onClick={() => handleOptionClick(e.value ? e.value :e.name)}
                                    key={k}
                                    className={`w-full p-1 text-start hover:bg-brown hover:text-white ${
                                        selectedIndex === k ? 'bg-brown selected-option text-white' : ''
                                    }`}
                                >
                                    {country && <span class={`fi fi-${e.isoCode.toLowerCase()} mr-2`}></span> }
                                    {e.value ?e.value : e.name}{' '}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
</div>
         
        </div>
    );
};

export default GeniusSelectWithTitle;
