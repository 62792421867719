import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrefix, selectWidth } from '../../utility/Redux/profile';
import { selectToken } from '../../utility/Redux/security';
import { setSpinnerLoading } from '../../utility/Redux/modal';
import CallAPI from '../../utility/functions/getData';
import GeniustextInput from '../../utility/component/mastertext';
import GeniusDatagrid from '../../utility/component/datagrid/geniusDatagrid';
import { PartyColumns } from './columns';
const PartyList = ({
  stationId,
  stationName,
  fullList,
  setFullList,
  parties,
  setParties,
  setStationId,
  setStationName,
}) => {
  const prefix = useSelector(selectPrefix);
  const windowWidth = useSelector(selectWidth);
  const [searchText, setSearchText] = useState('');

const columns = PartyColumns(windowWidth)

  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const getAccountData = () => {
    dispatch(setSpinnerLoading('Loading'));
    CallAPI(
      `/api/master/listAccountMaster`,
      {
        // table_prefix: prefix,
        table_prefix: prefix,
        mobile: '',
        name: '',
      },
      token,
      (e) => {
        if (!e.data.error) {
          setParties(e.data.response);
          setFullList(e.data.response);
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };

  useEffect(() => {
    if (!fullList[0]) {
      getAccountData();
    }
  }, [stationId]);

  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.MOBILE.toString().includes(searchText);
        } else {
          return item.NAME.toLowerCase().includes(searchText.toLowerCase());
        }
      });

      setParties(searchItems);
    } else {
      setParties(fullList);
    }
  }, [searchText]);
  return (
    <div style={{
        width:columns.reduce((acc, row) => acc + Number(row.width), 0)+5,
          height: windowWidth <= 1280 ? '410px' : '450px',

    }} className="overflow-y-auto px-2 min-w-[320px] ">
    

      <div
        style={{
          height:`calc(100% - 80px)`
        }}
        className="mt-3 "
      >
        <GeniusDatagrid
          rowID={(r) => r.ACCOUNT_NO}
          list={parties}
          columns={columns}
          dataGridHeader={true}
          showPagination={true}
        />
      </div>
      <div
        style={{
          width: '100%',
          border: '1px solid #D2D2D7',
        }}
        className="rounded-sm p-1 mt-3 flex items-center justify-between  pl-2"
      >
        {' '}
        <div className=" ">
          <input
            type="text"
            value={searchText}
            className="w-full"
            style={{
              outline: 'none ',
            }}
            placeholder="Search"
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
        <div className="text-sm pr-3 text-gray-500">
          Records : {parties.length}
        </div>
      </div>
    </div>
  );
};

export default PartyList;
