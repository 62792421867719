import moment from "moment";
import { FormatNumber2 } from "../../utility/functions/formatNumber";

export const PurchaseSummaryColumns = (width) => {
  const columns = [
    {
      field: "DATE",
      accessor: "DATE",
      type: "date",
      headerAlign: "center",

      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            className={` h-full w-full flex items-center  `}
          >
            {params.value ? moment(params.value).format('YYYY-MM-DD'): ""}
           
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
     Date
        </div>
      ),
    },


    {
      field: "NAME",
      accessor: "NAME",
      type: "string",
      headerAlign: "center",

      width: width < 768 ? 100 : width < 1400 ? 180 : 130,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            className={` h-full w-full flex items-center  `}
          >
            {params.value ? params.value: ""}
           
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
     Party
        </div>
      ),
    },
    {
      field: "MOBILE",
      accessor: "MOBILE",
      type: "string",
      headerAlign: "center",

      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            className={` h-full w-full flex items-center  `}
          >
            {params.value ? params.value: ""}
           
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
     Mobile
        </div>
      ),
    },
    {
      field: "ICODE",
      accessor: "ICODE",
      type: "string",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
  Code
        </div>
      ),
    },



    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      type: "number",
      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      headerClassName: "super-app-theme--header",
      width:  180,
      headerAlign: "center",
      align: "left",

      renderHeader: (params) => (
        <div className="text-white  whitespace-normal" style={{ fontSize: 13 }}>
          {" "}
       Item Name
        </div>
      ),
    },

 
    {
      field: "SECTION_NAME",
      accessor: "SECTION_NAME",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 180,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center `}>
            {param.value }
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Section
        </div>
      ),
    },

    {
      field: "QNTY",
      accessor: "QNTY",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  `}>
            {param.value }
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Qnty
        </div>
      ),
    },
    {
      field: "PUR_PRICE",
      accessor: "PUR_PRICE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value !== "0" ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
    Purchase Price
        </div>
      ),
    },
    {
      field: "AMOUNT",
      accessor: "AMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
        Amount
        </div>
      ),
    },
 
    {
      field: "DISQ_AMT",
      accessor: "DISQ_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            
          >
            {param.value !== 0 ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Dicount
        </div>
      ),
    },
    {
      field: "HSN_CODE",
      accessor: "HSN_CODE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  `}
          >
            {param.value}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
     HSN Code
        </div>
      ),
    },
    {
      field: "TAX1_RATE",
      accessor: "TAX1_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            
          >
            {param.value !== "0" ? FormatNumber2(param.value) + " %": "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          CGST
        </div>
      ),
    },
    {
      field: "TAX1_AMT",
      accessor: "TAX1_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 90,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            
          >
            {param.value !== 0 ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
       CGST
        </div>
      ),
    },
    {
      field: "TAX2_RATE",
      accessor: "TAX2_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            
          >
            {param.value >0 ?param.value + ' %':""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          SGST 
        </div>
      ),
    },
    {
      field: "TAX2_AMT",
      accessor: "TAX2_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 90,
      align: "right",
      renderCell: (param) => {
        return (
          <div

          >
            {param.value !== 0 ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          SGST
        </div>
      ),
    },
    {
      field: "TAX3_RATE",
      accessor: "TAX3_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return (
          <div
       
          >
            {param.value !== "0" ? FormatNumber2(param.value) + " %" : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          IGST 
        </div>
      ),
    },
    {
      field: "TAX3_AMT",
      accessor: "TAX3_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 90,
      align: "right",
      renderCell: (param) => {
        return (
          <div
          
          >
            {param.value !== 0 ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          IGST
        </div>
      ),
    },

    
    {
      field: "NET_AMOUNT",
      accessor: "NET_AMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 90,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            
          >
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
     Net Amount
        </div>
      ),
    },

    {
      field: "BAL_STOCK",
      accessor: "BAL_STOCK",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 120,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            
          >
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
         Bal. Stock
        </div>
      ),
    },
  ];


  const tabColuns = [
    {
      field: "DATE",
      accessor: "DATE",
      type: "date",
      headerAlign: "center",

      width: 90,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            className={` h-full w-full flex items-center  `}
          >
            {params.value ? moment(params.value).format('YYYY-MM-DD'): ""}
           
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
     Date
        </div>
      ),
    },


    {
      field: "NAME",
      accessor: "NAME",
      type: "string",
      headerAlign: "center",

      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            className={` h-full w-full flex items-center  `}
          >
            {params.value ? params.value: ""}
           
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
     Party
        </div>
      ),
    },
    {
      field: "MOBILE",
      accessor: "MOBILE",
      type: "string",
      headerAlign: "center",

      width: 90,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            className={` h-full w-full flex items-center  `}
          >
            {params.value ? params.value: ""}
           
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
     Mobile
        </div>
      ),
    },
    {
      field: "ICODE",
      accessor: "ICODE",
      type: "string",
      width: 70,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
  Code
        </div>
      ),
    },



    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      type: "number",
      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      headerClassName: "super-app-theme--header",
      width:  150,
      headerAlign: "center",
      align: "left",

      renderHeader: (params) => (
        <div className="text-white  whitespace-normal" style={{ fontSize: 13 }}>
          {" "}
       Item Name
        </div>
      ),
    },

 
    {
      field: "SECTION_NAME",
      accessor: "SECTION_NAME",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 150,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center `}>
            {param.value }
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Section
        </div>
      ),
    },

    {
      field: "QNTY",
      accessor: "QNTY",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  `}>
            {param.value }
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Qnty
        </div>
      ),
    },
    {
      field: "PUR_PRICE",
      accessor: "PUR_PRICE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 70,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value !== "0" ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
    Rate
        </div>
      ),
    },
    {
      field: "AMOUNT",
      accessor: "AMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 70,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
        Amount
        </div>
      ),
    },
 
    {
      field: "DISQ_AMT",
      accessor: "DISQ_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 70,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            
          >
            {param.value !== 0 ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Dicount
        </div>
      ),
    },
    {
      field: "HSN_CODE",
      accessor: "HSN_CODE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 70,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  `}
          >
            {param.value}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
     HSN Code
        </div>
      ),
    },
    {
      field: "TAX1_RATE",
      accessor: "TAX1_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            
          >
            {param.value !== "0" ? FormatNumber2(param.value)  +" %": ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          CGST
        </div>
      ),
    },
    {
      field: "TAX1_AMT",
      accessor: "TAX1_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 70,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            
          >
            {param.value !== 0 ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
       CGST
        </div>
      ),
    },
    {
      field: "TAX2_RATE",
      accessor: "TAX2_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            
          >
            {param.value >0 ? param.value + " %":""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          SGST
        </div>
      ),
    },
    {
      field: "TAX2_AMT",
      accessor: "TAX2_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 70,
      align: "right",
      renderCell: (param) => {
        return (
          <div

          >
            {param.value !== 0 ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          SGST
        </div>
      ),
    },
    {
      field: "TAX3_RATE",
      accessor: "TAX3_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return (
          <div
       
          >
        {param.value >0 ? param.value + " %":""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          IGST 
        </div>
      ),
    },
    {
      field: "TAX3_AMT",
      accessor: "TAX3_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 70,
      align: "right",
      renderCell: (param) => {
        return (
          <div
          
          >
            {param.value !== 0 ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          IGST
        </div>
      ),
    },

    
    {
      field: "NET_AMOUNT",
      accessor: "NET_AMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 70,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            
          >
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
     Net Amount
        </div>
      ),
    },

    {
      field: "BAL_STOCK",
      accessor: "BAL_STOCK",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            
          >
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
         Bal. Stock
        </div>
      ),
    },
  ];
  return width <1281? tabColuns: columns;
};
