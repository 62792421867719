import { Close } from "@mui/icons-material";
import { Box, Modal, Slide } from "@mui/material";
import { useEffect } from "react";

const FullWidthModal = ({
  ActionButtons,
  setIsOriginalBill,
  longitude,
  latitude,
  setBillSettlemtModalStatus,
  Undo,
  setBillNo,
  setAlertTwoButtonModal,
  setViewBillStatus,
  SaleType,
  remainingTotal,
  status,
  MobileColumn,
  billCartList,
  isVoided,
  billCart,
  billSettementColumn,
  totalQntyComponent,
  TotalQnty,
  totalReturn,
  totalReturnAmount,
  roundOffAmt,
  handleClose,
  billSettelemntBilling,
  fromref,
  c,
  setisTextActive,
  customerDetails,
  TotalAmount,
  setCustomerDetails,
  customerList,
  prefix,
  token,
  setCustomerList,
  dispatch,
  setBillingDetails,
  inputFields,
  setCustomermasetrHelp,
  grandTotalWithTax,
  width,
  billingDetails,
  setListAccountMasterHelp,
}) => {
  return (
    <div className="flex justify-center">
      <Modal
        open={status}
        onClose={handleClose}
        closeAfterTransition
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Slide direction="up" in={status} mountOnEnter unmountOnExit>
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              right: 0,
              left: 0,
              width: "100%",
              bgcolor: "background.paper",
              boxShadow: 24,
              height: "auto",
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              height: window.innerHeight - 80,
            }}
          >
            <div className="flex justify-between border-b border-gray-300 p-2">
              <div>Bill Settlement</div>
              <button
                onClick={() => {
                  handleClose();
                }}
              >
                <i className="bi bi-x-square-fill text-red-400 text-[20px]"></i>
              </button>
            </div>
            <div className="p-2 h-full ">
              <div
                style={{
                  height: `calc(100% - ${width > 349 ? 460 : 650}px)`,
                }}
              >
                {billCart(
                  billSettementColumn,
                  billCartList,
                  dispatch,
                  isVoided
                )}
              </div>
              <div>
                {totalQntyComponent(
                  billSettementColumn,
                  TotalQnty,
                  totalReturn,
                  billCartList,
                  TotalAmount,
                  totalReturnAmount,
                  width,
                  grandTotalWithTax,
                  true,
                  dispatch
                )}
              </div>
              <div className="mt-2">
                {billSettelemntBilling(
                  fromref,
                  c,
                  setisTextActive,
                  customerDetails,
                  TotalAmount,
                  setCustomerDetails,
                  customerList,
                  prefix,
                  token,
                  setCustomerList,
                  dispatch,
                  setBillingDetails,
                  inputFields,
                  setCustomermasetrHelp,
                  grandTotalWithTax,
                  width,
                  billingDetails,
                  setListAccountMasterHelp,
                  remainingTotal
                )}
              </div>
              <div className="flex justify-end mt-1">
                {ActionButtons(
                  billCartList,
                  billingDetails,
                  grandTotalWithTax,
                  dispatch,
                  prefix,
                  token,
                  Undo,
                  setBillNo,
                  setAlertTwoButtonModal,
                  setViewBillStatus,
                  inputFields,
                  customerDetails,
                  SaleType,
                  handleClose,
                  longitude,
                  latitude,
                  Undo,
                  setIsOriginalBill,
                  roundOffAmt
                )}
              </div>
            </div>
          </Box>
        </Slide>
      </Modal>
    </div>
  );
};

export default FullWidthModal;
