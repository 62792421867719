
import React, { useEffect, useState } from 'react'
import HandleIndexedDB from '../Test/handleIndexDb';
import { OnSubmit } from './function';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrefix } from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import Lottie from 'lottie-react';
import spinner from '../utility/json/Spinner.json';

const modalStyles = "fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50";
const cardStyles = "bg-white dark:bg-zinc-800 rounded-lg shadow-lg w-full max-w-sm max-h-[600px] overflow-hidden";
const listItemStyles = "flex justify-between items-center py-2 border-b border-zinc-200 dark:border-zinc-700";
const buttonStyles = "bg-green text-black p-2 rounded-lg  w-full";
const IsSaveBillOnline = ({status,oldBillIDDB,setBillNo,inputFields,setIsPosSaveOnline}) => {
      const [billDetails,setBillDetails] = useState(oldBillIDDB)
  const dispatch = useDispatch()
  const [loadingIndex,setLoadingIOndex] = useState(null)
  const prefix = useSelector(selectPrefix)
  const token = useSelector(selectToken)
      useEffect(()=>{
        setBillDetails(oldBillIDDB)
      },[oldBillIDDB])

      useEffect(()=>{
        oldBillIDDB.map(s =>{
            const databaseName ='BillingList'
            const storeName = s
     
            HandleIndexedDB({
                            databaseName,
                            storeName,
                            callback: function(data, col) {
                            // const grandT = data[0].GTOTAL
                            // const saleTy = data[0].SALE_TYPE
                            setBillDetails(e => e.map(old => {
                           if(old ===s ){
                            return data
                           }else{
                            return    old
                           }
                            } 
                          )
                            )
                             }})
                            
                    
   
        })
      },[oldBillIDDB])
   const   onButton1Click =()=>{
        let database = 'BillingList'
        
      
        billDetails.map((s,i) => {
            setLoadingIOndex(i)
            const list = s[0]
            OnSubmit(
                s,
                dispatch,
                prefix,
                token,
                ()=>{
                   
                },
                list.GTOTAL,
                setBillNo,
                "",
                "",
                inputFields,
                list.SALE_TYPE,true
              );
         })
 
         const deleteRequest = indexedDB.deleteDatabase(database);
 
                   deleteRequest.onsuccess = function () {
                       console.log("Database deleted successfully");
                   };
 
                   deleteRequest.onerror = function (event) {
                       console.error("Error deleting database:", event.target.error);
                   };
                   setLoadingIOndex(null)
                   setBillDetails([])
                   setIsPosSaveOnline(false)
     }
  return (

<div>
{status && <div className={modalStyles}>
  
<div className={cardStyles}>
<div className='flex justify-between py-3 px-2  bg-gray-100  items-center'>
<h2 className="text-lg font-semibold ">Hold Bill List</h2>

<div>

  <button onClick={()=> setIsPosSaveOnline(false)} >
  <i className="bi bi-x-square-fill text-red-500 text-[18px]"></i>

  </button>
  </div>

</div>
<div className='p-4'>

  <div className='flex justify-between '>

    </div>
    <div style={{
      maxHeight:'410px',
    }} className='overflow-y-auto '>
    <div  className=''>
    {billDetails?.map((old,i) =>{
     

  return (
    <BillItem name={i+1} amount={old[0]?.GTOTAL + '₹'} isLoading={loadingIndex && Number(i) === Number(loadingIndex) ?true:false}/>
)
   })}
    
    </div>
    </div>
 
</div>
<div className='bg-gray-100 px-3 py-3 '>

<button onClick={onButton1Click} className={buttonStyles}>Save All Bills Online</button>
</div>
</div>
</div>}
</div>

  )

}
const BillItem = ({ name, amount ,isLoading}) => {
    return (
        <li className={listItemStyles}>
            <span>{name}</span>
            <div className='flex items-center ' >
            <span>{amount}</span>
  <div className='w-6 ml-3'>
  {isLoading &&  <div className="h-[18px]  w-[18px]  flex items-center ">
                <Lottie
                  animationData={spinner}
                  loop={true}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                />
              </div>}
  </div>
            </div>
        
        </li>
    );
};
export default IsSaveBillOnline