import React, { useState } from 'react';
import './styleDiognal.css';
import Dropzone from 'react-dropzone';
import uniqid from 'uniqid';
import Webcam from 'react-webcam';
import { Close } from '@mui/icons-material';
import { Alert, Button, Snackbar } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import { setAlertWithTitle } from '../../Redux/modal';
import { UploadFile, deleteFile } from '../../functions/firebase';
import { selectPrefix } from '../../Redux/profile';
import AlertModalTwoButton from '../modals/alertWithTwoButton';
const ProfilePicFrame = ({
  customerFirebasePic,
  selectedFile,
  setSelectedFile,
  webcamStatus,
  setWebcamStatus,
  capturedImage,
  setCapturedImage,
  webcamRef,
  path,
  setFirebaseUrl,
}) => {
  const customerDetails = {
    mobile: '123',
  };
  const prefix = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const [isSnackbarOpen,setSnackBarOpen] = useState(false)
  const handleDrop = (acceptedFiles) => {
    if (customerDetails?.mobile) {
      const editedFiles = acceptedFiles.map((file) => {
        const editedName = ` ${uniqid()}-${file.name}`;
        return new File([file], editedName, { type: file.type });
      });


      const name = `${prefix + customerDetails.mobile}`;
      const path = 'retailx/images/';
      UploadFile(editedFiles[0], path, name);
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Customer Mobile!',
          msg: 'Please select Customer Mobile to continue.',
        })
      );
    }

    // dispatch(setSelectedPhoto(acceptedFiles[0]));
  };
  const shootImage = async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    setCapturedImage(imageSrc);
    const name = `${prefix + customerDetails.mobile}`;
    const path = 'retailx/images/';
    const file = await fetch(imageSrc).then((response) => response.blob());
    UploadFile(file, path, name);
    setWebcamStatus(false);
  };
  {
  }
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
      className="diagonalp-container p-2  rounded-sm"
    >
      {webcamStatus ? (
        <div className="h-full w-full flex flex-col justify-between">
          <Close
            onClick={() => {
              setWebcamStatus(false);
              setSelectedFile('');
              setSnackBarOpen(true)
            }}
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              color: '#8A8787',
              backgroundColor: '#ebd9dd',
              zIndex: '50',
              borderBottomLeftRadius: '7px',
              borderTopRightRadius: '7px',
              fontSize: '17px',
            }}
          />
          <Webcam
            audio={false}
            ref={webcamRef}
            style={{
              width: '100%', // Set your desired width
              height: 'auto',
            }}
            // height={profilePicWidth - 50}
            mirrored={true} // Depending on your preference
          />
          <Button
            // ref={updateRef}
            onClick={() => {
              shootImage();
            }}
            variant="contained"
            size="small"
            style={{
              textTransform: 'none',
              letterSpacing: '1px',
              backgroundColor: '#ebd9dd',
              width: '100%',

              fontWeight: '500',

              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className="text-black  flex flex-col  ">Save Image </div>
          </Button>
        </div>
      ) : capturedImage ? (
        <div className="h-full w-full  ">
          <Button
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
              padding: 0,
            }}
          >
            <Close
              onClick={() => {
                setCapturedImage(null);
                setSnackBarOpen(true)
              }}
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                color: '#8A8787',
                backgroundColor: '#ebd9dd',
                zIndex: '50',
                borderBottomLeftRadius: '7px',
                borderTopRightRadius: '7px',
                fontSize: '17px',
              }}
            />
          </Button>

          <img
            src={capturedImage}
            alt={`Selected`}
            style={{
              width: 'auto',
              height: '100%',
            }}
          />
        </div>
      ) : selectedFile ? (
        <div className="h-full w-full">
          <Close
            onClick={() => {
              setWebcamStatus(false);
              setSelectedFile('');
              setSnackBarOpen(true)
            }}
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              color: '#8A8787',
              backgroundColor: '#ebd9dd',
              zIndex: '50',
              borderBottomLeftRadius: '7px',
              borderTopRightRadius: '7px',
              fontSize: '17px',
            }}
          />
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <img
                {...getRootProps()}
                src={URL.createObjectURL(selectedFile)}
                alt={`Selected`}
                style={{
                  width: 'auto',
                  height: '100%',
                }}
              />
            )}
          </Dropzone>
        </div>
      ) : customerDetails?.mobile ? (
        customerFirebasePic ? (
          <div className="h-full w-full  ">

            <Button
              sx={{
                position: 'absolute',
                top: '0',
                right: '0',

                padding: 0,
              }}
            >
              <Close
                onClick={() => {
                  setAlertTwoButtonModal({
                    title: 'Alert',
                    msg: 'Do you want to delete this image',
                    status: true,
                    setStatus: '',
                    button1: 'Yes',
                    button1Click: ()=>{
                      setAlertTwoButtonModal(o=>{
                        return {
                          ...o,
                          status:false
                        }
                      })
                      setFirebaseUrl(null);
                      deleteFile(path);
                      setSnackBarOpen(true)
                    },
                    button2: 'Cancel',
                    button2Click:()=>{
                      setAlertTwoButtonModal(o=>{
                        return {
                          ...o,
                          status:false
                        }
                      })
                    },
                  })
             
                }}
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  color: '#8A8787',
                  backgroundColor: '#ebd9dd',
                  zIndex: '50',
                  borderBottomLeftRadius: '7px',
                  borderTopRightRadius: '7px',
                  fontSize: '17px',
                }}
              />
            </Button>

            <img
              src={customerFirebasePic}
              alt={`Selected`}
              style={{
                width: 'auto',
                height: '100%',
              }}
            />
          </div>
        ) : (
          <>
            <Dropzone onDrop={handleDrop}>
              {({ getRootProps, getInputProps }) => (
                <>
                  <div
                    {...getRootProps()}
                    style={{
                      right: 0,
                      left: 0,
                      zIndex: 100,
                      width: 'auto',
                      height: '100%',
                    }}
                    className={
                      'flex justify-center items-center absolute h-full  '
                    }
                  >
                    <i className="bi bi-person text-ggray   text-[30px] "></i>
                  </div>
                  <div className="diagonalp-1 w-full h-full "></div>

                  <div className="diagonalp-2 "></div>
                </>
              )}
            </Dropzone>
          </>
        )
      ) : customerFirebasePic ? (
        <div className="h-full w-full  ">
          <Button
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
              padding: 0,
            }}
          >
            <Close
              onClick={() => {
                setCapturedImage(null);
                setSnackBarOpen(true)
              }}
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                color: '#8A8787',
                backgroundColor: '#ebd9dd',
                zIndex: '50',
                borderBottomLeftRadius: '7px',
                borderTopRightRadius: '7px',
                fontSize: '17px',
              }}
            />
          </Button>
          <img
            src={customerFirebasePic}
            alt={`Selected`}
            style={{
              width: 'auto',
              height: '100%',
            }}
          />
        </div>
      ) : (
        <>
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <>
                <div
                  {...getRootProps()}
                  style={{
                    right: 0,
                    left: 0,
                    zIndex: 100,
                    width: 'auto',
                    height: '100%',
                  }}
                  className={
                    'flex justify-center items-center absolute h-full  '
                  }
                >
                  <i className="bi bi-person text-ggray  bg-white text-[30px] "></i>
                </div>
                <div className="diagonalp-1 w-full h-full "></div>

                <div className="diagonalp-2 "></div>
              </>
            )}
          </Dropzone>
        </>
      )}
      <Snackbar
    sx={{
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    }}
  open={isSnackbarOpen}
  autoHideDuration={2000}
  onClose={()=>{
    setSnackBarOpen(false)
  }}
>

  <Alert
  onClose={()=>{
    setSnackBarOpen(false)
  }}
  severity="error"
  variant="filled"
  sx={{ width: 'auto' }}
>
  Image Deleted !
</Alert>
</Snackbar >
<AlertModalTwoButton details={alertTwoButtonModal} setAlertTwoButtonModal={setAlertTwoButtonModal} />

    </div>
  );
};

export default ProfilePicFrame;
