import moment from "moment";
import React, {  useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTable } from "react-table";
import { selectWidth } from "../../Redux/profile";

const EditableDataGrid = ({
  onRowDoubleClick,
  columns,
  data,
  onEditCell,
  firstInputRef,
  keyDown,
  nextRow,onScannerClick,
  onCellDoubleClick,cellOubleClickOpn,
  rowBgColor,
  hideRightMargin,rowBgColor2,
  handleBlurLastColumn,deletRow,hideScroll
}) => {
  // Create an instance of the table
  const [input, setInput] = useState("");
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);

  const handleDiv1Scroll = () => {
    const scrollTop = div1Ref.current.scrollLeft;
    div2Ref.current.scrollLeft = scrollTop;
  };
  const windowWidth = useSelector(selectWidth);
  return (
    <div
      style={{ width: "100%", height: "100%", backgroundColor: "white" }}
      className="border-l border-b border-r "
    >
      <div
        ref={div2Ref}
        style={{
          overflowX: "auto",
          scrollbarWidth: "none", // Hide scrollbar for Firefox
          WebkitOverflowScrolling: "touch", // Enable momentum scrolling in iOS
        }}
        className=""
      >  <table {...getTableProps()} className="w-full   border-t-0 ">
          <thead className="">
        {headerGroups.map((headerGroup) => (
          <tr
            style={{ fontSize: "14px" }}
            className="bg-[#0d0d0d] text-white  flex items-center"
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column, index) => (
              <td
                {...column.getHeaderProps()}
                className="whitespace-nowrap  text-center py-2    "
                style={
                  columns[index].width
                    ? {
                        minWidth: columns[index].width,
                        fontWeight: 500,
                        borderRight: "1px solid #888c89",
                      }
                    : {
                        flex: columns[index].flex,
                        fontWeight: 500,
                        borderRight: "1px solid #888c89",
                      }
                }
              >
                {/* {columns[index].width} */}

                {columns[index].renderHeader().props.children}
              </td>
            ))}
          </tr>
        ))}
        </thead>
        </table>
      </div>
      <div
        style={{
          height: `calc(100% -  30px)`,
          overflowX: hideScroll?'hidden':'auto'
        }}
        onScroll={handleDiv1Scroll}
        ref={div1Ref}
        className="   w-full     "
      >
        <table {...getTableProps()} className="w-full   border-t-0 ">
          <thead className="">
            {headerGroups.map((headerGroup) => (
              <tr
                style={{ fontSize: "15px" }}
                className="text-white "
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, index) => (
                  <td
                    {...column.getHeaderProps()}
                    className="whitespace-nowrap  text-center bg-[#0d0d0d]"
                    style={
                      columns[index].width
                        ? {
                            minWidth: columns[index].width,
                            fontWeight: 500,
                          }
                        : {
                            flex: columns[index].flex,
                            fontWeight: 500,
                            borderRight: "1px solid #888c89",
                          }
                    }
                  ></td>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} >
            {rows.map((row, rowIndex) => {
              const bg = rowBgColor
                ? rowBgColor[0]
                  ? rowBgColor.filter((e) => e.ICODE === data[rowIndex].ICODE)
                  : []
                : [];
                const bg2 = rowBgColor2?rowBgColor2[0] ?rowBgColor2.filter((e) => e.ICODE === data[rowIndex].ICODE) :[]:[]
              const bgColor =bg2[0]?'lightBlue': bg[0] ? "#F2D2D2" : "white";
              prepareRow(row);
              return (
                <tr
                  className="hover:cursor-pointer"
           
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, i) => {

                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          borderRight: "1px dashed #b5b3b3",
                          borderTop:
                            rowIndex === 0
                              ? "0 dashed #b5b3b3"
                              : "1px dashed #b5b3b3",
                          borderBottom: "1px dashed #b5b3b3",
                          maxWidth: cell.column.width,

                          fontSize: "14px",
                        }}
                        className="overflow-hidden"
                        
                      
                      >
                        <div
                     
                          style={{
                            textAlign: cell.render("Cell").props.cell.column
                              .align
                              ? cell.render("Cell").props.cell.column.align
                              : "left",
                            backgroundColor: bgColor,
                            whiteSpace: "nowrap", // Prevent text from wrapping
                            textOverflow: "ellipsis",
                          }}
                          onClick={() => {
                            if ((onCellDoubleClick && window.innerWidth<= 1280)  || cellOubleClickOpn) {
                              onCellDoubleClick({
                                ...cell,
                                row: {
                                  ...cell.row,
                                  values: data[rowIndex],
                                },
                              });
                            }
                          }}
                          className="p-2 h-[37px]  flex items-center "
                        >
                          <div
                            ref={i === 0 ? firstInputRef : null}
                            style={{
                              marginRight: hideRightMargin
                                ? 0
                                : i === row.cells.length - 1
                                ? "15px"
                                : 0,
                              
                            }}
                           
                            className=" w-full flex items-center "
                          >
                            
                            {!cell.column.editable &&
                            cell.column?.type !== "date" ? (
                              cell.render("Cell").props.cell.column.type ===
                              "date" ? (
                                moment(
                                  cell.render("Cell").props.cell.value
                                ).format("YYYY-MM-DD")
                              ) : cell.render("Cell").props.cell.value ===
                                  "0.00" ||
                                cell.render("Cell").props.cell.value === 0 ||
                                cell.render("Cell").props.cell.value === "0" ? (
                                " "
                              ) : cell.column.renderCell ? (
                                cell.column.renderCell(
                                  cell.render("Cell").props.cell
                                )
                              ) : (
                                cell.render("Cell")
                              )
                            ) : (
                              <input
                              style={{
                                width:cell.column?.type ==='date'? '113px':'100%',
                                textAlign:cell.column?.align?cell.column?.align:'left'
                              }}
                                readOnly={
                                  cell.column.type !== "date" &&
                                  windowWidth <= 1280 
                                }
                                type={
                                  cell.column?.type ? cell.column.type : "text"
                                }
                          
                                onKeyDown={(e) => {
                                  if (
                                    e.code === "Enter" ||e.code==='NumpadEnter' ||
                                    e.code === "ArrowRight" ||e.code === 'F4'
                                  ) {
                                    if (i < row.cells.length - 1) {
                                      keyDown(
                                        e,
                                        cell.column.field,
                                        cell.row.original.id
                                      );
                                    } else {
                                      nextRow(rowIndex+1);
                                    }
                                  } else if (e.code === "ArrowLeft") {
                                    const currentFieldIndex = columns.findIndex(
                                      (column) =>
                                        column.field === cell.column.field
                                    );
                                    const previousEditableField = columns
                                      .slice(0, currentFieldIndex)
                                      .reverse()
                                      .find((column) => column.editable);
                                    if (previousEditableField) {
                                      const nearestInput =
                                        document.getElementById(
                                          cell.row.original.id +
                                            "-" +
                                            previousEditableField.field
                                        );
                                      // if (nearestInput) {
                                      //   nearestInput.focus();
                                      //   nearestInput.select();
                                      // }
                                      if (nearestInput) {
                                        nearestInput.focus();
                                        // Check if input field is a text-based input
                                        if (
                                          nearestInput.tagName.toLowerCase() ===
                                            "input" &&
                                          nearestInput.type === "text"
                                        ) {
                                          // Set selection range for text inputs
                                          nearestInput.setSelectionRange(
                                            0,
                                            nearestInput.value.length
                                          );
                                        } else {
                                          // For non-text inputs, use select method if supported
                                          if (
                                            typeof nearestInput.select ===
                                            "function"
                                          ) {
                                            if(windowWidth >1280){
                                              nearestInput.select()
          
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }}
                                id={
                                  cell.row.original.id?cell.row.original.id + "-" + cell.column.field:cell.row.original.ICODE + "-" + cell.column.field
                                }
                                onFocus={e=>{
                                  if(windowWidth >1280){
                                    e.target.select()

                                  }
                                }}
                                className="outline-none  bg-slate-100 h-full p-0.5"
                                value={cell.value}
                        
                              
  onClick={() => {
                          if ((onCellDoubleClick && window.innerWidth<= 1280)  || cellOubleClickOpn) {
                            onCellDoubleClick({
                              ...cell,
                              row: {
                                ...cell.row,
                                values: data[rowIndex],
                              },
                            });
                          }
                        }}
                                
                                onChange={(e) => {
                                  onEditCell({
                                    id: cell.row.original.id,
                                    value: e.target.value,
                                    field: cell.column.field,
                                  });
                                }}
                              />
                            )}
                             { onScannerClick &&cell.column.editable && cell.column?.field==='ICODE' && <button onClick={()=>{
                         onScannerClick(cell.row.original.id)
                            }}>
                            <i className="bi bi-upc-scan text-[16px] ml-2 flex items-center text-orange"></i> 
                              </button>}
 {cell.column.editable && cell.column?.type==='date' && <button onClick={()=>{
                         deletRow(cell.row.original.id)
                            }}>
                            <i className="bi bi-x-lg text-[16px] ml-2 flex items-center text-red-500"></i> 
                              </button>}
                           
                          </div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EditableDataGrid;
