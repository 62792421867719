import moment from "moment";
import { FormatNumber2 } from "../../utility/functions/formatNumber";

export const ItemWiseSaleReportColumns = (width) => {
    const columns = [


      {
        field: 'DATE',
        accessor: 'DATE',
        type: 'string',
        headerAlign: 'center',
  
        width: width < 768 ? 100 : 100,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => {
          return (
            <div
              className={`${
                params.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full flex items-center  `}
            >
              {moment(params.value).format('YYYY-MM-DD')}{' '}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Date'}{' '}
          </div>
        ),
      },
      {
        field: 'ITEMNAME',
        accessor: 'ITEMNAME',
        type: 'string',
        width: width < 768 ? 100 : width < 1400 ? 180 : 180,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Item Name
          </div>
        ),
      },
      {
        field: 'CUSTOMER_NAME',
        accessor: 'CUSTOMER_NAME',
        type: 'string',
        width: width < 768 ? 100 : width < 1400 ? 180 : 180,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Customer Name
          </div>
        ),
      },
      {
        field: 'CUSTOMER_MOBILE',
        accessor: 'CUSTOMER_MOBILE',
        type: 'string',
        width: width < 768 ? 100 : width < 1400 ? 180 : 180,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Customer Mobile
          </div>
        ),
      },

      {
        field: 'QNTY',
        accessor: 'QNTY',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 100,
        align: 'right',
 
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
   Sale Qnty
          </div>
        ),
      },
      {
        field: 'SALE_PRICE',
        accessor: 'SALE_PRICE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 100,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0" ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
 Sale Price
          </div>
        ),
      },
      {
        field: 'AMOUNT',
        accessor: 'AMOUNT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 100,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0" ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
  Amount
          </div>
        ),
      },
      {
        field: 'TAX1_RATE',
        accessor: 'TAX1_RATE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 100,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0" ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
        CGST %
          </div>
        ),
      },
      {
        field: 'TAX1_AMT',
        accessor: 'TAX1_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 110,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
            CGST Amount
          </div>
        ),
      },
      {
        field: 'TAX2_RATE',
        accessor: 'TAX2_RATE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 100,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0"  ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
              SGST %
          </div>
        ),
      },   {
        field: 'TAX2_AMT',
        accessor: 'TAX2_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 110,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== 0 ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
            SGST Amount
          </div>
        ),
      },
      {
        field: 'TAX3_RATE',
        accessor: 'TAX3_RATE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 100,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0" ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
           IGST %
          </div>
        ),
      },
      {
        field: 'TAX3_AMT',
        accessor: 'TAX3_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 110,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value!== 0  ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
             IGST Amount
          </div>
        ),
      },
      {
        field: 'GTOTAL',
        accessor: 'GTOTAL',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 120,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
        Total
          </div>
        ),
      },
      {
        field: 'BAL_STOCK',
        accessor: 'BAL_STOCK',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 120,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value }
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
        Item Stock
          </div>
        ),
      },
    ];

    const mobileColumns = [


      {
        field: 'ITEMNAME',
        accessor: 'ITEMNAME',
        type: 'string',
        width:  140,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Item Name
          </div>
        ),
      },
    
      {
        field: 'QNTY',
        accessor: 'QNTY',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 60,
        align: 'right',
 
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
   Sale Qnty
          </div>
        ),
      },
    
      {
        field: 'GTOTAL',
        accessor: 'GTOTAL',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 70,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
       Amount
          </div>
        ),
      },
      {
        field: 'BAL_STOCK',
        accessor: 'BAL_STOCK',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 80,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value }
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
        Item Stock
          </div>
        ),
      },
    ]

    const tabClomuns = [


      {
        field: 'DATE',
        accessor: 'DATE',
        type: 'string',
        headerAlign: 'center',
  
        width: 80,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => {
          return (
            <div
              className={`${
                params.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full flex items-center  `}
            >
              {moment(params.value).format('YYYY-MM-DD')}{' '}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Date'}{' '}
          </div>
        ),
      },
      {
        field: 'ITEMNAME',
        accessor: 'ITEMNAME',
        type: 'string',
        width: width < 768 ? 100 : width < 1400 ? 180 : 180,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Item Name
          </div>
        ),
      },
      {
        field: 'CUSTOMER_NAME',
        accessor: 'CUSTOMER_NAME',
        type: 'string',
        width: 140,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Customer Name
          </div>
        ),
      },
      {
        field: 'CUSTOMER_MOBILE',
        accessor: 'CUSTOMER_MOBILE',
        type: 'string',
        width:100,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
          Mobile
          </div>
        ),
      },

      {
        field: 'QNTY',
        accessor: 'QNTY',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width:  60,
        align: 'right',
 
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
   Sale Qnty
          </div>
        ),
      },
      {
        field: 'SALE_PRICE',
        accessor: 'SALE_PRICE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 60,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0" ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
 Sale Price
          </div>
        ),
      },
      {
        field: 'AMOUNT',
        accessor: 'AMOUNT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 60,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0" ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
  Amount
          </div>
        ),
      },

    
      {
        field: 'TOTAL_TAX',
        accessor: 'TOTAL_TAX',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 60,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value!== 0  ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
             Total Tax
          </div>
        ),
      },
      {
        field: 'GTOTAL',
        accessor: 'GTOTAL',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 60,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
        Total
          </div>
        ),
      },
      {
        field: 'BAL_STOCK',
        accessor: 'BAL_STOCK',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 70,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value }
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
        Bal. Stock
          </div>
        ),
      },
    ];
    return width <500 ?mobileColumns :width<1281?tabClomuns : columns;
  };

  export const ItemWiseSaleReportPrintColumns = (width) => {
    const columns = [


      {
        field: 'DATE',
        accessor: 'DATE',
        type: 'date',
        headerAlign: 'center',
  
        width: width < 768 ? 100 : 100,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => {
          return (
            <div
              className={`${
                params.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full flex items-center  `}
            >
              {moment(params.value).format('YYYY-MM-DD')}{' '}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Date'}{' '}
          </div>
        ),
      }, {
        field: 'ITEMNAME',
        accessor: 'ITEMNAME',
        type: 'string',
        width: width < 768 ? 100 : width < 1400 ? 180 : 180,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Item Name
          </div>
        ),
      },

      {
        field: 'CUSTOMER_NAME',
        accessor: 'CUSTOMER_NAME',
        type: 'string',
        width: width < 768 ? 100 : width < 1400 ? 180 : 180,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Customer Name
          </div>
        ),
      },
      {
        field: 'CUSTOMER_MOBILE',
        accessor: 'CUSTOMER_MOBILE',
        type: 'string',
        width: width < 768 ? 100 : width < 1400 ? 180 : 180,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Customer Mobile
          </div>
        ),
      },
  
    
  
   
  
  
  
      {
        field: 'QNTY',
        accessor: 'QNTY',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 100,
        align: 'right',
     
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
   Qnty
          </div>
        ),
      },
      {
        field: 'SALE_PRICE',
        accessor: 'SALE_PRICE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 100,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0" ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
 Sale Price
          </div>
        ),
      },
      {
        field: 'AMOUNT',
        accessor: 'AMOUNT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 100,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0" ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
  Amount
          </div>
        ),
      },
      {
        field: 'TAX1_RATE',
        accessor: 'TAX1_RATE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 100,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0" ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
        CGST %
          </div>
        ),
      },
      {
        field: 'TAX1_AMT',
        accessor: 'TAX1_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 110,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
            CGST Amount
          </div>
        ),
      },
      {
        field: 'TAX2_RATE',
        accessor: 'TAX2_RATE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 100,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0"  ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
              SGST %
          </div>
        ),
      },   {
        field: 'TAX2_AMT',
        accessor: 'TAX2_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 110,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== 0 ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
            SGST Amount
          </div>
        ),
      },
      {
        field: 'TAX3_RATE',
        accessor: 'TAX3_RATE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 100,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0" ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
           IGST %
          </div>
        ),
      },
      {
        field: 'TAX3_AMT',
        accessor: 'TAX3_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 110,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value!== 0  ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
             IGST Amount
          </div>
        ),
      },
      {
        field: 'SECTION_NAME',
        accessor: 'SECTION_NAME',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 110,
        align: 'right',
 
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
      Section
          </div>
        ),
      },
      {
        field: 'GTOTAL',
        accessor: 'GTOTAL',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 120,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
          Grand Total
          </div>
        ),
      },
    ];
    return columns;
  };

  export const SectionWiseSaleReportColumns = (width) => {
    const columns = [
    
      {
        field: 'SECTION_NAME',
        accessor: 'SECTION_NAME',
  
        type: 'string',
        width: width < 768 ? 200 : width < 1400 ? 180 : 150,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Section Name
          </div>
        ),
      },
  
      {
        field: 'GTOTAL',
        accessor: 'GTOTAL',
  
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 150,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
          Grand Total
          </div>
        ),
      },
    ];
    const tabColumns = [
    
      {
        field: 'SECTION_NAME',
        accessor: 'SECTION_NAME',
  
        type: 'string',
        width: 150,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Section Name
          </div>
        ),
      },
  
      {
        field: 'GTOTAL',
        accessor: 'GTOTAL',
  
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 120,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
          Grand Total
          </div>
        ),
      },
    ];
  
     
      return width <1281?tabColumns: columns;
    };