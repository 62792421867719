
import CryptoJS from 'crypto-js';

const  encryptData = (data) => {
   const  encryptionKey = process.env.REACT_APP_AUTH_SECRET_KEY;
    const encryptedData = CryptoJS.AES.encrypt(data, encryptionKey).toString();
    return encryptedData;
}

const decryptData =(encryptedData ) => {
    const  encryptionKey = process.env.REACT_APP_AUTH_SECRET_KEY;
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
}

export {encryptData,decryptData}