import React from 'react';
import text from '../../../utility/images/GO92X12.png'
import lcon from '../../../utility/images/whiteLogoRetailx.png';
const RoutbillingHomeLogo = () => {
  return (
    <div className=" flex  justify-center items-center">
      <img
        src={lcon}
        style={{
          width: '40px',
          height: 'auto',
        }}
      />
 <div className='flex text-white justify-end font-constantia'>
  <div  className='text-[28px] '>
    Retail
  </div>
  <div className='text-[14px]   pt-[16px]'>
    x
  </div>
 </div>
    </div>
  );
};

export default RoutbillingHomeLogo;
