import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { selectPrefix, selectWidth, selectreportFromDate, selectreportToDate, setreportsFromDate, setreportsTodate } from '../../utility/Redux/profile';
import { FromToDate } from '../../utility/component/fromToDate';
import CustomerMasterHelp from '../../help/customermasterHelp';
import { GetSaleSummary } from './func';
import { selectToken } from '../../utility/Redux/security';
import AccountMasterHelp from '../../help/listAccountMasterHelp';
import { SaleSummaryColumns } from './columns';
import Footer from './footer';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import GeniusTableReports from '../../utility/component/geniusComponents/geniusTableData';
import ViewBillModal from '../../billing/viewBillModal';
import PrintWithFooter from '../../utility/component/datagrid/printReportWithFooter';
import { SaleSummaryPrintColumns } from './printColumns';
import { SaleSummaryPrintTherminalColumns } from './therminaColumns';
import IsLocalData from '../../utility/component/isLocalData';
import FromToDateMobile from '../../utility/component/fromToDateMobile';

const SaleSummary = () => {
  const prefix = useSelector(selectPrefix)
  const [summaryList,setSummaryList] = useState([])
  const fromDate = useSelector(selectreportFromDate)
  const ToDate = useSelector(selectreportToDate)
  const width = useSelector(selectWidth)
  const [billHelpStatus,setBillhelpStatus] = useState(false)
  const [billNo,setBillNo] = useState('')
  const printRef= useRef(null)
  const therminalRef = useRef(null)
  const PrintColumns  = SaleSummaryPrintColumns(width)
  const therminalColoums =SaleSummaryPrintTherminalColumns(width)
  const [getCustomerData,setCustomerData] = useState({
    "table_prefix":prefix,
    "FDATE": moment(fromDate).format('YYYY-MM-DD'),
    "TDATE":  moment(ToDate).format('YYYY-MM-DD'),
    "NAME":"",
    "MOBILE":"",
    "CUSTOMER_NAME":"",
    "CUSTOMER_MOBILE":""
  })
  const [customerName,setCustomerName] =useState('')
  const columns = SaleSummaryColumns(width)
  const [customerHelpStatus, setCustomerHelpStatus] = useState(false);
  const [partyHelpStatus, setPartyHelpStatus] = useState(false);
  const dispatch =  useDispatch()
  const navigate = useNavigate()
  const token = useSelector(selectToken)
  const okButton = ()=>{
  setSummaryList([])
  GetSaleSummary(getCustomerData,token,dispatch,setSummaryList)
  }
  const handlePrint = useReactToPrint({
  content: () => printRef.current,
    pageStyle: `
  @page {
    size: landscape;
  }
`,

});

const handlePrint2 = useReactToPrint({
  content: () => therminalRef.current,
    pageStyle: `
  @page {
    size: landscape;
  }
`,
});


useEffect(()=>{
  setCustomerData(e =>{
    return {
      ...e,
      "FDATE": moment(fromDate).format('YYYY-MM-DD'),
    "TDATE":  moment(ToDate).format('YYYY-MM-DD'),
    }
  })
},[fromDate,ToDate])

const isVoided = (I)=>{
 const l = summaryList.filter(s => s.INVNO ===I.INVNO && s.BILL_STATUS === 'Void')
 if(l[0]){
  return {
    backgroundColor:'white',
    color:'red'
  }
 }else{
  return false
 }
}


  useEffect(()=>{
    const callback = (details, data) => {
      setCustomerData(details[0])
      setSummaryList(data)
  dispatch(setreportsFromDate(details[0].FDATE));
  dispatch(setreportsTodate(details[0].TDATE));
    };
    const elseFunc =()=>{console.log('nothing')}
    IsLocalData('sale_summary', callback, elseFunc);
  },[])
  

  return (
    <div className=" bg-white flex flex-col h-full  overflow-y-auto ">
      <div className=" flex flex-col h-full p-3 ">
        <div>
          {window.innerWidth <= 764 ? (
            <FromToDateMobile maxWidth={'500px'}     onPartyMasterSearchClick={()=>{
              setPartyHelpStatus(true)
            }}
          onPrintClick={handlePrint}
          customerClear={()=>{
            setCustomerName('')
            setCustomerData({
              "table_prefix":prefix,
              "FDATE": moment(fromDate).format('YYYY-MM-DD'),
              "TDATE":  moment(ToDate).format('YYYY-MM-DD'),
              "NAME":"",
              "MOBILE":"",
              "CUSTOMER_NAME":"",
              "CUSTOMER_MOBILE":""
            })
          }}
          partyClear={()=>{
            setCustomerName('')
            setCustomerData({
              "table_prefix":prefix,
              "FDATE": moment(fromDate).format('YYYY-MM-DD'),
              "TDATE":  moment(ToDate).format('YYYY-MM-DD'),
              "NAME":"",
              "MOBILE":"",
              "CUSTOMER_NAME":"",
              "CUSTOMER_MOBILE":""
            })
          }}
            partyData={  {              NAME: getCustomerData.NAME,
              MOBILE: getCustomerData.MOBILE}}
              onCustomerSearchClick={()=>{
                setCustomerHelpStatus(true)
              }}
              customerData={{
                CUSTOMER_NAME:customerName,
                CUSTOMER_MOBILE:getCustomerData.CUSTOMER_MOBILE

              }}
            submit={okButton}

            />
       
          ) : (
            <>
            <FromToDate
          onPrintClick={handlePrint}
        hideReset={true}
         
            submit={okButton}
            />
<div className='mt-1'>
<FromToDate  hideDate={true}     onPartyMasterSearchClick={()=>{
              setPartyHelpStatus(true)
            }}
            customerClear=  {()=>{
              setCustomerName('')
              setCustomerData({
                "table_prefix":prefix,
                "FDATE": moment(fromDate).format('YYYY-MM-DD'),
                "TDATE":  moment(ToDate).format('YYYY-MM-DD'),
                "NAME":"",
                "MOBILE":"",
                "CUSTOMER_NAME":"",
                "CUSTOMER_MOBILE":""
              })
            }}

            partyClear=  {()=>{
              setCustomerName('')
              setCustomerData({
                "table_prefix":prefix,
                "FDATE": moment(fromDate).format('YYYY-MM-DD'),
                "TDATE":  moment(ToDate).format('YYYY-MM-DD'),
                "NAME":"",
                "MOBILE":"",
                "CUSTOMER_NAME":"",
                "CUSTOMER_MOBILE":""
              })
            }}
            hideReset={true}
            partyReset={()=>{
              setCustomerData( e=>{
                return {
                  ...e,
                  "table_prefix":prefix,
                  "FDATE": moment(fromDate).format('YYYY-MM-DD'),
                  "TDATE":  moment(ToDate).format('YYYY-MM-DD'),
                  "NAME":"",
                  "MOBILE":"",
                 
                }
         
              })
              setCustomerName('')

              setSummaryList([])
            }}
            partyData={  {              NAME: getCustomerData.NAME,
              MOBILE: getCustomerData.MOBILE}}
              onCustomerSearchClick={()=>{
                setCustomerHelpStatus(true)
              }}
              customerData={{
                CUSTOMER_NAME:customerName,
                CUSTOMER_MOBILE:getCustomerData.CUSTOMER_MOBILE

              }} />
</div>
         
            </>
          )}

        </div>


  {summaryList[0] ?    <div
            style={{ height:`calc(100% - ${width <= 764 ?220: 170}px)`, width: '100%', marginTop: 10 }}
            className=" mb-2  "
          >


            <GeniusTableReports  isVoided={isVoided} onRowDoubleClick={(row)=>{
              const data = row.values
             setBillNo(data.INVNO)
             setBillhelpStatus(true)
            }} data={summaryList} columns={columns} footer={<Footer columns={columns} rows={summaryList}/>}/>
 
<div className='w-full mt-3 '>
          
          <div  style={{
        display:'none'
       }}>
        <div ref={printRef}>
          <PrintWithFooter
     
       customeHeader={
         <div className="items-center flex justify-center w-full" >
        <div className='text-center '>
        Sale Summary

          </div>
            <div className="flex items-center">
            <div className="mr-1 text-sm font-semibold flex justify-center ">
            </div>
            <div className="text-sm font-semibold" >
              From :
              </div>
              <div className="ml-1 text-sm">
                 {moment(fromDate).format('DD-MM-YYYY')} </div>
                 <div className="text-sm font-semibold ml-2" >
              To :
              </div>
              <div className="ml-1 text-sm">
                 {moment(ToDate).format('DD-MM-YYYY')} </div>
               
                 </div>
               
           </div>}
         
            data={summaryList}
            columns={PrintColumns}
            headerWidth={true}
            marginTop={"120px"}
            footer={<Footer columns={PrintColumns} rows={summaryList}/>}

          />
        </div>
        </div>
          </div>
          <div className='w-full mt-3 '>
          
          <div  style={{
        display:'none'
       }}>
        <div ref={therminalRef}>
          <PrintWithFooter


customeHeader={
  <div className="items-center " >
 <div className='text-center '>
 Sale Summary

   </div>
     <div className="flex items-center">
     <div className="mr-1 text-sm font-semibold flex justify-center ">
     </div>
     <div className="text-sm font-semibold" >
       From :
       </div>
       <div className="ml-1 text-sm">
          {moment(fromDate).format('DD-MM-YYYY')} </div>
          <div className="text-sm font-semibold ml-2" >
       To :
       </div>
       <div className="ml-1 text-sm">
          {moment(ToDate).format('DD-MM-YYYY')} </div>
        
          </div>
        
    </div>}
         
            data={summaryList}
            columns={therminalColoums}
            headerWidth={true}
            marginTop={"120px"}
            footer={<Footer columns={therminalColoums} rows={summaryList}/>}

          />
        </div>
        </div>
          </div>
          </div> : <div className='h-full flex justify-center items-center text-sm text-gray-400 border mt-3 rounded-sm'>
            No Records Found
            </div>}
       
    
     
      </div>
      <AccountMasterHelp onRowClick={(e) =>{
                setCustomerData(c =>{
                    return{
                        ...c,
                        "NAME":e.row.NAME,
                        "MOBILE":"",
                        "CUSTOMER_NAME":"",
                        "CUSTOMER_MOBILE":"",
                    }
                })
        setCustomerName("")

                setPartyHelpStatus(false)
      }} status={partyHelpStatus}  onClose={()=>{
        setPartyHelpStatus(false)
      }}/>
    <CustomerMasterHelp onRowClick={(e) =>{
        setCustomerData(c =>{
            return{
                ...c,
                "CUSTOMER_NAME":"",
                "CUSTOMER_MOBILE":e.row.CUSTOMER_MOBILE,
                "NAME":"",
                "MOBILE":"",
            }
        })
        setCustomerName(e.row.CUSTOMER_NAME)
        setCustomerHelpStatus(false)
    }} onClose={()=>{setCustomerHelpStatus(false)}} status={customerHelpStatus}/>
    <ViewBillModal bill_performa={'bills'} hideOrderSlip={true} setSummaryList={setSummaryList} status={billHelpStatus} billNo ={billNo}onBack={()=>setBillhelpStatus(false)

    }  />
    </div>
  );
};

export default SaleSummary;
