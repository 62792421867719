import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  selectPrefix,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setreportsFromDate,
  setreportsTodate,
} from "../../utility/Redux/profile";
import { FromToDate } from "../../utility/component/fromToDate";
import CustomerMasterHelp from "../../help/customermasterHelp";
import { GetPurchaseSummary } from "./func";
import { selectToken } from "../../utility/Redux/security";
import AccountMasterHelp from "../../help/listAccountMasterHelp";
import { PurchaseSummaryColumns,  } from "./columns";
import Footer from "./footer";
import { useReactToPrint } from "react-to-print";
import GeniusTableReports from "../../utility/component/geniusComponents/geniusTableData";
import PrintWithFooter from "../../utility/component/datagrid/printReportWithFooter";
import { PrintPurchaseSummaryColumns } from "./printColumns";
import IsLocalData from "../../utility/component/isLocalData";
import FromToDateMobile from "../../utility/component/fromToDateMobile";

const PurchaseSummary = () => {
  const prefix = useSelector(selectPrefix);
  const [purchaseList, setPurchaseList] = useState([]);
  const fromDate = useSelector(selectreportFromDate);
  const ToDate = useSelector(selectreportToDate);
  const width = useSelector(selectWidth);
  const [getCustomerData, setCustomerData] = useState({
    table_prefix: prefix,
    FDATE: moment(fromDate).format("YYYY-MM-DD"),
    TDATE: moment(ToDate).format("YYYY-MM-DD"),
    NAME: "",
    MOBILE: "",
  });
  const columns = PurchaseSummaryColumns(width);
  const printColumns = PrintPurchaseSummaryColumns(width);
  const [customerHelpStatus, setCustomerHelpStatus] = useState(false);
  const [partyHelpStatus, setPartyHelpStatus] = useState(false);
  const printRef = useRef(null);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const okButton = () => {
    GetPurchaseSummary(getCustomerData, token, dispatch, setPurchaseList);
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    
  });

  useEffect(()=>{
    const callback = (details, data) => {
      setCustomerData(details[0])
  setPurchaseList(data)
  dispatch(setreportsFromDate(details[0].FDATE));
  dispatch(setreportsTodate(details[0].TDATE));
    };
    const elseFunc =()=>{console.log('nothing')}
    IsLocalData('purchase_summary', callback, elseFunc);
  },[])
  
  return (
    <div className=" bg-white flex flex-col h-full w-full overflow-y-scroll">
      <div className=" flex flex-col h-full p-3  w-full">
        <div>
          {window.innerWidth <= 1000 ? (
            <>
            <FromToDateMobile   partyData={{
                NAME: getCustomerData.NAME,
                MOBILE: getCustomerData.MOBILE,
              }}
              onPrintClick={handlePrint}
              onPartyMasterSearchClick={(e) => setPartyHelpStatus(true)}
              submit={okButton}
              partyClear={() => {
                setCustomerData((e) => {
                  return {
                    ...e,
                    table_prefix: prefix,
                    FDATE: moment(fromDate).format("YYYY-MM-DD"),
                    TDATE: moment(ToDate).format("YYYY-MM-DD"),
                    NAME: "",
                    MOBILE: "",
                  };
                });
                setPurchaseList([]);
              }}
              />
            </>
          ) : (
            <FromToDate
            onPrintClick={handlePrint}
              partyData={{
                NAME: getCustomerData.NAME,
                MOBILE: getCustomerData.MOBILE,
              }}

              hideReset
              onPartyMasterSearchClick={(e) => setPartyHelpStatus(true)}
              submit={okButton}
              partyClear={() => {
                setCustomerData((e) => {
                  return {
                    ...e,
                    table_prefix: prefix,
                    FDATE: moment(fromDate).format("YYYY-MM-DD"),
                    TDATE: moment(ToDate).format("YYYY-MM-DD"),
                    NAME: "",
                    MOBILE: "",
                  };
                });
                setPurchaseList([]);
              }}
           
            />
          )}
        </div>

        <div
          style={{ height: `calc(100% - 0px)`, width: "100%", marginTop: 6 }}
          className=" mb-2 "
        >
          {purchaseList[0] ? (
            <GeniusTableReports
              columns={columns}
              data={purchaseList}
              footer={<Footer columns={columns} rows={purchaseList} />}
            />
          ) : (
            <div className="h-full flex justify-center items-center text-sm text-gray-400 border mt-3 rounded-sm">
              No Records Found
            </div>
          )}
        </div>


        <div
     
        >
       

          <div
            style={{
              display: "none",
            }}
          >
            <div ref={printRef}>
              <PrintWithFooter
                  customeHeader={
                    <div className="items-center flex justify-center w-full" >
                   <div className='text-center '>
           
                     </div>
                       <div className="flex items-center">
                       <div className="mr-1 text-sm font-semibold flex justify-center ">
                       </div>
                       <div className="text-sm font-semibold" >
                         From :
                         </div>
                         <div className="ml-1 text-sm">
                            {moment(fromDate).format('DD-MM-YYYY')} </div>
                            <div className="text-sm font-semibold ml-2" >
                         To :
                         </div>
                         <div className="ml-1 text-sm">
                            {moment(ToDate).format('DD-MM-YYYY')} </div>
                          
                            </div>
                          
                      </div>}
            title={'Purchase Summary'}
            marginTop={"120px"}
            headerWidth={true}

                data={purchaseList}
                columns={printColumns}
                footer={<Footer columns={printColumns} rows={purchaseList} />}
              />
            </div>
          </div>
        </div>
      </div>
      <AccountMasterHelp
        onRowClick={(e) => {
          setCustomerData((c) => {
            return {
              ...c,
              NAME: e.row.NAME,
              MOBILE: e.row.MOBILE,
            };
          });
          setPartyHelpStatus(false);
        }}
        status={partyHelpStatus}
        onClose={() => {
          setPartyHelpStatus(false);
        }}
      />
      <CustomerMasterHelp
        onRowClick={(e) => {
          setCustomerData((c) => {
            return {
              ...c,
              CUSTOMER_NAME: e.row.CUSTOMER_NAME,
              CUSTOMER_MOBILE: e.row.CUSTOMER_MOBILE,
            };
          });
          setCustomerHelpStatus(false);
        }}
        onClose={() => {
          setCustomerHelpStatus(false);
        }}
        status={customerHelpStatus}
      />
    </div>
  );
};

export default PurchaseSummary;
