import { createSlice } from '@reduxjs/toolkit';
import CryptoJS from 'crypto-js';
const secretPass = process.env.REACT_APP_SECRET_KEY;
const token = localStorage.getItem('tkn');

const shopToken = localStorage.getItem('shoptkn');
const shopUserId = localStorage.getItem('shopUserId');

const getToken = () => {
  if (token) {
    const bytes = CryptoJS.AES.decrypt(token, secretPass);

    if (bytes.sigBytes < 0) {
      localStorage.removeItem('prefix');
      localStorage.removeItem('expiresAt');
    } else {
      const tk = bytes.toString(CryptoJS.enc.Utf8);
      const tkn = JSON.parse(tk);

      return tkn;
    }
  }

  return null;
};

const getShopUserId = () => {
  if (shopUserId) {
    const bytes = CryptoJS.AES.decrypt(shopUserId, secretPass);

    if (bytes.sigBytes < 0) {
      localStorage.removeItem('shoptkn');
      localStorage.removeItem('shopUserId');
    } else {
      const tk = bytes.toString(CryptoJS.enc.Utf8);
      const tkn = JSON.parse(tk);

      return tkn;
    }
  }

  return null;
};

const getShopToken = () => {
  if (shopToken) {
    const bytes = CryptoJS.AES.decrypt(shopToken, secretPass);

    if (bytes.sigBytes < 0) {
      localStorage.removeItem('shoptkn');
    } else {
      const tk = bytes.toString(CryptoJS.enc.Utf8);
      const tkn = JSON.parse(tk);

      return tkn;
    }
  }

  return null;
};

export const Security = createSlice({
  name: 'security',
  initialState: {
    islogin: false,
    token: getToken(),
    shopUserId: getShopUserId(),
    systemAdmin: false,
    otpStatus:false,
    randomOtp:'',
    otpMobile:'',
    shopkeeperToken: getShopToken(),
  },
  reducers: {
    setLogin(state, action) {
      state.islogin = action.payload;
    },
setOtpStatus(state,action) {
  state.otpStatus = action.payload
},
setRandomOtp(state,action){
  state.randomOtp= action.payload
  },setOtpMobile(state,action){
    state.otpMobile= action.payload
    },
    setSystemAdminModalStatus(state, action) {
      state.systemAdmin = action.payload;
    },

    setShopkeeperToken(state, action) {
      state.shopkeeperToken = action.payload;
      const data = CryptoJS.AES.encrypt(
        JSON.stringify(action.payload),
        secretPass
      ).toString();
      localStorage.setItem('shoptkn', data);
    },

    setShopkeeperUserID(state, action) {
      state.shopUserId = action.payload;
      const data = CryptoJS.AES.encrypt(
        JSON.stringify(action.payload),
        secretPass
      ).toString();
      localStorage.setItem('shopUserId', data);
    },

    setToken(state, action) {
      state.token = action.payload;
      // state.headerAuth = {
      //   "Content-Type": "application/json",
      //   Authorization: `Bearer ${action.payload}`,
      // };
      const data = CryptoJS.AES.encrypt(
        JSON.stringify(action.payload),
        secretPass
      ).toString();
      localStorage.setItem('tkn', data);
    },
  },
});

export default Security.reducer;

export const {
  setLogin,
  setToken,setRandomOtp,
  setShopkeeperUserID,
  setSystemAdminModalStatus,
  setShopkeeperToken,setOtpStatus,setOtpMobile
} = Security.actions;

export const selectLogin = (state) => state.security.islogin;
export const selectToken = (state) => state.security.token;
export const selectSystemAdminModal = (state) => state.security.systemAdmin;
export const selectShopkeeperToken = (state) => state.security.shopkeeperToken;
// export const selectheader = (state) => state.security.headerAuth;
export const selectShopkeeperUserId = (state) => state.security.shopUserId;
export const selectOtpModalStatus = (state) => state.security.otpStatus;
export const selectRandomOtp = (state) => state.security.randomOtp;
export const selectOtpMobile = (state) => state.security.otpMobile;
