import React from "react";
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from "../utility/component/buttons";

import ItemBox from "./itemBox";
import { GetListItemMaster, OnSubmit, itemClick } from "./function";
import moment from "moment";
import PlaceHolderTextInput from "../utility/component/geniusComponents/placeTextInput";
import { FormatNumber2 } from "../utility/functions/formatNumber.js";
import {
  setAlertWithTitle,
  setIssueStockItem,
  setnumericKeyboardStatus,
  setnumericKeyboardType,
} from "../utility/Redux/modal.js";
import { enqueueSnackbar } from "notistack";
import "./animat.css";
import {
  fastMovingMovieItem,
  FontSizeWidth,
  getItemByAlios,
  getItemByCode,
  getItemByName,
} from "../utility/constant.js";
import EditableDataGrid from "../utility/component/datagrid/editableDatagrid.js";
import { getCustomerByMobile } from "../utility/functions/common.js";
import { toProperCase } from "../utility/component/format.js";
export const mobileViewWidth = 1020;
export const tabViewMinWidth = 765;

function billSettelemntBilling(
  fromref,
  c,
  setisTextActive,
  customerDetails,
  TotalAmount,
  setCustomerDetails,
  customerList,
  prefix,
  token,
  setCustomerList,
  dispatch,
  setBillingDetails,
  inputFields,
  setCustomermasetrHelp,
  grandTotalWithTax,
  width,
  billingDetails,
  setListAccountMasterHelp,
  remainingTotal,
  hideHeader
) {
  const secWidth =
    width > mobileViewWidth
      ? c[1].width + c[2].width + c[3].width + c[4].width
      : c[6].width + c[5].width;
  const thirWidth =
    width > mobileViewWidth && width < 1280
      ? c[6].width + c[5].width + 80
      : c[6].width + c[5].width;
  return (
    <div
      ref={fromref}
      className=" mt-[1px]  overflow-hidden  border-b rounded-b-sm "
    >
      {!hideHeader && (
        <div
          style={{
            display: width > mobileViewWidth ? "flex" : "none",
          }}
        >
          <div
            style={{
              width:
                c[0].width + c[1].width + c[2].width + c[3].width + c[4].width,
            }}
            className=" bg-[#383535] py-[4px] rounded-tr-sm flex items-center justify-center rounded-tl-sm p-1 text-white text-center"
          >
            Bill Settlement
          </div>
          <div className="flex   border-b    border-r bg-[#f7f7fd]  ">
            <div
              style={{
                width: c[5].width,
              }}
              className=" flex text-[16px] border-l   font-semibold  pr-1 items-center justify-end text-right"
            >
              Points
            </div>
            <div
              style={{
                width: c[6].width,
              }}
              className=" text-[17px] border-l font-semibold  text-end p-1"
            >
              {0}
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-center">
        <div
          style={{
            gridTemplateColumns:
              width > mobileViewWidth
                ? `${c[0].width}px ${secWidth - 10}px ${thirWidth}px`
                : width < 350
                ? `${width - 30}px`
                : `${width / 2 - 40}px ${width / 2}px`,
          }}
          className={`t:bg-[#F7F7F7] grid gap-[5px]  mt-1`}
        >
          <PlaceHolderTextInput
            type={"number"}
            id={"Customer Mobile"}
            onFocus={() => {
              setisTextActive(true);
            }}
            onLostFocus={(t) => {
              if (t.target.value.length > 9) {
                getCustomerByMobile(
                  t.target.value,
                  prefix,
                  token,
                  dispatch
                ).then((customerData) => {
                  if (customerData[0]) {
                    setCustomerDetails(customerData[0]);
                  }
                });
              }
            }}
            placeholder={"Customer Mobile"}
            value={customerDetails.CUSTOMER_MOBILE}
            onChange={(t) => {
              if (TotalAmount > 0) {
                setCustomerDetails((e) => {
                  return {
                    ...e,
                    CUSTOMER_MOBILE: t.target.value,
                  };
                });
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: `Please add items to do bill settlement`,
                  })
                );
              }
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                if (TotalAmount > 0) {
                  if (e.target.value) {
                    getCustomerByMobile(
                      e.target.value,
                      prefix,
                      token,
                      dispatch
                    ).then((customerData) => {
                      if (customerData[0]) {
                        setCustomerDetails(customerData[0]);
                      }
                    });
                  }
                  inputFields.forEach((element) => {
                    if (element.id === "Cash_amount") {
                      element.focus();
                    }
                  });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Please add some items to create bill.",
                    })
                  );
                }
              } else {
                if (e.code === "ArrowRight") {
                  e.preventDefault();
                  inputFields.forEach((element) => {
                    if (element.id === "Customer_name") {
                      element.focus();
                    }
                  });
                } else {
                  if (e.code === "ArrowDown") {
                    e.preventDefault();

                    inputFields.forEach((element) => {
                      if (element.id === "Cash_amount") {
                        element.focus();
                      }
                    });
                  } else {
                    if (e.code === "ArrowUp") {
                      e.preventDefault();
                    }
                  }
                }
              }
            }}
            onchange={(t) => {
              if (TotalAmount > 0) {
                if (!customerDetails.CUSTOMER_CODE) {
                  setCustomerDetails((e) => {
                    return {
                      ...e,
                      CUSTOMER_MOBILE: t.target.value,
                    };
                  });
                  if (t.target.value.length === 10) {
                    const isOldCustomer = customerList.filter(
                      (customer) => customer.CUSTOMER_MOBILE === t.target.value
                    );
                    if (isOldCustomer[0]) {
                      setCustomerDetails({
                        ...isOldCustomer[0],
                      });
                      setBillingDetails((old) => ({
                        ...old,
                        btcName: isOldCustomer[0].CUSTOMER_NAME,
                      }));
                    }
                  }
                } else {
                  setCustomerDetails((cus) => {
                    return {
                      ...cus,
                      CUSTOMER_CODE: "",
                      CUSTOMER_NAME: "",
                      CUSTOMER_ADDRESS: "",
                      CUSTOMER_EMAIL: "",
                      CUSTOMER_TYPE: "",
                      CITY: "",
                      COUNTRY: "",
                      CUSTOMER_MOBILE: "",
                      PIN_CODE: "",
                      DISQ_RATE: "0",
                      LOYALTY_POINTS: 0,
                      CUSTOMER_STATUS: "",
                      PRV_BAL: 0,
                      BAL_TYPE: "",
                    };
                  });
                }
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: `Please add items to do bill settlement`,
                  })
                );
              }
            }}
          />

          <PlaceHolderTextInput
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                if (TotalAmount > 0) {
                  // setCustomermasetrHelp(true);
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: `Please add items to do bill settlement`,
                    })
                  );
                }
              } else {
                if (e.code === "ArrowRight") {
                  inputFields.forEach((element) => {
                    if (element.id === "Cash_amount") {
                      element.focus();
                    }
                  });
                } else {
                  if (e.code === "ArrowDown") {
                    inputFields.forEach((element) => {
                      if (element.id === "Card_amount") {
                        element.focus();
                      }
                    });
                  } else {
                    if (e.code === "ArrowLeft") {
                      inputFields.forEach((element) => {
                        if (element.id === "Customer_mobile") {
                          element.focus();
                        }
                      });
                    }
                  }
                }
              }
            }}
            onFocus={() => {
              setisTextActive(true);
            }}
            onBlur={() => {
              setisTextActive(false);
            }}
            type={"text"}
            id={"Customer_name"}
            placeholder={"Customer Name"}
            value={customerDetails.CUSTOMER_NAME}
            rightIcon={
              <button
                onClick={() => {
                  setCustomermasetrHelp(true);
                }}
              >
                <i className="bi bi-search text-orange"></i>
              </button>
            }
            onchange={(t) => {
              if (TotalAmount > 0) {
                if (!customerDetails.CUSTOMER_CODE) {
                  setCustomerDetails((e) => {
                    return {
                      ...e,
                      CUSTOMER_NAME: toProperCase(t.target.value),
                    };
                  });
                } else {
                  setCustomerDetails((cus) => {
                    return {
                      ...cus,
                      CUSTOMER_CODE: "",
                      CUSTOMER_NAME: "",
                      CUSTOMER_ADDRESS: "",
                      CUSTOMER_EMAIL: "",
                      CUSTOMER_TYPE: "",
                      CITY: "",
                      COUNTRY: "",
                      CUSTOMER_MOBILE: "",
                      PIN_CODE: "",
                      DISQ_RATE: "0",
                      LOYALTY_POINTS: 0,
                      CUSTOMER_STATUS: "",
                      PRV_BAL: 0,
                      BAL_TYPE: "",
                    };
                  });
                }
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: `Please add items to do bill settlement`,
                  })
                );
              }
            }}
          />

          {width >= 1024 && (
            <PlaceHolderTextInput
              icon={
                <div>
                  <i className="bi bi-currency-rupee text-[#b5b3b3]  flex items-center"></i>
                </div>
              }
              id={"Grand Total"}
              fontWieght={600}
              readOnly={true}
              borderColor={"#262626"}
              placeholder={"Grand Total"}
              value={` Grand Total : ${
                grandTotalWithTax && FormatNumber2(grandTotalWithTax)
              }`}
            />
          )}

          {/* ///test */}

          <PlaceHolderTextInput
            readOnly={width <= 1280}
            onClick={() => {
              if (width <= 1280) {
                dispatch(setnumericKeyboardType("CASH"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "CASH",
                    id: "1",
                    value:
                      billingDetails.cashAmount !== 0
                        ? billingDetails.cashAmount
                        : 0,
                  })
                );
              }
            }}
            autoFocus={true}
            onFocus={(r) => {
              r.target.select();
              setisTextActive(true);
            }}
            onBlur={() => {
              setisTextActive(false);
            }}
            align={"right"}
            onDoubleClick={() => {
              setBillingDetails((e) => {
                return {
                  ...e,
                  cashAmount: remainingTotal,
                };
              });
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                const inputFields =
                  fromref.current.querySelectorAll("input, select");
                inputFields.forEach((element) => {
                  if (element.id === "Card_amount") {
                    element.focus();
                  }
                });
              }
            }}
            id={"Cash_amount"}
            type={"number"}
            placeholder={"Cash Amount"}
            value={
              billingDetails.cashAmount !== 0 ? billingDetails.cashAmount : ""
            }
            onchange={(t) => {
              if (TotalAmount > 0) {
                setBillingDetails((e) => {
                  return {
                    ...e,
                    cashAmount: t.target.value === "" ? 0 : t.target.value,
                  };
                });
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: `Please add items to do bill settlement`,
                  })
                );
              }
            }}
          />

          <PlaceHolderTextInput
            type={"number"}
            id={"Card_amount"}
            align={"right"}
            readOnly={width <= 1280}
            onClick={() => {
              if (width <= 1280) {
                dispatch(setnumericKeyboardType("CARD_AMT"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "CARD_AMT",
                    id: "1",
                    value:
                      billingDetails.cardAmount !== 0
                        ? billingDetails.cardAmount
                        : 0,
                  })
                );
              }
            }}
            onFocus={() => {
              setisTextActive(true);
            }}
            onBlur={() => {
              setisTextActive(false);
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                const inputFields =
                  fromref.current.querySelectorAll("input, select");
                inputFields.forEach((element) => {
                  if (element.id === "Card_no") {
                    element.focus();
                  }
                });
              } else {
                if (e.code === "ArrowRight") {
                  inputFields.forEach((element) => {
                    if (element.id === "Card_no") {
                      element.focus();
                    }
                  });
                } else {
                  if (e.code === "ArrowDown") {
                    inputFields.forEach((element) => {
                      if (element.id === "BTC_amount") {
                        element.focus();
                      }
                    });
                  } else {
                    if (e.code === "ArrowLeft") {
                      inputFields.forEach((element) => {
                        if (element.id === "Cash_amount") {
                          element.focus();
                        }
                      });
                    } else {
                      if (e.code === "ArrowUp") {
                        inputFields.forEach((element) => {
                          if (element.id === "Customer_name") {
                            element.focus();
                          }
                        });
                      }
                    }
                  }
                }
              }
            }}
            placeholder={"Card Amount"}
            onDoubleClick={() => {
              const remainingTotal =
                Number(grandTotalWithTax) -
                (Number(billingDetails.cashAmount) +
                  Number(billingDetails.eAmount) +
                  Number(billingDetails.btcAmount) +
                  Number(billingDetails.couponAmount));

              setBillingDetails((e) => {
                return {
                  ...e,
                  cardAmount: remainingTotal,
                };
              });
            }}
            value={
              billingDetails.cardAmount !== 0 ? billingDetails.cardAmount : ""
            }
            onchange={(t) => {
              if (TotalAmount > 0) {
                setBillingDetails((e) => {
                  return {
                    ...e,
                    cardAmount: t.target.value === "" ? 0 : t.target.value,
                  };
                });
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: `Please add items to do bill settlement`,
                  })
                );
              }
            }}
          />

          <PlaceHolderTextInput
            icon={
              <i className="bi mr-1  bi-credit-card text-[#b5b3b3]  text-[16px] flex  items-center"></i>
            }
            id={"Card_no"}
            onFocus={() => {
              setisTextActive(true);
            }}
            onBlur={() => {
              setisTextActive(false);
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                const inputFields =
                  fromref.current.querySelectorAll("input, select");
                inputFields.forEach((element) => {
                  if (element.id === "eAmount") {
                    element.focus();
                  }
                });
              }
              if (e.code === "ArrowRight") {
                inputFields.forEach((element) => {
                  if (element.id === "eAmount") {
                    element.focus();
                  }
                });
              } else {
                if (e.code === "ArrowDown") {
                  inputFields.forEach((element) => {
                    if (element.id === "BTC_amount") {
                      element.focus();
                    }
                  });
                } else {
                  if (e.code === "ArrowLeft") {
                    inputFields.forEach((element) => {
                      if (element.id === "Card_amount") {
                        element.focus();
                      }
                    });
                  }
                }
              }
            }}
            readOnly={width <= 1280}
            onClick={() => {
              if (width <= 1280) {
                dispatch(setnumericKeyboardType("CARD_NUMBER"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "CARD_NUMBER",
                    id: "1",
                    value:
                      billingDetails.cardNo !== 0 ? billingDetails.cardNo : 0,
                  })
                );
              }
            }}
            type={"text"}
            placeholder={"Card Number"}
            value={billingDetails.cardNo !== 0 ? billingDetails.cardNo : ""}
            onchange={(t) => {
              if (TotalAmount > 0) {
                setBillingDetails((e) => {
                  return {
                    ...e,
                    cardNo: t.target.value === "" ? 0 : t.target.value,
                  };
                });
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: `Please add items to do bill settlement`,
                  })
                );
              }
            }}
          />

          <PlaceHolderTextInput
            type={"number"}
            id={"eAmount"}
            placeholder={" eAmount"}
            onFocus={() => {
              setisTextActive(true);
            }}
            align={"right"}
            onBlur={() => {
              setisTextActive(false);
            }}
            readOnly={width <= 1280}
            onClick={() => {
              if (width <= 1280) {
                dispatch(setnumericKeyboardType("EAMOUNT"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "EAMOUNT",
                    id: "1",
                    value:
                      billingDetails.eAmount !== 0 ? billingDetails.eAmount : 0,
                  })
                );
              }
            }}
            value={billingDetails.eAmount !== 0 ? billingDetails.eAmount : ""}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                const inputFields =
                  fromref.current.querySelectorAll("input, select");
                inputFields.forEach((element) => {
                  if (element.id === "BTC_amount") {
                    element.focus();
                  }
                });
              } else {
                if (e.code === "ArrowRight") {
                  inputFields.forEach((element) => {
                    if (element.id === "BTC_amount") {
                      element.focus();
                    }
                  });
                } else {
                  if (e.code === "ArrowDown") {
                    inputFields.forEach((element) => {
                      if (element.id === "Coupan_amount") {
                        element.focus();
                      }
                    });
                  } else {
                    if (e.code === "ArrowUp") {
                      inputFields.forEach((element) => {
                        if (element.id === "Cash_amount") {
                          element.focus();
                        }
                      });
                    }
                  }
                }
              }
            }}
            onDoubleClick={() => {
              const remainingTotal =
                Number(grandTotalWithTax) -
                (Number(billingDetails.cashAmount) +
                  Number(billingDetails.cardAmount) +
                  Number(billingDetails.btcAmount) +
                  Number(billingDetails.couponAmount));

              setBillingDetails((e) => {
                return {
                  ...e,
                  eAmount: remainingTotal,
                };
              });
            }}
            onchange={(t) => {
              if (TotalAmount > 0) {
                setBillingDetails((e) => {
                  return {
                    ...e,
                    eAmount: t.target.value === "" ? 0 : t.target.value,
                  };
                });
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: `Please add items to do bill settlement`,
                  })
                );
              }
            }}
          />

          <PlaceHolderTextInput
            onFocus={() => {
              setisTextActive(true);
            }}
            align={"right"}
            onBlur={() => {
              setisTextActive(false);
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                if (billingDetails.btcAmount > 0) {
                  const inputFields =
                    fromref.current.querySelectorAll("input, select");
                  inputFields.forEach((element) => {
                    if (element.id === "Coupan_amount") {
                      element.focus();
                    }
                  });
                } else {
                  setListAccountMasterHelp(true);
                }
              }
              if (e.code === "ArrowDown") {
                inputFields.forEach((element) => {
                  if (element.id === "Coupan_no") {
                    element.focus();
                  }
                });
              } else {
                if (e.code === "ArrowUp") {
                  inputFields.forEach((element) => {
                    if (element.id === "Card_amount") {
                      element.focus();
                    }
                  });
                } else {
                  if (e.code === "ArrowRight") {
                    inputFields.forEach((element) => {
                      if (element.id === "Coupan_amount") {
                        element.focus();
                      }
                    });
                  } else {
                    if (e.code === "ArrowLeft") {
                      inputFields.forEach((element) => {
                        if (element.id === "eAmount") {
                          element.focus();
                        }
                      });
                    }
                  }
                }
              }
            }}
            id={"BTC_amount"}
            type={"number"}
            onDoubleClick={() => {
              const remainingTotal =
                Number(grandTotalWithTax) -
                (Number(billingDetails.cashAmount) +
                  Number(billingDetails.cardAmount) +
                  Number(billingDetails.eAmount) +
                  Number(billingDetails.couponAmount));

              setBillingDetails((e) => {
                return {
                  ...e,
                  btcAmount: remainingTotal,
                };
              });
            }}
            readOnly={width <= 1280}
            onClick={() => {
              if (width <= 1280) {
                dispatch(setnumericKeyboardType("BTC_AMT"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "BTC_AMT",
                    id: "1",
                    value:
                      billingDetails.btcAmount !== 0
                        ? billingDetails.btcAmount
                        : 0,
                  })
                );
              }
            }}
            placeholder={"BTC Amount"}
            value={
              billingDetails.btcAmount !== 0 ? billingDetails.btcAmount : ""
            }
            onchange={(t) => {
              if (TotalAmount > 0) {
                setBillingDetails((e) => {
                  return {
                    ...e,
                    btcAmount: t.target.value === "" ? 0 : t.target.value,
                    btcName: e.customerName,
                  };
                });
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: `Please add items to do bill settlement`,
                  })
                );
              }
            }}
          />

          <PlaceHolderTextInput
            icon={
              <i className="bi mr-1  bi-person  text-[#b5b3b3]  text-[17px] flex  items-center"></i>
            }
            onFocus={() => {
              setisTextActive(true);
            }}
            onBlur={() => {
              setisTextActive(false);
            }}
            id={"Btc_name"}
            readOnly={true}
            rightIcon={
              <button
                onClick={() => {
                  setCustomermasetrHelp(true);
                }}
              >
                <i className="bi bi-search text-orange"></i>
              </button>
            }
            type={"text"}
            placeholder={"BTC Name"}
            value={billingDetails.btcName}
          />

          <PlaceHolderTextInput
            onDoubleClick={() => {
              const remainingTotal =
                Number(grandTotalWithTax) -
                (Number(billingDetails.cardAmount) +
                  Number(billingDetails.eAmount) +
                  Number(billingDetails.btcAmount) +
                  Number(billingDetails.cashAmount));
              setBillingDetails((e) => {
                return {
                  ...e,
                  couponAmount: remainingTotal,
                };
              });
            }}
            align={"right"}
            onFocus={() => {
              setisTextActive(true);
            }}
            onBlur={() => {
              setisTextActive(false);
            }}
            readOnly={width <= 1280}
            onClick={() => {
              if (width <= 1280) {
                dispatch(setnumericKeyboardType("COUPAN_AMT"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "COUPAN_AMT",
                    id: "1",
                    value:
                      billingDetails.couponAmount !== 0
                        ? billingDetails.couponAmount
                        : 0,
                  })
                );
              }
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                const inputFields =
                  fromref.current.querySelectorAll("input, select");
                inputFields.forEach((element) => {
                  if (element.id === "Coupan_no") {
                    element.focus();
                  }
                });
              }
              if (e.code === "ArrowRight") {
                inputFields.forEach((element) => {
                  if (element.id === "Coupan_no") {
                    element.focus();
                  }
                });
              } else {
                if (e.code === "ArrowUp") {
                  inputFields.forEach((element) => {
                    if (element.id === "eAmount") {
                      element.focus();
                    }
                  });
                }
              }
            }}
            type={"number"}
            placeholder={"Coupan Amount"}
            id={"Coupan_amount"}
            value={
              billingDetails.couponAmount !== 0
                ? billingDetails.couponAmount
                : ""
            }
            onchange={(t) => {
              if (TotalAmount > 0) {
                setBillingDetails((e) => {
                  return {
                    ...e,
                    couponAmount: t.target.value === "" ? 0 : t.target.value,
                  };
                });
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: `Please add items to do bill settlement`,
                  })
                );
              }
            }}
          />

          <PlaceHolderTextInput
            id={"Coupan_no"}
            onKeyDown={(e) => {
              if (e.code === "ArrowLeft") {
                inputFields.forEach((element) => {
                  if (element.id === "Coupan_amount") {
                    element.focus();
                  }
                });
              } else {
                if (e.code === "ArrowUp") {
                  inputFields.forEach((element) => {
                    if (element.id === "BTC_amount") {
                      element.focus();
                    }
                  });
                }
              }
            }}
            onFocus={() => {
              setisTextActive(true);
            }}
            onBlur={() => {
              setisTextActive(false);
            }}
            readOnly={width <= 1280}
            onClick={() => {
              if (width <= 1280) {
                dispatch(setnumericKeyboardType("COUPAN_NO"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "COUPAN_NO",
                    id: "1",
                    value:
                      billingDetails.couponNo !== 0
                        ? billingDetails.couponNo
                        : 0,
                  })
                );
              }
            }}
            placeholder={"Coupan Number"}
            value={billingDetails.couponNo !== 0 ? billingDetails.couponNo : ""}
            onchange={(t) => {
              if (TotalAmount > 0) {
                setBillingDetails((e) => {
                  return {
                    ...e,
                    couponNo: t.target.value === "" ? 0 : t.target.value,
                  };
                });
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: `Please add items to do bill settlement`,
                  })
                );
              }
            }}
          />

          <PlaceHolderTextInput
            icon={
              <div>
                <i className="bi bi-currency-rupee text-[#b5b3b3]  flex items-center"></i>
              </div>
            }
            id={"Balance"}
            onKeyDown={(e) => {
              if (e.code === "ArrowLeft") {
                inputFields.forEach((element) => {
                  if (element.id === "Coupan_amount") {
                    element.focus();
                  }
                });
              } else {
                if (e.code === "ArrowUp") {
                  inputFields.forEach((element) => {
                    if (element.id === "BTC_amount") {
                      element.focus();
                    }
                  });
                }
              }
            }}
            onFocus={() => {
              setisTextActive(true);
            }}
            onBlur={() => {
              setisTextActive(false);
            }}
            readOnly={true}
            borderColor={"#262626"}
            placeholder={"Balance"}
            value={` Balance : ${
              TotalAmount &&
              FormatNumber2(
                grandTotalWithTax -
                  (Number(billingDetails.couponAmount) +
                    Number(billingDetails.btcAmount) +
                    Number(billingDetails.cashAmount) +
                    Number(billingDetails.cardAmount) +
                    Number(billingDetails.eAmount))
              )
            }`}
          />
        </div>
      </div>
    </div>
  );
}

function ActionButtons(
  billCartList,
  billingDetails,
  grandTotalWithTax,
  dispatch,
  prefix,
  token,
  clearBilling,
  setBillNo,
  setAlertTwoButtonModal,
  setViewBillStatus,
  inputFields,
  customerDetails,
  SaleType,
  setModalStatus,
  longtitude,
  latitude,
  Undo,
  setIsOriginalBill,
  roundOffAmt
) {
  return (
    <div>
      {billCartList[0]?.INVNO ? (
        <ModifyButton
          onClick={() => {
            const TotalreceivedAmt =
              Number(billingDetails.cashAmount) +
              Number(billingDetails.cardAmount) +
              Number(billingDetails.btcAmount) +
              Number(billingDetails.eAmount) +
              Number(billingDetails.couponAmount);

            if (TotalreceivedAmt >= Number(grandTotalWithTax)) {
              if (TotalreceivedAmt === Number(grandTotalWithTax)) {
                OnSubmit(
                  billCartList,
                  dispatch,
                  prefix,
                  token,
                  Undo,
                  grandTotalWithTax,
                  setBillNo,
                  setAlertTwoButtonModal,
                  setViewBillStatus,
                  inputFields,
                  billCartList[0].SALE_TYPE,
                  setModalStatus,
                  "",
                  longtitude,
                  latitude,
                  false,
                  customerDetails,
                  setIsOriginalBill,
                  roundOffAmt
                );
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: `Recived Amount cannot be greater then bill amount.`,
                  })
                );
              }
            } else {
              //  inputFields.forEach((element) => {
              //    if (element.id === "Cash_amount") {
              //      element.focus();
              //    }
              //  });
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: `Bill Settlement not cleared.`,
                })
              );
            }
          }}
        />
      ) : (
        <SaveButton
          onClick={() => {
            const phoneRegex = /^[6-9]\d{9}$/;
            if (
              (customerDetails.CUSTOMER_MOBILE &&
                phoneRegex.test(customerDetails.CUSTOMER_MOBILE)) ||
              !customerDetails.CUSTOMER_MOBILE
            ) {
              const TotalreceivedAmt =
                Number(billingDetails.cashAmount) +
                Number(billingDetails.cardAmount) +
                Number(billingDetails.btcAmount) +
                Number(billingDetails.eAmount) +
                Number(billingDetails.couponAmount);

              if (TotalreceivedAmt >= Number(grandTotalWithTax)) {
                if (TotalreceivedAmt === Number(grandTotalWithTax)) {
                  OnSubmit(
                    billCartList,
                    dispatch,
                    prefix,
                    token,
                    Undo,
                    grandTotalWithTax,
                    setBillNo,
                    setAlertTwoButtonModal,
                    setViewBillStatus,
                    inputFields,
                    SaleType,
                    setModalStatus,
                    "",
                    longtitude,
                    latitude,
                    false,
                    customerDetails,
                    setIsOriginalBill,
                    roundOffAmt
                  );
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: `Recived Amount cannot be greater then Bill amount.`,
                    })
                  );
                }
              } else {
                inputFields.forEach((element) => {
                  if (element.id === "Cash_amount") {
                    element.focus();
                  }
                });
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: `Bill Settlement not cleared.`,
                  })
                );
              }
            } else {
              inputFields.forEach((element) => {
                if (element.id === "Customer_mobile") {
                  element.focus();
                }
              });
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: "Customer mobile is Invalid.",
                })
              );
            }
          }}
        />
      )}
      <UndoButton onClick={clearBilling} />
    </div>
  );
}

function itemListComp(
  itemList,
  billCartList,
  setBillCartList,
  billingDetails,
  customerDetails,
  dispatch,
  setAlertTwoButtonModal,
  isStockCheck,
  setItem_Box,
  setSalePriceModalStatus,
  itemImgList,
  prefix,
  token,
  setSectionName,
  setItemList,
  width,
  profileSetting
) {
  return (
    <div
      style={{
        height: `calc(100% - ${width < 640 ? 50 : 9}px)`,
      }}
      className=" border rounded-sm p-2 shadow-sm flex flex-col mt-2 overflow-y-auto bg-[#f8f8fd]  pr-1"
    >
      {itemList[0] ? (
        <div
          className="grid grid-cols-1 gap-2  sm:grid-cols-2 t:grid-cols-2 xl:grid-cols-2 lt:grid-cols-1 2xl:grid-cols-2 3xl:grid-cols-3
             bg-[#f8f8fd]"
        >
          {itemList?.map((box, index) => {
            return (
              <ItemBox
                onClick={(e) => {
                  const isReturnAvailable = billCartList.filter(
                    (e) => e.ICODE === box.ICODE && Number(e.RQNTY) > 0
                  );
                  if (!isReturnAvailable[0]) {
                    if (box.SALE_PRICE > 0) {
                      itemClick(
                        e,
                        box,
                        setBillCartList,
                        billingDetails,
                        customerDetails,
                        1,
                        dispatch,
                        setAlertTwoButtonModal,
                        false,
                        isStockCheck,
                        prefix,
                        token,
                        false,
                        profileSetting,
                        billCartList
                      );
                    } else {
                      const isInBillCart = billCartList.filter(
                        (t) => t.ICODE === box.ICODE
                      );

                      if (isInBillCart[0]) {
                        const data = {
                          ...box,
                          SALE_PRICE: isInBillCart[0].SALE_PRICE,
                        };

                        itemClick(
                          data,
                          data,
                          setBillCartList,
                          billingDetails,
                          customerDetails,
                          1,
                          dispatch,
                          setAlertTwoButtonModal,
                          false,
                          isStockCheck,
                          prefix,
                          token,
                          false,
                          profileSetting,
                          billCartList
                        );
                      } else {
                        setItem_Box(box);

                        setSalePriceModalStatus(true);
                      }
                    }
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: "Alert",
                        msg: "You cannot enter a value for Qnty after entering Return. Please remove the value from Return before entering Qnty.",
                        horizontal: true,
                      })
                    );
                  }
                }}
                box={box}
                key={index}
                index={index}
                mobileWidth={mobileViewWidth}
                imgCard={itemImgList}
              />
            );
          })}
        </div>
      ) : (
        <div className="w-full h-full  flex items-center justify-center">
          <LoadItems
            prefix={prefix}
            token={token}
            dispatch={dispatch}
            setSectionName={setSectionName}
            setItemList={setItemList}
          ></LoadItems>
        </div>
      )}
    </div>
  );
}
function LoadItems(props) {
  return <div className="text-gray-400">No items found. </div>;
}
function totalQntyComponent(
  c,
  TotalQnty,
  totalReturn,
  billCartList,
  TotalAmount,
  totalReturnAmount,
  width,
  grandTotalWithTax,
  modal,
  dispatch
) {
  return (
    <div className="text-[12px]  t:text-[13px]">
      <div className="  flex rounded-sm  rounded-br-none      bg-[#f7f7fd]">
        {c[0]?.width && (
          <div
            style={{
              width: c[0].width,
            }}
            className=" pr-1 pl-2 border-r  rounded-tl-sm  rounded-bl-sm    text-red-500 rounded-l-sm flex items-center   border-l border-b  border-t text-right  font-semibold "
          >
            <div className="pr-1 py-2">Sale Qnty</div>:
            <div className="ml-2 py-2">{TotalQnty}</div>
          </div>
        )}

        {c[1]?.width && width > mobileViewWidth && (
          <div
            style={{
              width: c[1].width,
            }}
            className=" p-1 border-r py-2  border-b flex items-center justify-end  border-t text-right  font-semibold "
          >
            Refund
          </div>
        )}

        {c[2]?.width && (
          <div
            style={{
              width: c[2].width,
            }}
            className=" p-1 border-r py-2 border-b flex items-center justify-end  border-t text-right  font-semibold "
          >
            {width > mobileViewWidth || modal
              ? FormatNumber2(totalReturnAmount)
              : ""}
          </div>
        )}

        {c[3]?.width && (
          <div
            style={{
              width: c[3].width,
            }}
            className="  flex items-center  justify-end  border-r  border-b border-t text-right  font-semibold "
          >
            {" "}
            <button
              disabled={!billCartList[0]}
              className="bg-orange w-full h-full"
              onClick={() => {
                dispatch(setnumericKeyboardType("DISQ_RATE_ALL"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "DISQ_RATE_ALL",
                    id: "DISQ_RATE_ALL",
                    value: 0,
                  })
                );
              }}
            >
              Disc. %
            </button>
          </div>
        )}

        {c[4]?.width && (
          <div
            style={{
              width: c[4].width,
            }}
            className=" border-r  border-b flex items-center justify-end  border-t text-right  font-semibold "
          >
            {modal ? (
              FormatNumber2(TotalAmount)
            ) : (
              <button
                disabled={!billCartList[0]}
                className="bg-orange w-full h-full"
                onClick={() => {
                  dispatch(setnumericKeyboardType("DISQ_AMT_ALL"));
                  dispatch(setnumericKeyboardStatus(true));
                  dispatch(
                    setIssueStockItem({
                      title: "DISQ_AMT_ALL",
                      id: "DISQ_AMT_ALL",
                      value: 0,
                    })
                  );
                }}
              >
                Disc. Amt.
              </button>
            )}
          </div>
        )}

        {c[5]?.width && (
          <div
            style={{
              width: c[5].width,
            }}
            className=" p-1  border-r py-2 flex items-center justify-end border-b  border-t  rounded-br-none  text-right  font-semibold  "
          >
            <div>Grand Total</div>
          </div>
        )}

        {c[6]?.width && (
          <div
            style={{
              width: c[6].width,
            }}
            className=" pl-1  border-r py-2 pr-2 flex items-center justify-end border-b  border-t rounded-tr-sm  rounded-br-sm  text-right  font-semibold  "
          >
            {FormatNumber2(grandTotalWithTax)}
          </div>
        )}
      </div>
      <div
        style={{
          fontSize: FontSizeWidth(width),
        }}
        className="flex  font-semibold    w-full     bg-[#f7f7fd]"
      >
        {c[6]?.width ? (
          ""
        ) : (
          <div className="rounded-t-sm rounded-tl-none text-[12px] w-full t:text-[14px] rounded-tr-none">
            <div className="flex   justify-end  ">
              <div className="flex bg-[#f7f7fd] "></div>
            </div>
            <div className="flex   justify-end  ">
              <div className="flex bg-[#f7f7fd] ">
                <div
                  style={{
                    width: c[0].width + c[1].width,
                  }}
                  className="text-end p-1 py-2 border-r "
                >
                  Grand Total
                </div>
                <div
                  style={{
                    width: c[2].width,
                  }}
                  className="  text-right p-1 border-r py-2 text-[12px]  t:text-[16px] "
                >
                  {FormatNumber2(grandTotalWithTax)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
function searchItemInput(
  searchBoxRef,
  searchRef,
  width,
  setBarCodeScannerStatus,
  itemSearchText,
  billCartList,
  dispatch,
  setBillCartList,
  billingDetails,
  customerDetails,
  setAlertTwoButtonModal,
  isStockCheck,
  setSearchQnty,
  setItem_Box,
  setSalePriceModalStatus,
  setItemSearchText,
  prefix,
  token,
  setItemList,
  setSectionName,
  profileSetting
) {
  return (
    <div ref={searchBoxRef} className=" shadow-sm rounded-sm sm:flex flex-1">
      <PlaceHolderTextInput
        icon={
          <i className="bi mr-2 bi-search text-[#b5b3b3]  flex  items-center"></i>
        }
        autoFocus={window.innerWidth > 1280}
        id={"search"}
        autoFill={"off"}
        reffrence={searchRef}
        type={"text"}
        rightIcon={
          width <= 1280 ? (
            <button
              onClick={() => {
                setBarCodeScannerStatus(true);
              }}
            >
              <i className="bi bi-upc-scan text-orange"></i>
            </button>
          ) : null
        }
        placeholder={"Search Items"}
        value={itemSearchText}
        onKeyDown={(e) => {
          if (
            e.code === "Enter" ||
            e.key === "Enter" ||
            (e.code === "NumpadEnter" && e.target.value)
          ) {
            if (e.target.value.includes("+")) {
              const numbers = e.target.value.split("+"); // Split the string by "+"
              const secondNumber = numbers[1];
              const ICODE = numbers[0];

              const s = secondNumber ? secondNumber : 1;
              getItemByCode(prefix, ICODE, token, dispatch, true).then(
                (list) => {
                  if (list[0]) {
                    const filterd = billCartList.filter(
                      (item) => item.ICODE === ICODE && item.RQNTY > 0
                    );
                    if (filterd[0]) {
                      enqueueSnackbar(`Not Permitted`, {
                        variant: "warning",
                      });
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "You cannot enter a value for Qnty after entering Return. Please remove the value from Return before entering Qnty.",
                          horizontal: true,
                        })
                      );
                    } else {
                      if (list[0].SALE_PRICE > 0) {
                        itemClick(
                          list[0],
                          list[0],
                          setBillCartList,
                          billingDetails,
                          customerDetails,
                          s,
                          dispatch,
                          setAlertTwoButtonModal,
                          false,
                          isStockCheck,
                          prefix,
                          token,
                          false,
                          profileSetting,
                          billCartList
                        );
                      } else {
                        const isInBillCart = billCartList.filter(
                          (t) => t.ICODE === list[0].ICODE
                        );
                        if (isInBillCart[0]) {
                          const data = {
                            ...list[0],
                            SALE_PRICE: isInBillCart[0].SALE_PRICE,
                          };
                          itemClick(
                            data,
                            data,
                            setBillCartList,
                            billingDetails,
                            customerDetails,
                            s,
                            dispatch,
                            setAlertTwoButtonModal,
                            false,
                            isStockCheck,
                            prefix,
                            token,
                            false,
                            profileSetting,
                            billCartList
                          );
                        } else {
                          setSearchQnty(s);
                          setItem_Box(list[0]);

                          setSalePriceModalStatus(true);
                        }
                      }
                    }
                    setItemSearchText("");
                  } else {
                    enqueueSnackbar(`Item Not Found.`, {
                      variant: "error",
                    });
                    setItemSearchText("");
                  }
                }
              );
            } else {
              const isNotANumber = /^[^0-9]/;
              if (isNotANumber.test(e.target.value)) {
                getItemByName(prefix, e.target.value, token, dispatch).then(
                  (items) => {
                    setItemList(items);
                    setItemSearchText("");
                  }
                );
              } else {
                getItemByCode(
                  prefix,
                  e.target.value,
                  token,
                  dispatch,
                  true
                ).then((list) => {
                  if (list[0]) {
                    const filterd = billCartList.filter(
                      (item) => item.ICODE === list[0].ICODE && item.RQNTY > 0
                    );
                    if (filterd[0]) {
                      enqueueSnackbar(`Not Permitted`, {
                        variant: "warning",
                      });
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "You cannot enter a value for Qnty after entering Return. Please remove the value from Return before entering Qnty.",
                          horizontal: true,
                        })
                      );
                    } else {
                      if (list[0].SALE_PRICE > 0) {
                        itemClick(
                          list[0],
                          list[0],
                          setBillCartList,
                          billingDetails,
                          customerDetails,
                          1,
                          dispatch,
                          setAlertTwoButtonModal,
                          false,
                          isStockCheck,
                          prefix,
                          token,
                          false,
                          profileSetting,
                          billCartList
                        );
                      } else {
                        const isInBillCart = billCartList.filter(
                          (t) => t.ICODE === list[0].ICODE
                        );
                        if (isInBillCart[0]) {
                          const data = {
                            ...list[0],
                            SALE_PRICE: isInBillCart[0].SALE_PRICE,
                          };

                          itemClick(
                            data,
                            data,
                            setBillCartList,
                            billingDetails,
                            customerDetails,
                            1,
                            dispatch,
                            setAlertTwoButtonModal,
                            false,
                            isStockCheck,
                            prefix,
                            token,
                            false,
                            profileSetting,
                            billCartList
                          );
                        } else {
                          setItem_Box(list[0]);

                          setSalePriceModalStatus(true);
                        }
                      }
                    }
                    setItemSearchText("");
                  } else {
                    getItemByAlios(
                      prefix,
                      e.target.value,
                      token,
                      dispatch,
                      true
                    ).then((list) => {
                      if (list[0]) {
                        const filterd = billCartList.filter(
                          (item) =>
                            item.ICODE === list[0].ICODE && item.RQNTY > 0
                        );
                        if (filterd[0]) {
                          enqueueSnackbar(`Not Permitted`, {
                            variant: "warning",
                          });
                          dispatch(
                            setAlertWithTitle({
                              title: "Alert",
                              msg: "You cannot enter a value for Qnty after entering Return. Please remove the value from Return before entering Qnty.",
                              horizontal: true,
                            })
                          );
                        } else {
                          if (list[0].SALE_PRICE > 0) {
                            itemClick(
                              list[0],
                              list[0],
                              setBillCartList,
                              billingDetails,
                              customerDetails,
                              1,
                              dispatch,
                              setAlertTwoButtonModal,
                              false,
                              isStockCheck,
                              prefix,
                              token,
                              false,
                              profileSetting,
                              billCartList
                            );
                          } else {
                            const isInBillCart = billCartList.filter(
                              (t) => t.ICODE === list[0].ICODE
                            );
                            if (isInBillCart[0]) {
                              const data = {
                                ...list[0],
                                SALE_PRICE: isInBillCart[0].SALE_PRICE,
                              };

                              itemClick(
                                data,
                                data,
                                setBillCartList,
                                billingDetails,
                                customerDetails,
                                1,
                                dispatch,
                                setAlertTwoButtonModal,
                                false,
                                isStockCheck,
                                prefix,
                                token,
                                false,
                                profileSetting,
                                billCartList
                              );
                            } else {
                              setItem_Box(list[0]);

                              setSalePriceModalStatus(true);
                            }
                          }
                        }
                        setItemSearchText("");
                      } else {
                        dispatch(
                          setAlertWithTitle({
                            title: "Alert",
                            msg: "Item not found",
                          })
                        );
                      }
                    });
                  }
                });
              }
            }
          }
        }}
        onchange={(t) => {
          setItemSearchText(() => t.target.value);
        }}
      />
      <button
        style={{
          marginTop: window.innerWidth >= 640 ? "0px" : "8px",
          backgroundColor: "#f7b03e",
          color: "black",
        }}
        onClick={() => {
          setSectionName(fastMovingMovieItem);
          GetListItemMaster(
            prefix,
            fastMovingMovieItem,
            token,
            dispatch,
            "",
            "",
            setItemList
          );
        }}
        className=" whitespace-nowrap px-2 py-2 h-full sm:w-fit w-full   rounded-sm  sm:ml-2"
      >
        {" Fast Moving Items"}
      </button>
    </div>
  );
}

function billCart(c, billCartList, dispatch, isVoided) {
  return (
    <EditableDataGrid
      columns={c}
      hideScroll={true}
      tableBgColor={"#f7f7fd"}
      cellOubleClickOpn={true}
      rowBgColor={billCartList.filter((e) => e.RQNTY > 0)}
      rowBgColor2={billCartList.filter((e) => e.IS_OFFER)}
      onCellDoubleClick={(e) => {
        if (e.column.field === "QNTY") {
          const item = e.row.values;

          if (Number(item.RQNTY) === 0) {
            dispatch(setnumericKeyboardType("QNTY"));
            dispatch(setnumericKeyboardStatus(true));
            dispatch(
              setIssueStockItem({
                title: item.ITEMNAME,
                id: item.ICODE,
                value: item.QNTY,
              })
            );
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "You cannot enter a value for Qnty after entering Return. Please remove the value from Return before entering Qnty.",
                horizontal: true,
              })
            );
          }
        }
        if (e.column.field === "SALE_PRICE") {
          const item = e.row.values;
          dispatch(setnumericKeyboardType("SALE_PRICE"));
          dispatch(setnumericKeyboardStatus(true));
          dispatch(
            setIssueStockItem({
              title: item.ITEMNAME,
              id: item.ICODE,
              value: item.SALE_PRICE,
            })
          );
        }
        if (e.column.field === "DISQ_AMT") {
          if (e.row.values.RQNTY > 0) {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "You cannot enter a value for Discount after entering Return. Please remove the value from Return before entering Qnty.",
                horizontal: true,
              })
            );
          } else {
            const item = e.row.values;
            dispatch(setnumericKeyboardType("DISQ_AMT"));
            dispatch(setnumericKeyboardStatus(true));
            dispatch(
              setIssueStockItem({
                title: item.ITEMNAME,
                id: item.ICODE,
                value: item.DISQ_AMT,
              })
            );
          }
        }
        if (e.column.field === "DISQ_RATE") {
          if (e.row.values.RQNTY > 0) {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "You cannot enter a value for Discount after entering Return. Please remove the value from Return before entering Qnty.",
                horizontal: true,
              })
            );
          } else {
            const item = e.row.values;
            dispatch(setnumericKeyboardType("DISQ_RATE"));
            dispatch(setnumericKeyboardStatus(true));
            dispatch(
              setIssueStockItem({
                title: item.ITEMNAME,
                id: item.ICODE,
                value: item.DISQ_RATE,
              })
            );
          }
        }
        if (e.column.field === "RQNTY") {
          const item = e.row.values;
          dispatch(setnumericKeyboardType("RQNTY"));
          dispatch(setnumericKeyboardStatus(true));
          dispatch(
            setIssueStockItem({
              title: item.ITEMNAME,
              id: item.ICODE,
              value: item.RQNTY,
            })
          );
        }
      }}
      isVoided={isVoided}
      data={billCartList}
    />
  );
}

function billNoComp(
  setBillHelpStatus,
  billCartList,
  date,
  profileSetting,
  dispatch
) {
  return (
    <div className=" border  p-2 shadow-sm flex rounded-sm bg-[#f8f8fd]  items-center justify-between">
      <div
        onClick={() => {
          if (profileSetting?.MODIFY_SALE_BILL === "Yes") {
            setBillHelpStatus(true);
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: `You don't have permission to modify the bill.`,
              })
            );
          }
        }}
        className="flex"
      >
        <button>
          <i className="bi bi-search text-orange mr-1 flex items-center"></i>
        </button>
        <div className="font-semibold flex text-center  ">
          Bill No. : {billCartList[0] ? billCartList[0].INVNO : ""}
        </div>
      </div>
      <div className="text-gray-500">{moment(date).format("DD/MM/YYYY")}</div>
    </div>
  );
}

export {
  billNoComp,
  billSettelemntBilling,
  ActionButtons,
  itemListComp,
  totalQntyComponent,
  billCart,
  searchItemInput,
};
