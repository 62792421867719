import { Button } from "@mui/material";
import axios from "axios";
import { toJpeg, toPng } from "html-to-image";
import React, { useEffect } from "react";
import Lottie from "lottie-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaWhatsappSquare, FaSms, FaWhatsapp } from "react-icons/fa";
import { TiMessage } from "react-icons/ti";
import whitSpinner from "../../src/utility/json/spinerWhite.json";

import jsPDF from "jspdf";
import spinner from "../../src/utility/json/spinerWhite.json";
import { BiCloudDownload } from "react-icons/bi";
import { TiArrowBackOutline } from "react-icons/ti";
import ViewBillImage from "./viewBillImage";
import { useRef } from "react";

import { useLocation } from "react-router-dom";

import ReactToPrint from "react-to-print";
import {
  selectCompanyAddress,
  selectDefultProfileSetting,
  selectPrefix,
  selectUserType,
  selectWidth,
  setUserType,
} from "../utility/Redux/profile";
import { selectToken } from "../utility/Redux/security";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../utility/Redux/modal";
import CallAPI from "../utility/functions/getData";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import { DownloadFile, UploadFile } from "../utility/functions/firebase";
import AlertModalTwoButton from "../utility/component/modals/alertWithTwoButton";
import ActionRemarks from "../utility/component/modals/RemarksModal";
import OrderSlip from "./orderSlip";
import { getMessageWallet } from "../utility/constant";

const ViewBill = ({
  billNo,
  isOriginalBill,
  bill_performa,
  onBack,
  hideVoid,
  setSummaryList,
  hideOrderSlip,
}) => {
  const dispatch = useDispatch();
  const [orderList, setOrderList] = useState([]);
  const location = useLocation();
  const [remarksStatus, setRemarksStatus] = useState(false);
  const currentInvoiceNumber = billNo;
  const [waDisable, setWadisable] = useState(false);
  const [smsDisable, setsmsdisable] = useState(false);
  const [total, setTotal] = useState();
  const prefix = useSelector(selectPrefix);
  const [billLoading, setBillLoading] = useState(false);
  const componentRef = useRef();
  const [billStatus, setBillStatus] = useState(false);
  const token = useSelector(selectToken);
  const [sentdo, seSendTo] = useState("");
  const [data, setData] = useState([]);
  const pageStyle = `
    @media print {
      @page {
        size: portrait; /* Ensures portrait orientation */
      }
      body {
        -webkit-print-color-adjust: exact; /* Ensure colors are printed */
      }
    }
  `;
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const companyDetails = useSelector(selectCompanyAddress);
  const defaultProfile = useSelector(selectDefultProfileSetting);
  const userType = useSelector(selectUserType);
  const width = useSelector(selectWidth);
  const getViewBillDetatils = () => {
    dispatch(setSpinnerLoading("Loading bill"));
    axios
      .post(
        "/api/billing/loadBill",

        {
          table_prefix: prefix,
          INVNO: currentInvoiceNumber,
          BILL_PERFORMA: "bills",
        },

        {
          headers: headers,
        }
      )

      .then((response) => {
        const dataList = response.data.response;
        seSendTo(dataList[0]?.CUSTOMER_MOBILE);
        setData(dataList);
        setTotal(dataList[0]?.GTOTAL);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  function dataURLToBlob(dataUrl) {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  const handleSendImage = (number, total) => {
    dispatch(setSpinnerLoading("Loading"));
    if (defaultProfile?.SEND_BILL_BY_WHATSAPP === "Yes") {
      getMessageWallet(companyDetails.email, token)
        .then((bal) => {
          if (bal > 0) {
            if (number?.length === 10) {
              setWadisable(true);
              const amount = FormatNumber2(total);
              toPng(
                componentRef.current,
                window.innerWidth < 768 ? { quality: 0.25 } : { quality: 0.95 }
              )
                .then((dataUrl) => {
                  const blob = dataURLToBlob(dataUrl);
                  const path = "retailx/bill/";
                  const name = `${prefix + currentInvoiceNumber}`;

                  UploadFile(blob, path, name)
                    .then(() => {
                      const b = `retailx/bill/${prefix + currentInvoiceNumber}`;

                      DownloadFile(b).then((c) => {
                        const s = c.split(
                          "https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/"
                        );
                        const data = {
                          dataUrl: s[1],
                          invno: currentInvoiceNumber.toString(),
                          mobile: number,
                          amount: amount,
                          company_email: companyDetails.email,
                          accountSid: "AC3d8fd1bb1700f4445c0491d89457a403",
                          authToken: "bf73d1ae8b3691a3b84aaf8dbe9a8673",
                          from: "MG3315c8fd955484a58d2e7e000c9b685b",
                          contentSid: "HX59f3634121fe407ad70cbf624bdfb2d5",
                        };
                        dispatch(setSpinnerLoading("Sending Image"));
                        axios
                          .post("/api/sendDigitalBill/saveBillImage", data, {
                            headers: headers,
                          })
                          .then((response) => {
                            setWadisable(true);
                            dispatch(
                              setAlertWithTitle({
                                title: "Success",
                                msg: "Whatsapp is sent succesfully",
                                lottie: "success",
                              })
                            );
                            // dispatch(setAlertMsg('Message sent successfully'));
                            // dispatch(setALertStatus(true));
                          })
                          .catch((err) => {
                            console.log(err);

                            if (
                              err?.response?.data?.name === "TokenExpiredError"
                            ) {
                              dispatch(setSessionExpired(true));
                              dispatch(
                                setAlertWithTitle({
                                  title: "Whatsapp Sent",
                                  msg: "Whastapp sent successfully",
                                })
                              );
                            } else {
                              dispatch(
                                setAlertWithTitle({
                                  title: "ERROR",
                                  msg: "Something went wrong.",
                                  lottie: "reject",
                                })
                              );
                            }
                          })
                          .finally(() => {
                            setWadisable(false);
                            dispatch(setSpinnerLoading(false));
                          });
                      });
                    })
                    .catch((e) => {
                      dispatch(setSpinnerLoading(false));
                    });
                })
                .catch((err) => {
                  dispatch(setSpinnerLoading(false));
                })
                .finally(() => {
                  setWadisable(false);
                });
            } else {
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: "Incorrect fields",
                })
              );
            }
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "Insufficient funds",
              })
            );
          }
        })
        .catch(() => {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Insufficient funds",
            })
          );
        });
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: `You don't have permission to send whatsapp.`,
        })
      );
    }
  };

  const handleSendSms = (number, amount) => {
    dispatch(setSpinnerLoading("Loading"));

    if (defaultProfile?.SEND_BILL_BY_SMS === "Yes") {
      getMessageWallet(companyDetails.email, token, true).then((bal) => {
        if (bal > 0) {
          if (number?.length === 10 && amount) {
            dispatch(setSpinnerLoading("Sending"));
            const data = {
              INVNO: currentInvoiceNumber,
              MOBILE: `+91${number}`,
              AMOUNT: amount,
              company_email: companyDetails.email,
              BILL_URL: "https://retailxapp.com",
            };
            axios
              .post(
                "/api/sendDigitalBill/sendSms",
                data,

                {
                  headers: headers,
                }
              )
              .then((response) => {
                dispatch(
                  setAlertWithTitle({
                    title: "Msg Sent",
                    msg: "Message sent successfully",
                  })
                );
              })
              .catch((err) => {
                console.log(err);
                dispatch(
                  setAlertWithTitle({
                    title: "Error",
                    msg: "Message Failed",
                  })
                );
              })
              .finally(() => {
                dispatch(setSpinnerLoading(false));
              });
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Incorrect fields",
              })
            );
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Insufficient funds",
            })
          );
        }
      });
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: `You don't have permission to send sms.`,
        })
      );
    }
  };

  const downloadPdf = () => {
    toJpeg(
      componentRef.current,
      window.innerWidth < 768 ? { quality: 0.25 } : { quality: 0.95 }
    )
      .then((dataUrl) => {
        const pdf = new jsPDF();
        pdf.addImage(dataUrl, "PNG", 0, 0);
        pdf.save(`Bill_${currentInvoiceNumber}`);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getViewBillDetatils();
  }, []);

  const formRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (formRef.current) {
        const inputFields = formRef.current.querySelectorAll("button");

        inputFields.forEach((element) => {
          if (element.id === "PrintButton") {
            element.focus();
            // element.st
          }
        });

        const handleKeyDown = (event) => {
          if (event.key === "ArrowRight") {
            const nextButton = document.getElementById("Button2");
            if (nextButton) {
              nextButton.focus();
            }
          } else if (event.key === "Enter") {
            const focusedElement = document.activeElement;
            focusedElement.click();
          } else if (event.key === "ArrowDown") {
            const focusedElement = document.activeElement;
            const buttons = formRef.current.querySelectorAll("button");
            const focusedIndex = Array.from(buttons).indexOf(focusedElement);
            const nextButton = buttons[focusedIndex + 1];
            if (nextButton) {
              nextButton.focus();
            }
          } else if (event.key === "ArrowTop") {
            const nextButton = document.getElementById("Button1");
            if (nextButton) {
              nextButton.focus();
            }
          }
        };

        formRef.current.addEventListener("keydown", handleKeyDown);

        return () => {
          formRef.current.removeEventListener("keydown", handleKeyDown);
        };
      }
    }, 200);

    return () => clearTimeout(timeout);
  }, [billLoading, formRef]);

  const orderPrintRef = useRef(null);

  return (
    <div
      ref={formRef}
      className="w-full h-fit  flex flex-col md:flex md:flex-row "
    >
      <div
        style={window.innerWidth < 770 ? { width: "100%" } : { width: "auto" }}
        className="  flex-col flex grow justify-center items-center"
      >
        <div className=" w-full flex p-3 max-h-[600px] overflow-y-auto justify-center items-start ">
          {billLoading ? (
            <div>
              <div className="w-6 h-6">
                <Lottie
                  animationData={spinner}
                  loop={true}
                  style={{ fontSize: 15 }}
                  color="white"
                />
              </div>
            </div>
          ) : (
            <div className="h-fit flex">
              <div className="border rounded-sm overflow-hidden h-full ">
                <ViewBillImage
                  bill_performa={bill_performa}
                  isOriginalBill={isOriginalBill}
                  setBillStatus={setBillStatus}
                  setOrderList={setOrderList}
                  ref={componentRef}
                  billNo={billNo}
                  data={data}
                />
              </div>
              {orderList[0] && !hideOrderSlip && (
                <div className="border rounded-sm ml-2">
                  {" "}
                  <OrderSlip orderList={orderList} ref={orderPrintRef} />{" "}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          display: width > 768 ? "flex" : "none",
        }}
        className="p-4  flex-col   "
      >
        <div className="w-full">
          <div className="flex justify-between w-full  "></div>

          <div className="flex justify-start items-center w-full  ">
            <div style={{ fontSize: 13 }}>Mobile</div>
            <div
              style={{
                height: 27,
                backgroundColor: "#EDEDF2",
                overflow: "hidden",
                marginLeft: 10,
              }}
              className=" rounded-sm  w-16 md:w-40  items-center "
            >
              <input
                type={"Number"}
                style={{
                  fontSize: 13,
                  backgroundColor: "#EDEDF2",
                  paddingLeft: 10,

                  fontWeight: 400,
                  outline: 0,
                }}
                placeholder={"123"}
                value={sentdo}
                onChange={(t) => seSendTo(t.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-5 justify-center">
          <button
            id={"Button1"}
            size={"small"}
            className="flex items-center p-1  rounded-sm  focus:border focus:border-black"
            onClick={() => {
              if (sentdo && !waDisable) {
                handleSendImage(sentdo, total);
              } else {
                if (!sentdo) {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Not found any mobile",
                    })
                  );
                }
              }
            }}
            style={{
              backgroundColor: "#A8CF45",
              color: "white",
              textTransform: "none",
              width: "100%",
              justifyContent: "start",
              paddingLeft: 20,
            }}
          >
            <div className="ml-4">
              <FaWhatsapp size={20} className="bg-green text-white" />
            </div>
            <div className="px-2 text-sm"> {"Whatsapp"}</div>{" "}
            {waDisable ? (
              <div className="w-full flex justify-center items-center">
                <div className="w-6 h-6">
                  <Lottie
                    animationData={spinner}
                    loop={true}
                    style={{ fontSize: 15 }}
                    color="white"
                  />
                </div>
              </div>
            ) : null}
          </button>

          <Button
            size={"small"}
            // onClick={() => {
            //   if (!smsDisable && sentdo) {
            //     handleSendSms(sentdo, total);

            //   } else {
            //     if (!sentdo) {
            //       dispatch(setAlertWithTitle({
            //         title:'Alert',
            //         msg:'Not found any mobile'
            //       }))
            //     }
            //   }
            // }}
            style={{
              backgroundColor: "#F5874F",
              color: "white",
              marginTop: 10,
              width: "100%",
              justifyContent: "start",
              paddingLeft: 20,

              textTransform: "none",
            }}
            variant="contained"
          >
            <TiMessage size={20} className="bg-orange text-white" />
            <div className="px-2">SMS</div>{" "}
            {smsDisable ? (
              <div className="w-full flex justify-center items-center">
                <div className="w-6 h-6">
                  <Lottie
                    animationData={whitSpinner}
                    loop={true}
                    style={{ fontSize: 15 }}
                    color="white"
                  />
                </div>
              </div>
            ) : null}
          </Button>

          <Button
            size={"small"}
            onClick={() => {
              downloadPdf();
            }}
            style={{
              backgroundColor: "#F5874F5",
              color: "white",
              marginTop: 10,
              textTransform: "none",
              width: "100%",
              justifyContent: "start",
              paddingLeft: 20,
            }}
            variant="contained"
          >
            <BiCloudDownload size={20} className="bg-brown text-white" />
            <div className="px-2">Download</div>{" "}
          </Button>
          {billStatus && !hideVoid && userType === "MANAGER" ? (
            <Button
              size={"small"}
              onClick={() => {
                if (billStatus === "Settled") {
                  setRemarksStatus(true);
                } else {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: true,
                    title: "Alert",
                    msg: "Are you sure you want to Settled this bill?",
                    button1: "Yes",
                    button2: "Cancel",
                    button2Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                    },
                    button1Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                      dispatch(setSpinnerLoading("Settled"));

                      CallAPI(
                        "/api/billing/changeSaleBillStatus",
                        {
                          table_prefix: prefix,
                          INVNO: currentInvoiceNumber,
                          BILL_STATUS:
                            billStatus === "Void" ? "Settled" : "Void",
                          REMARKS: "",
                        },
                        token,
                        (e) => {
                          if (!e.data.error) {
                            onBack();
                            if (setSummaryList) {
                              setSummaryList((p) =>
                                p.map((old) => {
                                  if (old.INVNO === currentInvoiceNumber) {
                                    return {
                                      ...old,
                                      BILL_STATUS: "Settled",
                                    };
                                  } else {
                                    return old;
                                  }
                                })
                              );
                            }
                            dispatch(
                              setAlertWithTitle({
                                title: "Success",
                                msg: `Bill successfully  ${
                                  billStatus === "Void" ? "Settled" : "Voided"
                                }`,
                              })
                            );
                          } else {
                            dispatch(
                              setAlertWithTitle({
                                title: "Alert",
                                msg: "Something went wrong",
                              })
                            );
                          }
                        },
                        dispatch,
                        () => {
                          dispatch(setSpinnerLoading(false));
                        }
                      );
                    },
                  });
                }
              }}
              style={{
                backgroundColor: billStatus === "Void" ? "#5491f5" : "#e97a7a",
                color: "white",
                marginTop: 10,

                textTransform: "none",
                width: "100%",
                justifyContent: "start",
                paddingLeft: 20,
              }}
              variant="contained"
            >
              <i
                className={`bi ${
                  billStatus === "Void" ? " bi-patch-check" : "bi-trash3"
                }`}
              ></i>
              <div className="px-2">
                {billStatus === "Void" ? "Settled" : "Void"}
              </div>{" "}
              {waDisable ? (
                <div className="w-full flex justify-center items-center">
                  <div className="w-6 h-6">
                    <Lottie
                      animationData={spinner}
                      loop={true}
                      style={{ fontSize: 15 }}
                      color="white"
                    />
                  </div>
                </div>
              ) : null}
            </Button>
          ) : null}
          <Button
            size={"small"}
            onClick={onBack}
            style={{
              color: "#383535",
              marginTop: 10,
              borderColor: "#383535",
              textTransform: "none",
              width: "100%",
              justifyContent: "start",
              paddingLeft: 20,
            }}
            variant="outlined"
          >
            <TiArrowBackOutline
              //   onClick={() => dispatch(setShareImageModalStatus(false))}
              size={20}
              className=" text-brown"
            />
            <div
              //   onClick={() => dispatch(setShareImageModalStatus(false))}
              className="px-2"
            >
              Back{" "}
            </div>{" "}
          </Button>
        </div>

        <ReactToPrint
          trigger={() => {
            return (
              <Button
                size={"small"}
                id={"PrintButton"}
                style={{
                  backgroundColor: "#BAD4F9",
                  color: "black",
                  textTransform: "none",
                  marginTop: 10,
                }}
                variant="contained"
              >
                <div className="px-2">Print</div>{" "}
              </Button>
            );
          }}
          content={() => componentRef.current}
          pageStyle={pageStyle}
        />
        {orderList[0] && !hideOrderSlip && (
          <ReactToPrint
            trigger={() => {
              return (
                <Button
                  size={"small"}
                  id={"PrintButton"}
                  style={{
                    backgroundColor: "#BAD4F9",
                    color: "black",
                    textTransform: "none",
                    marginTop: 10,
                  }}
                  variant="contained"
                >
                  <div className="px-2">Order Print</div>{" "}
                </Button>
              );
            }}
            content={() => orderPrintRef.current}
          />
        )}

        <div className="w-full flex justify-center mt-3 "></div>
      </div>

      <div className="fixed bottom-0 w-full flex md:hidden p-3 bg-[#EDEDF2] items-center border-t-2 border-t-slate-200 ">
        <div
          style={{
            height: 35,
            backgroundColor: "#c9c5c5",
            overflow: "hidden",
            width: "45%",
          }}
          className=" rounded-md mx-2 border-2  items-center "
        >
          <input
            type={"Number"}
            style={{
              fontSize: 14,
              backgroundColor: "#c9c5c5",
              paddingLeft: 10,
              height: "100%",
              fontWeight: 400,
              outline: 0,
            }}
            placeholder={"Mobile Number"}
            value={sentdo}
            onChange={(t) => seSendTo(t.target.value)}
          />
        </div>
        <div
          style={{ width: "55%" }}
          className=" rounded-sm flex justify-evenly "
        >
          <FaWhatsappSquare
            size={30}
            className="text-green"
            onClick={() => {
              if (sentdo) {
                handleSendImage(sentdo, total);
                // handleSendSms(sentdo);
              } else {
                if (!sentdo) {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Not found any mobile",
                    })
                  );
                }
              }
            }}
          />

          <FaSms
            size={30}
            className="text-orange"
            onClick={() => {
              if (sentdo) {
                handleSendSms(sentdo, total);
              } else {
                if (!sentdo) {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Not found any mobile",
                    })
                  );
                }
              }
            }}
          />

          <Button
            size={"small"}
            style={{
              backgroundColor: "#383535",
              color: "white",
              textTransform: "none",
            }}
            onClick={onBack}
            variant="contained"
          >
            <div className="px-2">Back</div>{" "}
          </Button>
        </div>
      </div>
      <ActionRemarks
        remarksStatus={remarksStatus}
        setRemarksStatus={setRemarksStatus}
        currentInvoiceNumber={currentInvoiceNumber}
        setSummaryList={setSummaryList}
        onBack={onBack}
      />
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};

export default ViewBill;
